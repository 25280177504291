
const AddressInputField = ({title, street, setStreet, city, setCity, state, setState, zip, setZip,locked,searchCustomer}) => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if(searchCustomer) searchCustomer();
        }
    };
    
    return(
        <div className='Project-Booking-Input-Field'>
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            {   !locked &&
            <>
                <input className='Project-Booking-Input-Field-Input' style={{width:'250px'}} placeholder={'Street'} value={street} onChange={e => setStreet(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
                <input className='Project-Booking-Input-Field-Input' style={{width:'100px'}} placeholder={'City'} value={city} onChange={e => setCity(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
                <input className='Project-Booking-Input-Field-Input' maxLength={2} style={{width:'40px'}} placeholder={'State'} value={state} onChange={e => setState(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
                <input className='Project-Booking-Input-Field-Input' maxLength={5} style={{width:'55px'}} placeholder={'Zip Code'} value={zip} onChange={e => setZip(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
            </>
            }
            {   locked &&
            <>
                <input className='Project-Booking-Input-Field-Input' style={{width:'250px'}} value={street} onChange={e => setStreet(e.target.value)} disabled/>
                <input className='Project-Booking-Input-Field-Input' style={{width:'100px'}} value={city} onChange={e => setCity(e.target.value)} disabled/>
                <input className='Project-Booking-Input-Field-Input' maxLength={2} style={{width:'40px'}} value={state} onChange={e => setState(e.target.value)} disabled/>
                <input className='Project-Booking-Input-Field-Input' maxLength={5} style={{width:'55px'}}  value={zip} onChange={e => setZip(e.target.value)} disabled/>
            </>
            }
        </div>
    )
};

export default AddressInputField;