import './CustomerReview.css'
import { useState, useEffect, useContext } from 'react';
import {Context} from '../Context';
import newCustomerRequestService from '../Services/CustomerRequestService';
import moment from 'moment';
import { SERVER_URL } from '../Constants';

import { FaFlag,FaSearch,FaArrowUp,FaArrowDown,FaFilePdf,FaCheck } from "react-icons/fa";
import { BsPersonFillAdd,BsPersonFillUp  } from "react-icons/bs";
import { RotatingLines } from "react-loader-spinner";

const organizeDate = (date) => {
    return moment(date).format("MMM Do YYYY");
};

const DropDownSelection = ({title, options, setTo, width, initializedValue}) => {
    const inputCssProps = {
        '--inputWidth': `${width}`
    };

    const selectOption = (value) => {
         setTo(value);
    };

    return(
        <div className='External-Form-Input' style={inputCssProps}>
        <div className='External-Form-Input-Title'>
            {title}
        </div>

        <select className='External-Form-Dropdown' onChange={(e) => selectOption(e.target.value)}>
            <option value={true}>{initializedValue}</option>
            {   options.map((option, key) => (
                <option className='External-Form-Dropdown-Selection' key={key} value={option}>{option}</option>
            ))
            }
        </select>
        
    </div>
    )
};

const SearchBar = ({setSearchInput,searchList, selectSearchItem}) => {
    const [ focused, setFocused ] = useState(false);

    const handleSearchItemClick = (businessName) => {
        selectSearchItem(businessName);
        setFocused(false);
        document.getElementById('myInput').value = ''
        setSearchInput('');
    };

    return(      
        <div className='Customer-Request-Header-Search' onClick={() => setFocused(true)}  onMouseLeave={() => setFocused(false)}>
            <FaSearch  className='Customer-Request-Header-Search-Icon'/>
            <input autoComplete="off"  onChange={((e) => setSearchInput(e.target.value))} id="myInput" className='Customer-Request-Header-Search-Text' placeholder='Search Customers'/>

            {   focused && searchList && 
            <div className='Customer-Request-Header-Search-List'>
            {   
                searchList.map((object, key) => (
                    <div key={key} className='Customer-Request-Header-Search-Term' onClick={(event) => {handleSearchItemClick(object.business_name); event.stopPropagation()}}>
                        {object.business_name}
                    </div>
                ))
            }
            </div>
            }

        </div>
    )
};

const ActiveSelector = ({activeSort,setActiveSort}) => {
    return(
        <div className='Customer-Request-Header-Active'>

            {   activeSort && activeSort !== 'both' &&
            <div className='Customer-Request-Header-Select Selected-Active'>
                Active
            </div>
            }
            { activeSort === 'both' &&
            <div className='Customer-Request-Header-Select ' onClick={() => setActiveSort(true)}>
                Active
            </div>
            }
            {   !activeSort &&
            <div className='Customer-Request-Header-Select ' onClick={() => setActiveSort(true)}>
                Active
            </div>
            }


            <div className='Customer-Request-Header-Select-Divider'/>

            {   !activeSort &&
            <div className='Customer-Request-Header-Select Selected-Active'>
                InActive
            </div>
            }
            {   activeSort &&
            <div className='Customer-Request-Header-Select' onClick={() => setActiveSort(false)}>
                InActive
            </div>  
            }

            <div className='Customer-Request-Header-Select-Divider'/>

            {   activeSort === 'both' &&
            <div className='Customer-Request-Header-Select Selected-Active'>
                Both
            </div>
            }
            {   activeSort !== 'both' &&
            <div className='Customer-Request-Header-Select' onClick={() => setActiveSort('both')}>
                Both
            </div>  
            }
        </div>
    )
};

const ControlBar = ({resetList,activeSort,setActiveSort}) => {
    return(
        <div className='Customer-Request-Header-Controls'>
            <ActiveSelector activeSort={activeSort} setActiveSort={setActiveSort}/>
            <button onClick={() => resetList()} className='Customer-Request-Header-All-Button'>Default View</button>
        </div>
    )
};

const SortDirectionIndicator = ({currentSelection, validSelection, direction}) => {
    if(currentSelection === validSelection){
        if(direction === 'ASC') return <FaArrowUp />
        else return <FaArrowDown />
    }

};

const BottomHeader = ({screenWidth, sortDirection, orderBy, handleOrderClick}) => {
    return(
        <div className='Customer-Request-Bottom-Header'>

            <div className='Customer-Request-Tile-Information'>
                <div className='Customer-Request-Tile-Data Header-Title' onClick={() => handleOrderClick('customer_type')}>
                    Request Type <SortDirectionIndicator currentSelection={orderBy} validSelection={'customer_type'} direction={sortDirection}/>
                </div>
                <div className='Customer-Request-Tile-Data Header-Title' onClick={() => handleOrderClick('business_name')}>
                    Business Name <SortDirectionIndicator currentSelection={orderBy} validSelection={'business_name'} direction={sortDirection}/>
                </div>
                {   screenWidth > 1000 &&
                <div className='Customer-Request-Tile-Data Header-Title' onClick={() => handleOrderClick('city')}>
                    Business Location <SortDirectionIndicator currentSelection={orderBy} validSelection={'city'} direction={sortDirection}/>
                </div>
                }
                <div className='Customer-Request-Date Header-Title' onClick={() => handleOrderClick('date')}>
                    Date Received <SortDirectionIndicator currentSelection={orderBy} validSelection={'date'} direction={sortDirection}/>
                </div>
            </div>

        </div>
    )
};

const CustomerRequestHeader = ({customerHeaderObject}) => {
    const {setSearchInput,sortDirection,flipSortOrder,screenWidth, orderBy, handleOrderClick, searchList, selectSearchItem,resetList, 
        activeSort, setActiveSort} = customerHeaderObject;
    return(
        <div className='Customer-Request-Header'>
            <div className='Customer-Request-Top-Header'>
                <SearchBar setSearchInput={setSearchInput} searchList={searchList} selectSearchItem={selectSearchItem}/>
                <ControlBar resetList={resetList} activeSort={activeSort} setActiveSort={setActiveSort}/>
            </div>
            <BottomHeader screenWidth={screenWidth} sortDirection={sortDirection} orderBy={orderBy} handleOrderClick={handleOrderClick} />

        </div>
    )
};

const TileHoverPanel = ({customer,toggleFlag}) => {
    return (
        <div className='Customer-Request-Flag-Frame'>
            {   customer.flag &&
                <FaFlag className='Customer-Request-Flag-Checked' onClick={(event) => {toggleFlag(customer.id); event.stopPropagation()}}/>
            }
            {   !customer.flag &&
                <FaFlag className='Customer-Request-Flag-Unchecked' onClick={(event) => {toggleFlag(customer.id); event.stopPropagation()}}/>
            }
            
        </div>
    )
};

const CustomerTile = ({customer, selectCustomerRecord, toggleFlag, screenWidth}) => {
    const [ hover, setHover ] = useState();

    return(
        <div className='Vender-Request-Tile' onClick={() => selectCustomerRecord(customer.id)} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            { customer.form_type === 'New' &&
                <BsPersonFillAdd className='Customer-Request-Tile-Image'/>
            }
            { customer.form_type === 'Update' &&
                <BsPersonFillUp  className='Customer-Request-Tile-Image'/>
            }

            <div className='Customer-Request-Tile-Information'>
                <div className='Customer-Request-Tile-Data'>
                    {`${customer.form_type} Customer Request`}
                </div>
                <div className='Customer-Request-Tile-Data'>
                    {customer.trade_name}
                </div>
                {   screenWidth > 1000 &&
                <div className='Customer-Request-Tile-Data'>
                    {`${customer.city}, ${customer.state}`}
                </div>
                }

                <div className='Customer-Request-Date'>
                    {organizeDate(customer.date)}
                </div>
                {   screenWidth > 1000 &&
                <>
                {hover && <TileHoverPanel customer={customer} toggleFlag={toggleFlag}/>}
                {!hover &&
                    <div className='Customer-Request-Flag-Frame'>
                        {   customer.flag &&
                            <FaFlag className='Customer-Request-Flag'/>
                        }
                    </div>
                }
                </>
                }
 
            </div>
            
        </div>
    )
};

const EmptyCustomerDisplay = () => {
    return(
        <div className='Customer-Request-No-Display'>
            No Records to Display.
        </div>
    )
};

const CustomerRequestDisplay = ({customerRequestObject}) => {
    const { customerList, selectCustomerRecord, toggleFlag, screenWidth } = customerRequestObject;
    return(
        <div className='Customer-Request-Display'> 
            {   customerList && customerList.length > 0 &&
                customerList.map((customer, key) => (
                    <CustomerTile key={key} customer={customer} selectCustomerRecord={selectCustomerRecord} toggleFlag={toggleFlag} screenWidth={screenWidth}/>
                ))
            }
            {   customerList.length < 1 &&
                <EmptyCustomerDisplay />
            }
        </div>
    )
};

const ConfirmationWindow = ({title, confirm, deny}) => {
    return(
        <div className='Customer-Request-Confirmation'>
            <div className='Customer-Request-Confirmation-Header'>
                {title}
            </div>
            <div className='Customer-Request-Confirmation-Button-Group'>
            <button onClick={() => confirm()} className='Customer-Request-Confirmation-Button Customer-Request-Confirm-Button'>Yes</button>
            <button onClick={() => deny()} className='Customer-Request-Confirmation-Button Customer-Request-Deny-Button'>No</button>
            </div>

        </div>
    )
};

const ApprovalCustomerWindow = ({confirm, deny,approvalProps}) => {
    const approveConfirmationTitle = 'Please complete the form below to approve this customer.';
    const [complete,setComplete] = useState(false);

    const checkComplete = () => {
        let complete = true;

        if(!approvalProps.currency) complete=false;

        return complete;
    };

    useEffect(() => {
        if(checkComplete()) setComplete(true);
        else setComplete(false);
    },[approvalProps.currency]);

    return(
        <div className='Customer-Request-Confirmation'>
            <div className='Customer-Request-Confirmation-Header'>
                {approveConfirmationTitle}
            </div>

            <div className='Customer-Request-Confirmation-Body'>
                <div className='Customer-Request-Confirmation-Line'>
                    <DropDownSelection title={'Currency'} options={['USD', 'CAD']} setTo={approvalProps.setCurrency} width={150} initializedValue={approvalProps.currency}/>
                </div>
            </div>


            <div className='Customer-Request-Confirmation-Button-Group'>
            {   complete &&
                <button onClick={() => confirm()} className='Customer-Request-Confirmation-Button Customer-Request-Confirm-Button'>Approve</button>
            }  
                <button onClick={() => deny()} className='Customer-Request-Confirmation-Button Customer-Request-Deny-Button'>Cancel</button>
            </div>
        


        </div>
    )
};

const CustomerRecordHeader = ({customerRecordHeaderObject}) => {
    const {closeCustomerRecord,markCustomerInactive,markCustomerActive,customerRecord, confirmSelection, openRejection,enterApproval, editRecord, setEditRecord, updateRecord} = customerRecordHeaderObject;

    const inactiveConfirmationTitle = 'Are you sure you want to mark this customer record as inactive?';
    const activeConfirmationTitle = 'Are you sure you want to mark this customer record as active?';

    return(
        <div className='Customer-Request-Record-Header'>
            {   customerRecord.active && !editRecord &&
                <button onClick={() => enterApproval()} className='Customer-Request-Button'>Approve</button>
            }
            {   customerRecord.active && !editRecord &&
                <button onClick={() => openRejection()} className='Customer-Request-Button'>Reject</button>
            }
            {   customerRecord.active && !editRecord &&
                <button onClick={() => confirmSelection(inactiveConfirmationTitle,markCustomerInactive)} className='Customer-Request-Button'>Mark Inactive</button>
            }
            {   !customerRecord.active && !editRecord &&
                <button onClick={() => confirmSelection(activeConfirmationTitle,markCustomerActive)} className='Customer-Request-Button'>Mark Active</button>
            }
            {   customerRecord.active && !editRecord &&
                <button onClick={() => setEditRecord(true)} className='Customer-Request-Button'>Edit</button>
            }
            {   customerRecord.active && editRecord &&
                <button onClick={() => updateRecord(true)} className='Customer-Request-Button'>Submit</button>
            }

            <button onClick={() => closeCustomerRecord()} className='Customer-Request-Button Customer-Request-Button-End'>Exit</button>
        </div>
    )
};

const CustomerRecordAddressField = ({title,addressObject,editting}) => {
    if(addressObject && !editting){
        return(
            <div className='Customer-Request-Record-Form-Field'>
                <div className='Customer-Request-Record-Form-Field-Title'>
                    {title}
                </div>
                <div className='Customer-Request-Record-Form-Field-Value'>
                    {`${addressObject.street} ${addressObject.street2 ? addressObject.street2 : ''} ${addressObject.city}, ${addressObject.state} ${addressObject.zip} ${addressObject.country ? addressObject.country : ''}`}
                </div>
            </div>
        )
    }

    if(addressObject && editting){
        return(
            <div className='Customer-Request-Record-Form-Field'>
                <div className='Customer-Request-Record-Form-Field-Title'>
                    {title}
                </div>
                <div className='Customer-Request-Record-Form-Field-Section'>
                    <div className='Customer-Request-Record-Form-Under'>
                        <input style={{'width':'200px'}} value={addressObject.street} onChange={e => addressObject.setStreet(e.target.value)} className='Customer-Request-Record-Form-Input'/>
                        <div className='Customer-Request-Record-Form-Input-Title'>Street:</div>
                    </div>
                    <div className='Customer-Request-Record-Form-Under'>
                        <input style={{'width':'120px'}} value={addressObject.street2} onChange={e => addressObject.setStreet2(e.target.value)} className='Customer-Request-Record-Form-Input'/>
                        <div className='Customer-Request-Record-Form-Input-Title'>Street2:</div>
                    </div>
                    <div className='Customer-Request-Record-Form-Under'>
                        <input style={{'width':'150px'}} value={addressObject.city} onChange={e => addressObject.setCity(e.target.value)} className='Customer-Request-Record-Form-Input'/>
                        <div className='Customer-Request-Record-Form-Input-Title'>City:</div>
                    </div>
                    <div className='Customer-Request-Record-Form-Under'>
                        <input style={{'width':'40px'}} value={addressObject.state} onChange={e => addressObject.setState(e.target.value)} className='Customer-Request-Record-Form-Input'/>
                        <div className='Customer-Request-Record-Form-Input-Title'>State:</div>
                    </div>
                    <div className='Customer-Request-Record-Form-Under'>
                        <input style={{'width':'80px'}} value={addressObject.zip} onChange={e => addressObject.setZip(e.target.value)} className='Customer-Request-Record-Form-Input'/>
                        <div className='Customer-Request-Record-Form-Input-Title'>Zip:</div>
                    </div>
                    <div className='Customer-Request-Record-Form-Under'>
                        <input style={{'width':'80px'}} value={addressObject.country} onChange={e => addressObject.setCountry(e.target.value)} className='Customer-Request-Record-Form-Input'/>
                        <div className='Customer-Request-Record-Form-Input-Title'>Country:</div>
                    </div>
                </div>
            </div>
        )
    }
    else{
        return(
            <>
            </>
        )
    }

};

const CustomerRecordField = ({title, value, editting=false, selections=[], setTo}) => {
    if(value){
        return(
            <div className='Customer-Request-Record-Form-Field'>
                <div className='Customer-Request-Record-Form-Field-Title'>
                    {title}
                </div>

                {   !editting &&
                <div className='Customer-Request-Record-Form-Field-Value'>
                    {value}
                </div>
                }


                {   editting && selections.length < 1 &&
                <input className='Customer-Request-Record-Form-Field-Input' value={value} onChange={e => setTo(e.target.value)}/>
                }

                {   editting && selections.length > 0 &&
                <select className='Customer-Request-Dropdown'onChange={(e) => setTo(e.target.value)}>
                    <option value={value}>{value}</option>
                    {   selections.map((option, key) => (
                        <option className='Customer-Request-Dropdown-Selection' key={key} value={option}>{option}</option>
                    ))
                    }
                </select>
                }

            </div>
        )   
    }
    else{
        return(
            <>
            </>
        )
    }

};

const CustomerRecordListField = ({title,list}) => {
    if(list){
        let listString = '';

        list.forEach(value => {
            if(listString === '') listString = value
            else listString = listString + `, ${value}`;
        });
    
        return(
            <div className='Customer-Request-Record-Form-Field'>
                <div className='Customer-Request-Record-Form-Field-Title'>
                    {title}
                </div>
                <div className='Customer-Request-Record-Form-Field-Value'>
                    {listString}
                </div>
            </div>
        )
    }
    else{
        return(
            <>
            </>
        )
    }

};

const CustomerRecordContactField = ({title, contactObject}) => {
    if(contactObject){
        const contactString = `${contactObject.firstName} ${contactObject.lastName} - ${contactObject.number} ${contactObject.extension ? contactObject.extension : ''} - ${contactObject.email}`

        return(
            <div className='Customer-Request-Record-Contact-Form-Field'>
                <div className='Customer-Request-Record-Form-Field-Title'>
                    {title}
                </div>
                <div className='Customer-Request-Record-Form-Field-Spacer'/>

                <div className='Customer-Request-Record-Form-Field-Card'>
                    {   contactObject.company &&
                        <div className='Customer-Request-Record-Form-Field-Value'>
                            {`${contactObject.company}`}
                        </div>
                    }
                    <div className='Customer-Request-Record-Form-Field-Value'>
                        {`${contactObject.firstName} ${contactObject.lastName}`}
                    </div>
                    {   contactObject.title &&
                    <div className='Customer-Request-Record-Form-Field-Value'>
                        {`${contactObject.title}`}
                    </div>
                    }
                    <div className='Customer-Request-Record-Form-Field-Value'>
                        {`${contactObject.number}${contactObject.extension ? `: ${contactObject.extension}` : ''}`}
                    </div>
                    <div className='Customer-Request-Record-Form-Field-Value'>
                        {`${contactObject.email}`}
                    </div>
                    {   contactObject.address &&
                        <CustomerRecordAddressField title={'Address:'} addressObject={contactObject.address}/>
                    }
                </div>

            </div>
        )
    }
    else{
        return(
            <>
            </>
        )
    }

};

const CustomerRecordForm = ({customerFormObject,customerRecord,editting}) => {
    console.log(customerRecord)
    if(customerRecord){
  

        return(
        <div className='Customer-Request-Record-Form'>
            <CustomerRecordField title={'Date Recieved:'} value={organizeDate(customerRecord.date)}/>
            <CustomerRecordField title={'Form Type:'} value={customerFormObject.formType} editting={false} selections={['New', 'Update']} setTo={customerFormObject.setFormType}/>


            <div className='Customer-Request-Record-Spacer'/>

            <CustomerRecordField title={'Legal Name:'} value={customerFormObject.legalName} editting={editting} setTo={customerFormObject.setLegalName}/>
            <CustomerRecordField title={'Business Name:'} value={customerFormObject.businessName} editting={editting} setTo={customerFormObject.setBusinessName}/>
            <CustomerRecordField title={'EIN:'} value={customerRecord.fedEinSSn}/>
            <CustomerRecordField title={'Duns No:'} value={customerRecord.dunsNo}/>
            <CustomerRecordField title={'Inc State:'} value={customerRecord.incState}/>
            <CustomerRecordAddressField title={'Business Address:'} addressObject={customerFormObject.businessAddressObject} editting={editting}/>
            <CustomerRecordAddressField title={'Shipping Address:'} addressObject={customerFormObject.shippingAddressObject} editting={editting}/>

            <div className='Customer-Request-Record-Spacer'/>

            <CustomerRecordContactField title={'Primary Contact:'} contactObject={customerRecord.primaryContact}/>
            {   customerRecord.apContact &&
                <CustomerRecordContactField title={'AP Contact:'} contactObject={customerRecord.apContact}/>
            }

            <div className='Customer-Request-Record-Spacer'/>

            <CustomerRecordField title={'Requested Payment Method:'} value={customerRecord.paymentMethod}/>
            

            <div className='Customer-Request-Record-Spacer'/>

            {   customerRecord.bankAccount &&
            <>
                <CustomerRecordField title={'Bank Name:'} value={customerRecord.bankAccount.name}/>
                <CustomerRecordField title={'Name on Account:'} value={customerRecord.bankAccount.accountName}/>
                <CustomerRecordField title={'Routing Number:'} value={customerRecord.bankAccount.routingNumber}/>
                <CustomerRecordField title={'Account Number:'} value={customerRecord.bankAccount.accountNumber}/>
                <CustomerRecordField title={'Account Type:'} value={customerRecord.bankAccount.accountType}/>
                {   customerRecord.bankAccount.swift_code &&
                    <CustomerRecordField title={'Swift Code:'} value={customerRecord.bankAccount.swiftCode}/>
                }
                <CustomerRecordAddressField title={'Bank Address:'} addressObject={customerRecord.bankAccount.address}/>
                <CustomerRecordContactField title={'Bank Contact:'} contactObject={customerRecord.bankAccount.contact}/>
            </>
            } 

            
            {   customerRecord.creditCard &&
            <>
                <CustomerRecordField title={'Name on Card:'} value={customerRecord.creditCard.name}/>
                <CustomerRecordField title={'Card Number:'} value={customerRecord.creditCard.number}/>
                <CustomerRecordField title={'Card Expiration:'} value={customerRecord.creditCard.expiration}/>
                <CustomerRecordField title={'Card Security Code:'} value={customerRecord.creditCard.securityCode}/>
                <CustomerRecordField title={'Billing Zip:'} value={customerRecord.creditCard.billingZip}/>
            </>
            }

            <div className='Customer-Request-Record-Spacer'/>

            {   customerRecord.authorizedOfficers &&
                customerRecord.authorizedOfficers.map((officer,key) => (
                    <CustomerRecordContactField title={'Authorized Officer:'} key={key} contactObject={officer}/>
                ))
            }

            {   customerRecord.tradeReferences &&
                customerRecord.tradeReferences.map((reference,key) => (
                    <CustomerRecordContactField title={'Trade Reference:'} key={key} contactObject={reference}/>
                ))
            }

            <div className='Customer-Request-Record-Spacer'/>
            
            <CustomerRecordField title={'First Signer\'s Name:'} value={customerRecord.signer1Name}/>
            <CustomerRecordField title={'First Signer\'s Title:'} value={customerRecord.signer1Title}/>
            
            {   customerRecord.signer2Name &&
            <>
            <CustomerRecordField title={'Second Signer\'s Name:'} value={customerRecord.signer2Name}/>
            <CustomerRecordField title={'Second Signer\'s Title:'} value={customerRecord.signer2Title}/>
            </>
            }

            {   customerRecord.notes &&
            <>
            <CustomerRecordField title={'Notes'} value={customerRecord.notes}/>
            </>
            }
        </div>
    )
    } else return(<></>)
};

const CustomerRecordFile = ({fileName, selectionTitle, id, selectPdf}) => {
    return(
        <div className='Customer-Request-Record-File' onClick={() => window.open(`${SERVER_URL}${selectionTitle}/${fileName}`)}>
            <FaFilePdf className='Customer-Request-Record-File-Image'/>
            <div className='Customer-Request-Record-File-Name'>
                {fileName}
            </div>
        </div>
    )
};

const CustomerRecordFiles = ({customerRecord, selectPdf}) => {
    return(
        <div className='Customer-Request-Record-Files'>
            <div className='Customer-Request-Record-Files-Title'>
                Attached Files
            </div>
            <div className='Customer-Request-Record-Files-List'>
                {   customerRecord.creditSheetPath &&
                    <CustomerRecordFile fileName={customerRecord.creditSheetPath} selectionTitle={'CreditSheets'} id={customerRecord.creditSheet} selectPdf={selectPdf}/>
                }
                {   customerRecord.internationalWirePath &&
                    <CustomerRecordFile fileName={customerRecord.internationalWirePath} selectionTitle={'InternationalWires'} id={customerRecord.internationalWire} selectPdf={selectPdf}/>
                }
                {   customerRecord.voidedCheckPath &&
                    <CustomerRecordFile fileName={customerRecord.voidedCheckPath} selectionTitle={'VoidedChecks'} id={customerRecord.voidedCheck} selectPdf={selectPdf}/>
                }
                {   customerRecord.w9Path &&
                    <CustomerRecordFile fileName={customerRecord.w9Path}  selectionTitle={'W9s'} id={customerRecord.w9} selectPdf={selectPdf}/>
                }
            </div>
        </div>
    )
};

const CustomerRecordPdfViewer = ({closePdf,selectedPdf}) => {
    return(
        <div className='Customer-Request-Record-Pdf-Viewer-Window' onClick={() => closePdf()}>

            <div className='Customer-Request-Record-Pdf-Viewer'>
                {   selectedPdf &&
                <object data={`data:application/pdf;base64,${selectedPdf}`} type="application/pdf" width="100%" height="100%">
                    <p>Failed to Load PDF</p>
                </object>
                }
            </div>
        </div>
    )
};

const RejectionSection = ({setTo, title}) => {
    return(
        <div className='Customer-Request-Rejection-Section'>
            <input type='checkbox' className='Customer-Request-Rejection-Section-Button' onClick={e => setTo(e.target.checked)}/>
            <div className='Customer-Request-Rejection-Section-Title'>
                {title}
            </div>
        </div>
    )
};

const RejectionWindow = ({closeRejection, confirmReject,setRejectionText,setRejectionSections}) => {
    const [ formType, setFormType ] = useState(false);
    const [ customerInformation, setCustomerInformation ] = useState(false);
    const [ contactInformation, setContactInformation ] = useState(false);
    const [ paymentInformation, setPaymentInformation ] = useState(false);
    const [ achInformation, setAchInformation ] = useState(false);
    const [ voidedCheckInformation, setVoidedCheckInformation ] = useState(false);
    const [ coiInformation, setCoiInformation ] = useState(false);
    const [ w9Information, setW9Information ] = useState(false);
    const [ intlWireInformation, setIntlWireInformation ] = useState(false);


    useEffect(() => {
        let rejectionList = [];
        if(formType) rejectionList.push('formType');
        if(customerInformation) rejectionList.push('customerInformation');
        if(contactInformation) rejectionList.push('contacts');
        if(paymentInformation) rejectionList.push('payment');
        if(achInformation) rejectionList.push('ach');
        if(voidedCheckInformation) rejectionList.push('voidedCheck');
        if(coiInformation) rejectionList.push('coi');
        if(w9Information) rejectionList.push('w9');
        if(intlWireInformation) rejectionList.push('intlWire');

        setRejectionSections(rejectionList);

    },[formType,customerInformation,contactInformation,paymentInformation,achInformation,voidedCheckInformation,coiInformation,w9Information,intlWireInformation]);

    return(
        <div className='Customer-Request-Rejection-Window'>
            <div className='Customer-Request-Confirmation-Header'>
            Please enter a reason for rejecting this customer request.
            </div>

            <textarea className='Customer-Request-Rejection-Text' cols="50" rows="10" onChange={e => setRejectionText(e.target.value)}/>

            <div className='Customer-Request-Confirmation-Header'>
            Please select the sections you would like updated.
            </div>

            <div className='Customer-Request-Rejection-Sections'>
            <RejectionSection title={'Form Type'} setTo={setFormType}/>
            <RejectionSection title={'Customer Information'} setTo={setCustomerInformation}/>
            <RejectionSection title={'Contacts'} setTo={setContactInformation}/>
            <RejectionSection title={'Payment Information'} setTo={setPaymentInformation}/>
            <RejectionSection title={'ACH Details'} setTo={setAchInformation}/>
            <RejectionSection title={'Voided Check'} setTo={setVoidedCheckInformation}/>
            <RejectionSection title={'COI'} setTo={setCoiInformation}/>
            <RejectionSection title={'W9'} setTo={setW9Information}/>
            <RejectionSection title={'International Wire Instructions'} setTo={setIntlWireInformation}/>
            </div>

            <div className='Customer-Request-Confirmation-Button-Group'>
                <button onClick={() => confirmReject()} className='Customer-Request-Confirmation-Button Customer-Request-Confirm-Button'>Confirm Rejection</button>
                <button onClick={() => closeRejection()} className='Customer-Request-Confirmation-Button Customer-Request-Deny-Button'>Cancel</button>
            </div>
        </div>
    )
};

const CustomerRecordWindow = ({customerRecordObject,accessToken}) => {
    const {customerRecord, closeCustomerRecord,markCustomerInactive,markCustomerActive,approveCustomerRecord, rejectCustomerRecord} = customerRecordObject
    const [ confirmWindow, setConfirmWindow ] = useState(false);
    const [ approvalWindow, setApprovalWindow] = useState(false);
    const [ confirmText, setConfirmText ] = useState('');
    const [ callAfterFunction, setCallAfterFunction ] = useState();
    const [ pdfViewer, setPdfViewer ] = useState(false);
    const [ selectedPdf, setSelectedPdf ] = useState();

    const [ rejecting, setRejecting ] = useState(false);
    const [ rejectionText, setRejectionText ] = useState('');
    const [ rejectionSections, setRejectionSections ] = useState([]);

    const [currency, setCurrency] = useState('');

    const [ editRecord, setEditRecord ] = useState(false);

    const [ formType, setFormType ] = useState('');
    const [ customerType, setCustomerType ] = useState('');
    const [ legalName, setLegalName ] = useState('');
    const [ businessName, setBusinessName ] = useState('');

    const [ bunsinessStreet,setBunsinessStreet ] = useState('');
    const [ bunsinessStreet2,setBunsinessStreet2 ] = useState('');
    const [ bunsinessCity,setBunsinessCity ] = useState('');
    const [ bunsinessState,setBunsinessState ] = useState('');
    const [ bunsinessZip,setBunsinessZip ] = useState('');
    const [ bunsinessCountry,setBunsinessCountry ] = useState('');

    const [ shippingStreet,setShippingStreet ] = useState('');
    const [ shippingStreet2,setShippingStreet2 ] = useState('');
    const [ shippingCity,setShippingCity ] = useState('');
    const [ shippingState,setShippingState ] = useState('');
    const [ shippingZip,setShippingZip ] = useState('');
    const [ shippingCountry,setShippingCountry ] = useState('');

    const approvalProps = {
        currency: currency,
        setCurrency: setCurrency,
    };

    useEffect(() => {
        if(customerRecord){
            setFormType(customerRecord.formType);
            setCustomerType(customerRecord.customerType);
            setLegalName(customerRecord.legalName);
            setBusinessName(customerRecord.tradeName);
            
            if(customerRecord.address) {
                setBunsinessStreet(customerRecord.address.street);
                setBunsinessStreet2(customerRecord.address.street2);
                setBunsinessCity(customerRecord.address.city);
                setBunsinessState(customerRecord.address.state);
                setBunsinessZip(customerRecord.address.zip);
                if(customerRecord.address.country)setBunsinessCountry(customerRecord.address.country);
            };

            if(customerRecord.shippingAddress) {
                setShippingStreet(customerRecord.shippingAddress.street);
                setShippingStreet2(customerRecord.shippingAddress.street2);
                setShippingCity(customerRecord.shippingAddress.city);
                setShippingState(customerRecord.shippingAddress.state);
                setShippingZip(customerRecord.shippingAddress.zip);
                if(customerRecord.shippingAddress.country) setShippingCountry(customerRecord.shippingAddress.country);
            };

        };
    },[customerRecord]);

    const createCustomerRecordObject = () => {
        const updatedRecord = {
            id: customerRecord.id,
            formType: formType,
            customerType: customerType,
            legalName:legalName,
            businessName:businessName,
            addressId:customerRecord.businessAddress.id,
            street:bunsinessStreet,
            city:bunsinessCity,
            state:bunsinessState,
            zip:bunsinessZip,
            country:bunsinessCountry,
        };
        return updatedRecord;
    };

    const updateRecord = async () => {
        const updatedRecord = createCustomerRecordObject();
        const updatedRecordId = await newCustomerRequestService.editRecord(accessToken,updatedRecord);
        if(updatedRecordId) setEditRecord(false);        
    };

    const getW9 = async (id) => {
        const w9 = await newCustomerRequestService.getW9(accessToken,id);
        setSelectedPdf(w9);
    };

    const getVoidedCheck = async (id) => {
        const coi = await newCustomerRequestService.getVoidedCheck(accessToken,id);
        setSelectedPdf(coi);
    };

    const getRefSheet = async (id) => {
        const refSheet = await newCustomerRequestService.getRefSheet(accessToken,id);
        setSelectedPdf(refSheet);
    }

    const getInternationalWire = async (id) => {
        const internationalWire = await newCustomerRequestService.getInternationalWire(accessToken,id);
        setSelectedPdf(internationalWire);
    };

    const closePdf = () => {
        setPdfViewer(false);
        setSelectedPdf(null);
    };

    const selectPdf = (selection, id) => {
        setPdfViewer(true);
        if(selection === 'W9') getW9(id);
        if(selection === 'RefSheet') getRefSheet(id);
        if(selection == 'VoidedCheck') getVoidedCheck(id);
        if(selection == 'InternationalWire') getInternationalWire(id);
    };

    const denySelection = () => {
        setConfirmText('');
        setConfirmWindow(false);
    };

    const approveCustomer = () => {
        approveCustomerRecord(currency);
    };

    const approveSelection = () => {
        setConfirmText('');
        setConfirmWindow(false);
        callAfterFunction();
    };

    const closeRejection = () => {
        setRejecting(false);
    };

    const confirmReject = () => {
        rejectCustomerRecord(rejectionText,rejectionSections,closeRejection);
    };

    const openRejection = () => {
        setRejecting(true);
    };

    const exitApproval = () => {
        setApprovalWindow(false);
    };

    const enterApproval = () => {
        setApprovalWindow(true);
    };

    const confirmSelection = (text,confirmFunction) => {
        setConfirmText(text);
        setConfirmWindow(true);
        setCallAfterFunction(() => confirmFunction);
    };

    const customerRecordHeaderObject = {
        closeCustomerRecord:closeCustomerRecord,
        markCustomerInactive:markCustomerInactive,
        markCustomerActive:markCustomerActive,
        customerRecord:customerRecord,
        confirmSelection:confirmSelection,
        approveCustomerRecord:approveCustomerRecord,
        openRejection:openRejection,
        editRecord:editRecord,
        setEditRecord:setEditRecord,
        updateRecord:updateRecord,
        enterApproval:enterApproval,
    };
    
    const businessAddressObject = {
        street:bunsinessStreet,
        setStreet: setBunsinessStreet,
        street2:bunsinessStreet2,
        setStreet2: setBunsinessStreet2,
        city:bunsinessCity,
        setCity: setBunsinessCity,
        state:bunsinessState,
        setState: setBunsinessState,
        zip:bunsinessZip,
        setZip: setBunsinessZip,
        country:bunsinessCountry,
        setCountry: setBunsinessCountry,
    };

    const shippingAddressObject = {
        street:shippingStreet,
        setStreet: setShippingStreet,
        street2:shippingStreet2,
        setStreet2: setShippingStreet2,
        city:shippingCity,
        setCity: setShippingCity,
        state:shippingState,
        setState: setShippingState,
        zip:shippingZip,
        setZip: setShippingZip,
        country:shippingCountry,
        setCountry: setShippingCountry,
    };

    const customerFormObject = {
        customerRecord:customerRecord,
        editRecord:editRecord,
        formType:formType,
        setFormType:setFormType,
        customerType:customerType,
        setCustomerType:setCustomerType,
        legalName:legalName,
        setLegalName:setLegalName,
        businessName:businessName,
        setBusinessName:setBusinessName,
        businessAddressObject:businessAddressObject,
        shippingAddressObject:shippingAddressObject,
    };

    return(
        <div className='Customer-Request-Record-Window'>
            {   rejecting &&
                <RejectionWindow closeRejection={closeRejection} confirmReject={confirmReject} setRejectionText={setRejectionText} setRejectionSections={setRejectionSections}/>
            }
            {   !confirmWindow && !approvalWindow && !rejecting &&
            <>
                <CustomerRecordHeader customerRecordHeaderObject={customerRecordHeaderObject}/>
                {   customerRecord &&
                <>
                    <CustomerRecordForm customerFormObject={customerFormObject} customerRecord={customerRecord} editting={editRecord}/>
                    <CustomerRecordFiles customerRecord={customerRecord} selectPdf={selectPdf}/>
                </>
                }
                
            </>
            }
            {   approvalWindow &&
                <ApprovalCustomerWindow title={confirmText} confirm={approveCustomer} deny={exitApproval} approvalProps={approvalProps}/>
            }
            {   confirmWindow && 
                <ConfirmationWindow title={confirmText} confirm={approveSelection} deny={denySelection}/>
            }
            {   pdfViewer &&
                <CustomerRecordPdfViewer closePdf={closePdf} selectedPdf={selectedPdf}/>
            }
        </div>
    )
};

const Loader = () => {
  return (
    <RotatingLines
      strokeColor="rgb(6, 89, 79)"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true}
    />
  )
};

const ApprovalWindow = ({approvalFinished,closeWindow}) => {
    const [ closing, setClosing ] = useState(false);

    useEffect(() => {
        if(approvalFinished){
            setClosing(true);

            setTimeout(() => {
                closeWindow();
                setClosing(false);
            },1000)};

    },[approvalFinished]);

    return(
        <div className='Customer-Request-Approval'>
            {   !closing &&
            <>
            <Loader />
            <div className='Customer-Request-Approval-Text'>
                Please Wait... Approving Customer.
            </div>
            </>
            }
            {   closing &&
            <>
            <FaCheck className='Customer-Request-Approval-Symbol' />
            <div className='Customer-Request-Approval-Text'>
                Approved Customer Record
            </div>
            </>

            }
        </div>
    )
};

const CustomerRequests = () => {
    const contextObject = useContext(Context);

    const [recordId, setRecordId] = useState();

    const [ orderBy, setOrderBy ] = useState('date');

    const [ sortDirection, setSortDirection ] = useState('ASC');
    const [ activeSort, setActiveSort ] = useState(true);

    const [ customerList, setCustomerList ] = useState([]);
    const [ customerRecord, setCustomerRecord ] = useState({});
    const [ searchInput, setSearchInput ] = useState('');
    const [ searchList, setSearchList] = useState([]);

    const [ approving, setApproving ] = useState(false);
    const [ approvalFinished, setApprovalFinished ] = useState(false);


    const approveCustomerRecord = async (currency) => {
        setApprovalFinished(false);
        setApproving(true);
        const approvalObject = {
            customerId: recordId,
            currency:currency,
        };
        const approval = await newCustomerRequestService.approveRecord(contextObject.apiToken, approvalObject);
        console.log(approval.status)
        const boxFolderId = approval.data.box;
        const acumaticaId = approval.data.acumatica;
        if(approval.status > 199 && approval.status < 300){
            setApprovalFinished(true);
            window.open(`https://cornerstonedetentionproducts.app.box.com/folder/${boxFolderId}`)
            window.open(`https://cornerstoneinc.acumatica.com/Main?CompanyID=Pilot&ScreenId=AP303000&AcctCD=${acumaticaId}`)
        }
        else{
            alert('APPROVAL FAILURE');
        };
    };

    const closeWindow = () => {
        console.log('Close Window')
        setRecordId(null);
        setApproving(false);
        setApprovalFinished(false);
        refreshCustomerList();
        setSearchInput('');
    };

    const rejectCustomerRecord = ( rejectionText, rejectionSections, closeRejection) => {
        const rejection = {
            customerId:customerRecord.id,
            text:rejectionText,
            sections:rejectionSections,
        };

        newCustomerRequestService.submitRejectionRecord(contextObject.apiToken, rejection).then(res => closeRejection());
    };

    const markCustomerInactive = () => {
        newCustomerRequestService.inactivateRecord(contextObject.apiToken, recordId).then(res => {closeCustomerRecord(); refreshCustomerList()});
    };

    const markCustomerActive = () => {
        newCustomerRequestService.activateRecord(contextObject.apiToken, recordId).then(res => {closeCustomerRecord(); refreshCustomerList()});
    };

    const closeCustomerRecord = () => {
        setRecordId(null);
    };

    const resetList = () => {
        setSearchInput('');
        setOrderBy('date');
        setActiveSort(true);
        refreshCustomerList();
        setSortDirection('ASC');
    };

    const selectSearchItem = (businessName) => {
        const filterObject = {
            searchInput:businessName,
        };
        newCustomerRequestService.getCustomerList(contextObject.apiToken, filterObject).then(res => setCustomerList(res.data.customerList));
    };

    const refreshSearchList = async () => {
        if(searchInput){
            const searchObject = {
                searchInput: searchInput,
            };
            newCustomerRequestService.getSearchList(contextObject.apiToken, searchObject).then(res => setSearchList(res.data.searchList));
        }
    };

    useEffect(() => {
        refreshSearchList();
    },[searchInput]);

    const selectCustomerRecord = (id) => {
        setRecordId(id);
        newCustomerRequestService.getCustomerRecord(contextObject.apiToken, id).then(res => setCustomerRecord(res.data.customerRecord));
    };

    const flipSortOrder = () => {
        if(sortDirection === 'ASC') setSortDirection('DESC');
        else setSortDirection('ASC');
    };

    const handleOrderClick = (newOrderBy) => {
        if(newOrderBy === orderBy) flipSortOrder();
        else setOrderBy(newOrderBy)
    };

    const toggleFlag = (id) => {
        newCustomerRequestService.toggleCustomerFlag(contextObject.apiToken, id).then(res => refreshCustomerList());
    };

    const refreshCustomerList = () => {
        const filterObject = {
            active: activeSort,
            sortDirection: sortDirection,
            orderBy:orderBy,
            searchInput:searchInput,
        };
        newCustomerRequestService.getCustomerList(contextObject.apiToken, filterObject).then(res => setCustomerList(res.data.customerList));
    };

    useEffect(() => {
        refreshCustomerList();
    },[sortDirection, orderBy, activeSort]);

    const customerHeaderObject = {
        setSearchInput:setSearchInput,
        sortDirection:sortDirection,
        flipSortOrder:flipSortOrder,
        screenWidth:contextObject.screenWidth,
        handleOrderClick:handleOrderClick,
        orderBy: orderBy,
        searchList:searchList,
        selectSearchItem:selectSearchItem,
        resetList:resetList,
        activeSort:activeSort,
        setActiveSort:setActiveSort,
    };

    const customerRequestObject = {
        customerList:customerList,
        selectCustomerRecord:selectCustomerRecord,
        toggleFlag:toggleFlag,
        screenWidth: contextObject.screenWidth,
    };

    const customerRecordObject = {
        customerRecord:customerRecord,
        closeCustomerRecord:closeCustomerRecord,
        markCustomerInactive:markCustomerInactive,
        markCustomerActive:markCustomerActive,
        approveCustomerRecord:approveCustomerRecord,
        contextObject: contextObject,
        rejectCustomerRecord:rejectCustomerRecord,
    };

    return(
        <div className='Customer-Request-Window'>
            {   !recordId && !approving &&
            <>
                <CustomerRequestHeader customerHeaderObject={customerHeaderObject}/>
                <CustomerRequestDisplay customerRequestObject={customerRequestObject}/>      
            </>
            }
            {   recordId && !approving &&
                <CustomerRecordWindow accessToken={contextObject.apiToken} customerRecordObject={customerRecordObject}/>
            }
            {   approving &&
                <ApprovalWindow approvalFinished={approvalFinished} closeWindow={closeWindow}/>
            }

        </div>
    )
};

export default CustomerRequests;