import { useEffect, useState,useContext } from 'react';
import './ProjectBooking.css';
import {Context} from '../Context';
import LoadingSpinner from '../Components/LoadingSpinner';
import newProjectBookingDataService from '../Services/ProjectBookingService';
import CustomerSelector from '../Customers/CustomerSelector';
import { FaDotCircle } from "react-icons/fa";
import { MdError  } from "react-icons/md";
import { HiBadgeCheck } from "react-icons/hi";
import { IoIosAlert } from "react-icons/io";
import moment from 'moment';

import AddressInputField from '../Inputs/AddressInputField';
import StandardInputField from '../Inputs/StandardInputField';

const serverPath = 'https://www.cornerstoneinc.io/'

const branchOptions = [
    {text:'02 - DEC Construction', value:'02'},
    {text:'04 - Electronics', value:'04'},
    {text:'06 - C3 Mattresses', value:'06'},
    {text:'07 - Airteq Locks', value:'07'},
    {text:'09 - Modular Construction', value:'09'},
    {text:'12 - Service North', value:'12'},
    {text:'61 - Service East', value:'61'},
    {text:'62 - Service Southwest', value:'62'},
    {text:'64 - Service Southeast', value:'64'},
    {text:'66 - Service South', value:'66'},
];

const billingOptions = [
    {text:'Complete At Start', value:'upfront'},
    {text:'On Visits', value:'onVisit'},
    {text:'Monthly', value:'monthly'},
    {text:'Quarterly', value:'quarterly'},
    {text:'Semiannual', value:'semiannual'}
];

const insuranceProgramOptions = [
    {text: 'Contractor Controlled Insurance Program', value: 'CCIP'},
    {text: 'Owner Controlled Insurance Program', value: 'OCIP'},
    {text: 'None', value: null}
];

const taxabilityOptions = [
    {text:'Customer Taxed', value:'Customer'},
    {text:'Contractor Taxed',value:'Contractor'},
    {text:'Exempt',value:'Exempt'}
];

const requestTypeOptions = [
    {text:'Project', value:'Project'},
    {text:'Service Contract',value:'Service'}
];

const serviceBudgetUnits = [
    {text:'Hour', value:'HOUR'},
    {text:'Lump Sum', value:'LS'}
]

//  - - - - - - - - - - - API SUPPORT CALLS - - - - - - - - - - -
const sendProjectBudget = async (apiToken, budgetImport, filename, setBudgetResponse, setBudgetError) => {
    newProjectBookingDataService.uploadBudget(apiToken,budgetImport[0],filename)
    .then(response =>{
        setBudgetResponse(response.data);
    }).catch( e=> {
        setBudgetError(e.toString());

    });
};

const getBudgetTemplate = async () => {
    newProjectBookingDataService.getBlankTemplate()
};

const getFilteredCostCodeList = async (apiToken, costCode, setCostCodeList) => {
    newProjectBookingDataService.getFilteredCostCodeList(apiToken,costCode)
    .then(response =>{
        setCostCodeList(response.data.responseArray);
    }).catch( e=> {
        console.log(e.toString());
    });
};

const getFilteredProjectManagers = async (apiToken,branch,setProjectManagerList,setProjectManagerError) => {
    newProjectBookingDataService.getFilteredProjectManagerList(apiToken,branch)
    .then(response =>{
        if(response.data.length > 0){
            let projectManagerOptions = [];
            response.data.forEach(element => {
                const tempPM = {text: `${element.id} - ${element.name} - ${element.email}`, value: element.id}
                projectManagerOptions.push(tempPM);
            });
            setProjectManagerList(projectManagerOptions);
        }
        else{
            setProjectManagerList([{text:'No Project Managers Listed', value:null},]);
        }

    }).catch( e=> {
        setProjectManagerError(e.toString());
    });
};

const sendContractFile = async (apiToken,file,filename,setContractSuccess,setContractError) => {
    newProjectBookingDataService.postContract(apiToken,file,filename)
    .then(response =>{
        setContractSuccess(response.data.id);
    }).catch( e => {
        setContractError('Server Failed to Upload Contract Document. Please Contact IT for support.');
    });
};

const sendPrevailingWageFile = async (apiToken,file,filename,setPrevailingWageSuccess,setPrevailiingWageError) => {
    newProjectBookingDataService.postPrevailingWage(apiToken,file,filename)
    .then(response =>{
        setPrevailingWageSuccess(response.data.id);
    }).catch( e=> {
        setPrevailiingWageError('Server Failed to Upload Certified Payroll / Prevailing Wage Document. Please Contact IT for support.');
    });
};

const sendBondFile = async (apiToken,file,filename,setBondSuccess,setBondError) => {
    newProjectBookingDataService.postBond(apiToken,file,filename)
    .then(response =>{
        setBondSuccess(response.data.id);
    }).catch( e => {
        setBondError('Server Failed to Upload Bonding Document. Please Contact IT for support.');
    });
};

const sendTaxExemptFile = async (apiToken,file,filename,setTaxExemptSuccess,setTaxExemptError) => {
    newProjectBookingDataService.postTaxExempt(apiToken,file,filename)
    .then(response =>{
        setTaxExemptSuccess(response.data.id);
    }).catch( e=> {
        setTaxExemptError('Server Failed to Upload Tax Exemption Document. Please Contact IT for support.');
    });
};

const sendCertificateFile = async (apiToken,file,filename,setCertificateSuccess,setCertificateError) => {
    newProjectBookingDataService.postCertificate(apiToken,file,filename)
    .then(response =>{
        setCertificateSuccess(response.data.id);
    }).catch( e=> {
        setCertificateError('Server Failed to Upload OCIP / CCIP Document. Please Contact IT for support.');
    });
};

const sendProjectBookingRequest = async (apiToken,projectBookingObject,setProjectBookingSuccess,setProjectBookingError) => {
    newProjectBookingDataService.submitProjectBookingRequest(apiToken,projectBookingObject)
    .then(response =>{
        setProjectBookingSuccess(response.data.id);
    }).catch( e=> {
        setProjectBookingError('Server Failed to Upload Project Booking Request. Please Contact IT for support.');
    });
};

const updateProjectBookingRequest = async (apiToken,projectBookingObject,setProjectBookingSuccess,setProjectBookingError) => {
    newProjectBookingDataService.updateProjectBookingRequest(apiToken,projectBookingObject)
    .then(response =>{
        setProjectBookingSuccess(response.data.id);
    }).catch( e=> {
        setProjectBookingError('Server Failed to Upload Project Booking Request. Please Contact IT for support.');
    });
};

const sendTaskFile = async (apiToken,taskFile,projectName,setTaskFileSuccess,setTaskFileError) => {
    newProjectBookingDataService.updateProjectBookingTask(apiToken,taskFile,projectName)
    .then(response =>{
        setTaskFileSuccess(response.data.fileName);
    }).catch( e=> {
        setTaskFileError('Server Failed to Upload Task File Document. Please Contact IT for support.');
    });
};

const sendRevenueFile = async (apiToken,revenueFile,projectName,setRevenueFileSuccess,setRevenueFileError) => {
    newProjectBookingDataService.updateProjectBookingRevenue(apiToken,revenueFile,projectName)
    .then(response =>{
        setRevenueFileSuccess(response.data.fileName);
    }).catch( e=> {
        setRevenueFileError('Server Failed to Upload Revenue File Document. Please Contact IT for support.');
    });
};

const sendCostFile = async (apiToken,costFile,projectName,setCostFileSuccess,setCostFileError) => {
    newProjectBookingDataService.updateProjectBookingCost(apiToken,costFile,projectName)
    .then(response =>{
        setCostFileSuccess(response.data.fileName);
    }).catch( e=> {
        setCostFileError('Server Failed to Upload Cost File Document. Please Contact IT for support.');
    });
};

const getServiceContracts = async (apiToken,projectId,setServiceContracts,setServiceContractsError) => {
    newProjectBookingDataService.getServiceContracts(apiToken,projectId)
    .then(response =>{
        if(response.data.serviceContracts.length > 0){
            setServiceContracts(response.data.serviceContracts);
            return response.data.serviceContracts;
        }
        else{
            return null;
        }

    }).catch( e=> {
        setServiceContractsError(e.toString());
    });
};

const createServiceContractTemplate = async (apiToken,serviceContractList,setToFunction) => {
    newProjectBookingDataService.postServiceContractTemplate(apiToken,serviceContractList)
    .then(response =>{
        if(response.status > 199 && response.status < 300){
            setToFunction(response.data.serviceCostTemplate);
            const newWindow = window.open(`${serverPath+response.data.serviceCostTemplate}`, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }
        else{
            return null;
        }

    }).catch( e=> {
        console.log(e.toString());
    });
};

const sendServiceCostFile = async (apiToken,serviceCostFile,projectName,setServiceTaskFilename,setServiceRevenueFilename,setServiceCostFilename,setServiceFilesComplete,serviceContractList,setServiceCostFileError) => {
    newProjectBookingDataService.postServiceCostFile(apiToken,serviceCostFile,serviceContractList,projectName)
    .then(response => {
        setServiceTaskFilename(response.data.taskFilename);
        setServiceRevenueFilename(response.data.revenueFilename);
        setServiceCostFilename(response.data.costFilename);
        setServiceFilesComplete(true);
    }).catch( e=> {
        setServiceCostFileError('Server Failed to Upload Service Budget. Please Contact IT for support.');
    });
};

//  - - - - - - - - - - - HELPER FUNCTIONS - - - - - - - - - - -
const getDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    today = mm + '.' + dd + '.' + yyyy;
    return today;
};

const organizeDate = (date) => {
    return moment(date).format("MMM Do YYYY");
};

const organizeInputDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
};

const removeArrayItem = (array, index) => { 
    if (index > -1) {
        array.splice(index,1);
    }
    return array;
};

const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const usdFormatter = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return formatter.format(value);
};



//  - - - - - - - - - - - OLD BUDGET COMPONENTS - - - - - - - - - - -
/*
const ColumnComponent = ({title, value, setTo, editing, width, codeList, setTitle, options}) => {
    const [match, setMatch] = useState(false);

    const cssProps = {
        '--columnWidth': `${width}px`
    };

    useEffect(() => {
        setMatch(false);

        if(codeList){
            codeList.forEach(element => {
                if(element.costCode === value){
                    setMatch(true);
                    setTo(value);
                    setTitle(element.title);
                }
            });
        };
    },[value]);

    return (
        <>
        {   !editing && title !== 'Cost' &&
            <div className='Project-Booking-Budget-Line-Column' style={cssProps}>
                {value}
            </div>
        }
        {   !editing && title === 'Cost' &&
            <div className='Project-Booking-Budget-Line-Column' style={cssProps}>
                {`$${numberWithCommas(value)}`}
            </div>
        }
        {   editing &&
        <div className='Project-Booking-Budget-Line-Column-Edit'>
            {   !options &&
                <input className='Project-Booking-Budget-Line-Column' value={value} onChange={e => setTo(e.target.value)} style={cssProps}/>
            }
            {   options &&
                <select className='Project-Booking-Budget-Line-Column' value={value} style={cssProps} onChange={e => setTo(e.target.value)}>
                    {   
                        options.map((element, key) => (
                            <option key={key} value={element.value}>{element.text}</option>
                        ))
                    }
                </select>
            }
            {   codeList &&
                <div className='Project-Booking-Budget-Line-CC-List'>
                    {   codeList && !match &&
                    codeList.map((element,key) => (
                        <div className='Project-Booking-Budget-Line-CC-List-Item' key={key} onClick={() => {setTo(element.costCode); setTitle(element.title)}}>
                            {`${element.costCode} - ${element.title}`}
                        </div>
                    ))
                    }
                </div>
            }
        </div>
        
        }
        </>
    )
};

const BudgetControlComponent = ({hovering, expanded, setExpanded }) => {
    return(
        <div className='Project-Booking-Budget-Line-Control'>
            {   !expanded && hovering &&
            <div className='Project-Booking-Budget-Line-Button' onClick={() => setExpanded(true)}>
                <MdExpandMore className='Project-Booking-Budget-Line-Button-Icon'/>
            </div>
            }
            {   expanded &&
            <div className='Project-Booking-Budget-Line-Button' onClick={() => setExpanded(false)}>
                <MdExpandLess  className='Project-Booking-Budget-Line-Button-Icon'/>
            </div>
            }
        </div>
    )
};

const EstimateControlComponent = ({editing, hovering, setEditing, deleteLine, submitChanges}) => {
    return(
        <div className='Project-Booking-Budget-Line-Control'>
            {   !editing && hovering &&
            <div className='Project-Booking-Budget-Line-Button' onClick={() => setEditing(true)}>
                <MdOutlineEdit className='Project-Booking-Budget-Line-Button-SM-Icon'/>
            </div>
            }
            {   editing &&
            <>
                <div className='Project-Booking-Budget-Line-Button'>
                    <TiCancel className='Project-Booking-Budget-Line-Button-SM-Icon Icon-Cancel' onClick={() => deleteLine()}/>
                </div>

                <div className='Project-Booking-Budget-Line-Button'>
                    <FaCheck className='Project-Booking-Budget-Line-Button-SM-Icon Icon-Approve' onClick={() => submitChanges()}/>
                </div>
            </>
            }
        </div>
    )
};

const BudgetLine = ({costCode='', title='', index=null, description, group, total, estimateItemArray,updateBudgetLine,deleteEstimateItem}) => {
    const [lineCostCode, setCostCode] = useState(costCode);
    const [lineTitle, setTitle] = useState(title);  
    const [accountGroup, setGroup] = useState(group);
    const [lineTotal, setTotal] = useState(total);
    const [expanded, setExpanded ] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('');

    const [hovering, setHovering] = useState();

    const cssProps = {
    '--backgroundColor':backgroundColor
    }

    useEffect(() => {
        if(expanded) setBackgroundColor('rgb(204, 204, 204)');
        else setBackgroundColor('');
    },[expanded]);

    return(
        <>
        <div className='Project-Booking-Budget-Line' style={cssProps} onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)} onClick={() => setExpanded(!expanded)}>
            <ColumnComponent title={'Cost Code'} value={lineCostCode} setTo={setCostCode} width={100}/>
            <ColumnComponent title={'Code Title'} value={lineTitle} width={350}/>
            <ColumnComponent title={'Account Group'} value={accountGroup} setTo={setGroup} width={120}/>
            <ColumnComponent title={'Cost'} value={`${parseFloat(lineTotal).toFixed(2)}`} setTo={setTotal} width={100}/>
            <BudgetControlComponent hovering={hovering} expanded={expanded} setExpanded={setExpanded}/>
        </div>
        {   expanded &&
            estimateItemArray.map((element,key) => (
                <EstimateLineItem key={key} costCode={costCode} index={key} description={element.description} group={element.group} total={element.total} updateBudgetLine={updateBudgetLine} deleteEstimateItem={deleteEstimateItem}/>
            ))
        }
        </>
    )
};

const EstimateLineItem = ({costCode='', title='', index=null, description, group, total, assignBudgetLine, updateBudgetLine, deleteEstimateItem}) => {
    const contextObject = useContext(Context);

    const [editing, setEditing] = useState(false);
    const [lineCostCode, setCostCode] = useState(costCode);
    const [lineTitle, setTitle] = useState(title);  
    const [lineDescription, setDescription] = useState(description);
    const [accountGroup, setGroup] = useState(group);
    const [lineTotal, setTotal] = useState(total);

    const [costCodeList, setCostCodeList] = useState([]);
    const [hovering, setHovering] = useState();

    const ccsProps = {
        '--backgroundColor' : 'rgb(220, 220, 220)',
    };

    const resetFields = () => {
        setCostCodeList([]);
        setCostCode(costCode);
        setTitle(title);
        setDescription(description);
        setGroup(group);
        setTotal(total);
    };

    useEffect(() => {
        setCostCodeList([]);
    },[lineTitle]);

    useEffect(() => {
        resetFields();
    },[costCode,title,description,group,total]);

    useEffect(() => {
        if(lineCostCode) getFilteredCostCodeList(contextObject.apiToken,lineCostCode,setCostCodeList);
    },[lineCostCode]);

    const submitChanges = () => {
        setEditing(false);
        if(costCode === lineCostCode && group === accountGroup && total === lineTotal){
            // Nothing Changed
        }
        else{
            if(assignBudgetLine) assignBudgetLine(index,lineCostCode,lineDescription,accountGroup,lineTitle,lineTotal);
            else updateBudgetLine(index,costCode,group,total,lineCostCode,lineDescription,accountGroup,lineTitle,lineTotal)
        };
    };

    const deleteLine = () => {
        setEditing(false);
        deleteEstimateItem(costCode,group,index,total);
    };

    return(
        <div className='Project-Booking-Budget-Line' onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)} style={ccsProps}>
            {   !editing &&
                <ColumnComponent title={'Space'} value={''} width={100}/>
            }
            {   editing &&
                <ColumnComponent title={'Cost Code'} value={lineCostCode} setTo={setCostCode} editing={editing} width={90} codeList={costCodeList} setTitle={setTitle}/>
            }
            {   !editing &&
                <ColumnComponent title={'Description'} value={lineDescription} setTo={setDescription} editing={editing} width={350}/>
            }
            {   editing &&
                <ColumnComponent title={'Description'} value={lineDescription} setTo={setDescription} editing={editing} width={342}/>
            }
            {   !editing &&
                <ColumnComponent title={'Account Group'} value={accountGroup} setTo={setGroup} editing={editing} width={120}/>
            }
            {   editing &&
                <ColumnComponent title={'Account Group'} value={accountGroup} setTo={setGroup} editing={editing} width={115} options={[{text:'M',value:'M'},{text:'L',value:'L'},{text:'S',value:'S'},{text:'E',value:'E'},{text:'O',value:'O'}]}/>
            }
            <ColumnComponent title={'Cost'} value={`${lineTotal}`} setTo={setTotal} editing={editing} width={100}/>
            <EstimateControlComponent editing={editing} hovering={hovering} setEditing={setEditing} deleteLine={deleteLine} submitChanges={submitChanges}/>
        </div>
    );
};

const ProjectBudgetForm = ({formattedBudget, assignBudgetLine, updateBudgetLine, setTaxesFound, setPerDiemFound, setBondFound, deleteEstimateItem}) => {
    return(
        <div className='Project-Booking-Budget-Form'> 
            {
            Object.keys(formattedBudget).map((identifier,key) => {
                if(identifier != 'unassigned') {
                    if(formattedBudget[identifier].costCode === taxesCode || formattedBudget[identifier].costCode === useTaxCode || formattedBudget[identifier].costCode === salesTaxCode) setTaxesFound(true);
                    if(formattedBudget[identifier].costCode === perDiemCode) setPerDiemFound(true);
                    if(formattedBudget[identifier].costCode === bondCode) setBondFound(true);
                    
                    return(
                        <BudgetLine key={key} title={formattedBudget[identifier].title} costCode={formattedBudget[identifier].costCode} group={formattedBudget[identifier].group} total={formattedBudget[identifier].total} estimateItemArray={formattedBudget[identifier].estimateItemArray} updateBudgetLine={updateBudgetLine} deleteEstimateItem={deleteEstimateItem}/>
                    )
                };
            })
            }

            {   formattedBudget.unassigned.length > 0 &&
                <div className='Project-Booking-Budget-Section-Title'>Needs Assigned</div>
            }
            { formattedBudget.unassigned.length > 0 &&
            formattedBudget.unassigned.map((element,key) => (
                <EstimateLineItem key={key} index={key} description={element.description} group={element.group} total={element.total} assignBudgetLine={assignBudgetLine} deleteEstimateItem={deleteEstimateItem}/>
            ))
            }

            {

            }
        </div>
    )
};

const TotalsBar = ({materialTotal,laborTotal,subcontractorTotal,equipmentTotal,otherTotal,totalBudget}) => {
    return(
        <div className='Project-Booking-Budget-Totals'>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Materials $${materialTotal}`}
            </div>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Labor $${laborTotal}`}
            </div>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Subcontractors $${subcontractorTotal}`}
            </div>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Equipment $${equipmentTotal}`}
            </div>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Other $${otherTotal}`}
            </div>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Total Budget $${totalBudget}`}
            </div>
        </div>
    )
};

const [updating, setUpdating] = useState(false);
const [newEstimateItem, setNewEstimateItem] = useState(false);
const [taxesFound, setTaxesFound] = useState(false);
const [perDiemFound, setPerDiemFound] = useState(false);
const [bondFound, setBondFound] = useState(false);
const [materialTotal, setMaterialTotal] = useState(0);
const [laborTotal, setLaborTotal] = useState(0);
const [equipmentTotal, setEquipmentTotal] = useState(0);
const [subcontractorTotal, setSubcontractorTotal] = useState(0);
const [otherTotal, setOtherTotal] = useState(0);
const [totalBudget, setTotalBudget] = useState(0);
const [taxError, setTaxError] = useState('');

// Assigns an unassigned estimate item
const assignBudgetLine = (index,costCode,description,group,title,total) => {
    let newFormattedBudget = formattedBudget;

    refreshForms();

    if(index >= 0){
        const newUnassignedArray = removeArrayItem(formattedBudget.unassigned,index);
        newFormattedBudget.unassigned = newUnassignedArray;
    }
    else{
        setNewEstimateItem(false);
    }

    const budgetKey = `${costCode}${group}`;

    let updated = false;
    for (const [key, value] of Object.entries(newFormattedBudget)) {
        if(key == budgetKey) {
            const newEstimateItem = {description:description, group:group, total:total};
            newFormattedBudget[budgetKey].estimateItemArray.push(newEstimateItem);
            const newTotal = parseFloat(newFormattedBudget[budgetKey].total) + parseFloat(total);
            newFormattedBudget[budgetKey].total = newTotal.toFixed(2);
            updated = true;
            break;
        };
    };

    if(!updated){
        const newEstimateItem = {description:description, group:group, total:total};
        newFormattedBudget[budgetKey] = {costCode:costCode, estimateItemArray:[newEstimateItem,], group:group, title:title, total:parseFloat(total)}
    };
    setFormattedBudget(newFormattedBudget);

    setTimeout(() => {
        updateTotals();
    },100);
};

// Updates an already assigned estimate item
const updateBudgetLine = (index,originalCode,originalGroup,oldTotal,costCode,description,group,title,total) => {
    let newFormattedBudget = formattedBudget;

    refreshForms();

    // Remove from the existing Item Array
    const oldKey = `${originalCode}${originalGroup}`;
    const newEstimateItemArray = removeArrayItem(formattedBudget[oldKey].estimateItemArray,index);
    newFormattedBudget[oldKey].estimateItemArray = newEstimateItemArray;

    // Subtract from Total
    const newTotal = newFormattedBudget[oldKey].total - parseFloat(oldTotal);
    newFormattedBudget[oldKey].total = newTotal.toFixed(2);

    if(!newTotal > 0){
        delete newFormattedBudget[oldKey]
    }

    // Add to New Code 
    const newKey = `${costCode}${group}`;
    let updated = false;
    for (const [key, value] of Object.entries(newFormattedBudget)) {
        if(key == newKey) {
            const newEstimateItem = {description:description, group:group, total:total};
            newFormattedBudget[newKey].estimateItemArray.push(newEstimateItem);
            const newTotal = parseFloat(newFormattedBudget[newKey].total) + parseFloat(total);
            newFormattedBudget[newKey].total = newTotal.toFixed(2);
            updated = true;
            break;
        };
    };

    if(!updated){
        const newEstimateItem = {description:description, group:group, total:total};
        newFormattedBudget[newKey] = {costCode:costCode, estimateItemArray:[newEstimateItem,], group:group, title:title, total:total}
    };

    setFormattedBudget(newFormattedBudget);

    setTimeout(() => {
        updateTotals();
    },100);
};

// Deletes an estimate item
const deleteEstimateItem = (costCode,group,index,total) => {
    if(index === -1){
        setNewEstimateItem(false);
    }
    else{
        let newFormattedBudget = formattedBudget;

        refreshForms();

        if(!costCode){
            const newUnassignedArray = removeArrayItem(formattedBudget.unassigned,index);
            newFormattedBudget.unassigned = newUnassignedArray;
        }
        else{
            // Remove from the existing Item Array
            const oldKey = `${costCode}${group}`;
            const newEstimateItemArray = removeArrayItem(formattedBudget[oldKey].estimateItemArray,index);
            newFormattedBudget[oldKey].estimateItemArray = newEstimateItemArray;

            // Subtract from Total
            const newTotal = newFormattedBudget[oldKey].total - parseFloat(total);
            newFormattedBudget[oldKey].total = newTotal.toFixed(2);

            if(!newTotal > 0){
                delete newFormattedBudget[oldKey]
            };
        };

        setFormattedBudget(newFormattedBudget);

        setTimeout(() => {
            updateTotals();
        },100);
    };
};


    // Refreshes Budget by setting updating to true temporary
    const refreshForms = () => {
        setUpdating(true);

        setTimeout(() => {
            setUpdating(false);
        },100);
    };

    // Updates the totals
    const updateTotals = () => {
        let tempMaterial = 0;
        let tempLabor = 0;
        let tempEquipment = 0;
        let tempSubcontractor = 0;
        let tempOther = 0;

        Object.keys(formattedBudget).map((identifier,key)=> {
            if(formattedBudget[identifier].group === 'M') tempMaterial += parseFloat((formattedBudget[identifier].total));
            else if(formattedBudget[identifier].group === 'L') tempLabor += parseFloat((formattedBudget[identifier].total));
            else if(formattedBudget[identifier].group === 'E') tempEquipment += parseFloat((formattedBudget[identifier].total));
            else if(formattedBudget[identifier].group === 'S') tempSubcontractor += parseFloat((formattedBudget[identifier].total));
            else if(formattedBudget[identifier].group === 'O') tempOther += parseFloat((formattedBudget[identifier].total));
        });

        setMaterialTotal(numberWithCommas(parseFloat((tempMaterial).toFixed(2))));
        setLaborTotal(numberWithCommas(parseFloat((tempLabor).toFixed(2))));
        setEquipmentTotal(numberWithCommas(parseFloat((tempEquipment).toFixed(2))));
        setSubcontractorTotal(numberWithCommas(parseFloat((tempSubcontractor).toFixed(2))));
        setOtherTotal(numberWithCommas(parseFloat((tempOther).toFixed(2))));

        const tempTotal = tempMaterial+tempLabor+tempEquipment+tempSubcontractor+tempOther
        setTotalBudget(numberWithCommas(parseFloat((tempTotal).toFixed(2))));
    };

    // Do this when formatted Budget changes
    useEffect(() => {
        if(formattedBudget) updateTotals();
    },[formattedBudget]);

    // Clear the Budget
    const clearBudget = () => {
        setBudgetImport(null);
        setFormattedBudget(null);
    };

    // Do this when error on budget
    useEffect(() => {
        if(!formattedBudget && formattedBudgetError) clearBudget();
    },[formattedBudgetError]);

*/

//  - - - - - - - - - - - UPDATED BUDGET COMPONENTS - - - - - - - - - - -
const BudgetComponent = ({budgetResponse,budgetResponseErrors}) => {
    const BudgetHeader = () => {
        return(
            <div className='Project-Booking-Budget-Header'>
                <div className='Project-Booking-Budget-Header-Column' style={{width:`100px`}}>
                    Task
                </div>

                <div className='Project-Booking-Budget-Header-Column' style={{width:`300px`}}>
                    Description
                </div>

                <div className='Project-Booking-Budget-Header-Column' style={{width:`200px`}}>
                    Amount
                </div>
            </div>
        )
    };

    const BudgetDisplay = () => {
        return(
            <div className='Project-Booking-Budget-Display'>
                {
                    Object.keys(budgetResponse.stats).map((task) => (
                        <div className='Project-Booking-Budget-Line'>

                            <div className='Project-Booking-Budget-Column' style={{width:`100px`}}>
                                {task}
                            </div>
                            
                            <div className='Project-Booking-Budget-Column' style={{width:`300px`}}>
                                {budgetResponse.stats[task].title}
                            </div>

                            <div className='Project-Booking-Budget-Column' style={{width:`200px`}}>
                                {usdFormatter(budgetResponse.stats[task].amount)}
                            </div>

                        </div>
                    ))
                }
            </div>
        )
    };

    const BudgetErrors = () => {
        return(
            <div className='Project-Booking-Budget-Error-List'>
                {
                    budgetResponse.errorList.map((error) => (
                        <div className='Project-Booking-Budget-Error'>
                            {error}
                        </div>
                    ))
                }
            </div>
        )
    };

    const formattedTotal = (number) => {
        const currencyFormat = number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });

        return(currencyFormat);
    };


    return(
        <>
            <div className='Project-Booking-Budget-Window'>
                    <BudgetHeader />
                    <BudgetDisplay />
                    { budgetResponse.errorList.length > 0 &&
                        <BudgetErrors />
                    }
            </div>

            <div className='Project-Booking-Budget-Results'>
                <div className='Project-Booking-Budget-Results-Title'>
                    Total Estimated Cost: 
                </div>
                <div className='Project-Booking-Budget-Results-Cost'>
                    {formattedTotal(budgetResponse.totalCost)}
                </div>
                { budgetResponseErrors &&
                    <div className='Project-Booking-Budget-Results-Error'>
                        {budgetResponseErrors}
                    </div>
                }
            </div>
        </>

    )
};



//  - - - - - - - - - - - FIELD COMPONENTS - - - - - - - - - - -

const DateInputField = ({title, value, setTo, locked, width=300, searchCustomer}) => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if(searchCustomer) searchCustomer();
        }
    };

    return(
        <div className='Project-Booking-Input-Field'>
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            {   !locked &&
                <input className='Project-Booking-Input-Field-Input' type='date' style={{width:`${width}px`}} value={value} onChange={e => setTo(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
            }
            {   locked &&
                <input className='Project-Booking-Input-Field-Input' disabled style={{width:`${width}px`}} value={value} onChange={e => setTo(e.target.value)}/>
            }
        </div>
    )
};

const PercentageInputField = ({title, value, setTo, locked=false}) => {
    return(
        <div className='Project-Booking-Input-Field'>
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            <input className='Project-Booking-Input-Field-Input' disabled={locked} style={{width:'50px'}} value={value} onChange={e => setTo(e.target.value)}/>
            <div>%</div>
        </div>
    )
};

const DollarInputField = ({title, value, setTo, locked, width=100, globalUpdate=null}) => {
    const globalUpdateCall = () => {
        if(globalUpdate) globalUpdate();
    };

    return(
        <div className='Project-Booking-Input-Field'>
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            <div>$</div>
            {   locked &&
            <div className='Project-Booking-Input-Field-Input' style={{width:`${width}px`}}>
                {value}
            </div>
            }
            {   !locked &&
            <input className='Project-Booking-Input-Field-Input' style={{width:`${width}px`}} onBlur={() => globalUpdateCall()} type="number" value={value} onChange={e => setTo(e.target.value)}/>
            }
        </div>
    )
};

const SelectInputField = ({title, value, setTo, locked=false, options}) => {
    return(
        <div className='Project-Booking-Input-Field'>          
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            {   locked &&

            <select onChange={e => setTo(e.target.value)} value={value} disabled>
                <option value="" disabled hidden>Select an option</option>
                {   
                options.map((element, key) => (
                    <option key={key} value={element.value}>{element.text}</option>
                ))
                }
            </select>

            }
            {   !locked &&

            <select onChange={e => setTo(e.target.value)} value={value}>
                <option value="" disabled hidden>Select an option</option>
                {   
                options.map((element, key) => (
                    <option key={key} value={element.value}>{element.text}</option>
                ))
                }
            </select>
            
            }
        </div>
    )
};

//  - - - - - - - - - - - PROJECT REQUIRED - - - - - - - - - - -
const ProjectRequiredForm = ({projectRequired,setProjectRequired}) => {
    setProjectRequired(true);
    
    return(
        <div>
            
        </div>
    )
};

//  - - - - - - - - - - - BRANCH & MANAGER - - - - - - - - - - - 
const BranchManagementForm = ({projectManager,setProjectManager,projectManagerList,branch,setBranch}) => {
    return(
        <>
            <SelectInputField title={'Branch'} value={branch} setTo={setBranch} options={branchOptions}/>
            {   projectManagerList.length < 1 && branch &&
                <LoadingSpinner size={60}/>
            }
            {   projectManagerList.length > 0 && projectManagerList[0].value &&
                <SelectInputField title={'Project Manager'} value={projectManager} setTo={setProjectManager} options={projectManagerList}/>
            }
            {   projectManagerList.length > 0 && !projectManagerList[0].value &&
            <>
                <div>
                    No Project Managers Listed at This Branch.
                </div>
                <div>
                    Please select a different branch or reach out to IT to add PMs to the system for this branch.
                </div>
            </>
            }

        </>
    )
};

const ProjectTypeForm = ({projectType, setProjectType}) => {
    return(
        <SelectInputField title={'Job Type'} value={projectType} setTo={setProjectType} options={requestTypeOptions}/>
    )
};

//  - - - - - - - - - - - PROJECT - - - - - - - - - - - 
const ProjectDetailsForm = ({projectName,setProjectName,projectStreet,setProjectStreet,projectCity,setProjectCity,projectState,setProjectState,projectZip,setProjectZip,
    ownerDirectPurchase,setOwnerDirectPurchase,prevailingWage,setPrevailingWage,bondRequired,setBondRequired,businessLicenseRequired,setBusinessLicenseRequired,permitsRequired,setPermitsRequired,
    ccipInsurance,setCcipInsurance,taxStatus,setTaxStatus}) => {

    return(
        <div className='Project-Booking-Input-Form'>
            <StandardInputField title={'Project Name'} value={projectName} setTo={setProjectName}/>
            <AddressInputField title={'Project Address'} street={projectStreet} setStreet={setProjectStreet} city={projectCity} setCity={setProjectCity} state={projectState} setState={setProjectState} zip={projectZip} setZip={setProjectZip}/>
            <SelectInputField title={'Tax Status'} value={taxStatus} setTo={setTaxStatus} options={taxabilityOptions}/>
            <SelectInputField title={'Owner Direct Purchase'} value={ownerDirectPurchase} setTo={setOwnerDirectPurchase} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Certified Payroll / Prevailing Wage'} value={prevailingWage} setTo={setPrevailingWage} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Bond Required'} value={bondRequired} setTo={setBondRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Business License Required'} value={businessLicenseRequired} setTo={setBusinessLicenseRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Permits Required'} value={permitsRequired} setTo={setPermitsRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'CCIP or OCIP Insurance'} value={ccipInsurance} setTo={setCcipInsurance} options={insuranceProgramOptions}/>
        </div>
    )
};

const ProjectContractForm = ({defaultRetainage,setDefaultRetainage,totalContractAmount,setTotalContractAmount,totalCostAmount,setTotalCostAmount,margin,setMargin}) => {
    return(
        <div className='Project-Booking-Input-Form'>
            <DollarInputField title={'Total Contract Amount'} value={totalContractAmount} setTo={setTotalContractAmount}/>
            <DollarInputField title={'Total Estimated Cost'} value={totalCostAmount} setTo={setTotalCostAmount}/>
            <PercentageInputField title={'margin'} value={margin} setTo={setMargin} locked={true}/>
            <PercentageInputField title={'Default Retainage'} value={defaultRetainage} setTo={setDefaultRetainage}/>
        </div>
    )
};

//  - - - - - - - - - - - SERVICE - - - - - - - - - - - 
const ServiceDetailsForm = ({projectName,setProjectName,
    projectStreet,setProjectStreet,projectCity,setProjectCity,projectState,setProjectState,projectZip,setProjectZip,prevailingWage,setPrevailingWage,
    bondRequired,setBondRequired,businessLicenseRequired,setBusinessLicenseRequired,permitsRequired,setPermitsRequired,taxStatus,setTaxStatus}) => {

    return(
        <div className='Project-Booking-Input-Form'>
            <StandardInputField title={'Project Name'} value={projectName} setTo={setProjectName}/>
            <AddressInputField title={'Project Address'} street={projectStreet} setStreet={setProjectStreet} city={projectCity} setCity={setProjectCity} state={projectState} setState={setProjectState} zip={projectZip} setZip={setProjectZip}/>
            <SelectInputField title={'Tax Status'} value={taxStatus} setTo={setTaxStatus} options={[{text:'Customer Taxed', value:'Customer'},{text:'Contractor Taxed',value:'Contractor'},{text:'Exempt',value:'Exempt'}]}/>
            <SelectInputField title={'Certified Payroll / Prevailing Wage'} value={prevailingWage} setTo={setPrevailingWage} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Bond Required'} value={bondRequired} setTo={setBondRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Business License Required'} value={businessLicenseRequired} setTo={setBusinessLicenseRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Permits Required'} value={permitsRequired} setTo={setPermitsRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
        </div>
    )
};

const ServiceContractForm = ({serviceContractList,setServiceContractList,handleContractChange}) => {
    let activated = false;
    const [updating, setUpdating ] = useState(false);


    const handleChange = (object) => {
        let tempList = serviceContractList;
        tempList[object.contractYear-1] = object;

        handleContractChange(tempList);
    };

    const removeYear = () => {
       let tempList = serviceContractList;
        setUpdating(true);
        setTimeout(() => {
            setUpdating(false);
        },10);

        tempList.pop();
        
        setServiceContractList(tempList);
    };

    const createNewBlankYear = () => {
        let tempList = serviceContractList;
        setUpdating(true);
        setTimeout(() => {
            setUpdating(false);
        },10);

        if(tempList.length < 1){
            const tempYear = {
                contractYear: 1,
                startDate: '',
                endDate: '',
                billingRule: '',
                contractAmount: 0,
                presoldHours: 0,
                materialAllowance: 0,
                travelTime: 0,
            };
    
            tempList.push(tempYear);    
        }
        else{
            let tempEndYear = parseInt(tempList[tempList.length-1].endDate.substring(0, 4));
            tempEndYear += 1;

            const newEndDate = tempEndYear + tempList[tempList.length-1].endDate.slice(4)

            const tempYear = {
                contractYear: tempList.length+1,
                startDate: tempList[tempList.length-1].endDate,
                endDate: newEndDate,
                billingRule: tempList[tempList.length-1].billingRule,
                contractAmount: tempList[tempList.length-1].contractAmount,
                presoldHours: tempList[tempList.length-1].presoldHours,
                materialAllowance: tempList[tempList.length-1].materialAllowance,
                travelTime: tempList[tempList.length-1].travelTime,
            };
    
            tempList.push(tempYear);  
        }

        setServiceContractList(tempList);
    };

    useEffect(() => {
        if(!activated && serviceContractList.length < 1){
            activated = true;
            createNewBlankYear();
        };
    },[]);

    const ServiceContractComponent = ({contractYear,startDate,endDate,billingRule,contractAmount,presoldHours,materialAllowance,travelTime,handleChange}) => {
        const [start, setStart] = useState(`${startDate ? organizeInputDate(startDate) : ''}`);
        const [end, setEnd] = useState(`${endDate ? organizeInputDate(endDate) : ''}`);
        const [billing, setBilling] = useState(billingRule);
        const [value, setValue] = useState(contractAmount);
        const [hours, setHours] = useState(presoldHours);
        const [materials,setMaterials] = useState(materialAllowance);
        const [travel,setTravel] = useState(travelTime);

        useEffect(() => {        
            if(start && !end){
                let tempEndYear = parseInt(start.substring(0, 4));
                tempEndYear += 1;
                setEnd(tempEndYear + start.slice(4));
            };

            const tempObject = {
                contractYear:contractYear,
                startDate:start,
                endDate:end,
                billingRule:billing,
                contractAmount:value,
                presoldHours:hours,
                materialAllowance:materials,
                travelTime:travel,
            };

            handleChange(tempObject);
        },[start,end,billing,value,hours,materials,travel]);
        
        return(
            <div className='Project-Booking-Service-Contract-Component'>
                <StandardInputField title={"Year"} value={contractYear} locked={true} width={50}/>
                <DateInputField title={"Contract Start Date"} value={start} setTo={setStart} width={150}/>
                <DateInputField title={"Contract End Date"} value={end} setTo={setEnd} width={150}/>
                <DollarInputField title={"Contract Value"} value={value} setTo={setValue} width={150}/>
                <SelectInputField title={"Billing Rule"} value={billing} setTo={setBilling} options={billingOptions}/>
                <StandardInputField title={"Presold Hours"} value={hours} setTo={setHours}  width={150}/>
                <DollarInputField title={"Presold Material Allowance"} value={materials} setTo={setMaterials} width={150}/>
                <StandardInputField title={"Billable Travel Time"} value={travel} setTo={setTravel}  width={150}/>
            </div>
        )
    };

    return(
        <div className='Project-Booking-Service-Contract-Form'>
            { 
                serviceContractList.map((element,key) => (
                    <ServiceContractComponent {...element} handleChange={handleChange} removeYear={removeYear} key={key}/>
                ))
            }
            <button className='Project-Booking-Service-Contract-Add' onClick={() => createNewBlankYear()}>Add A Year</button>
            {   serviceContractList.length > 1 &&
            <button className='Project-Booking-Service-Contract-Remove' onClick={() => removeYear()}>Remove Year</button>
            }
        </div>
    )
};

const FilesForm = ({contractFile,setContractFile,prevailingWage,prevailingWageFile,setPrevailingWageFile,bondRequired,bondFile,setBondFile,
    taxStatus,taxExemptFile,setTaxExemptFile,ccipInsurance,certificateFile,setCertificateFile,
    contractFileId,prevailingWageFileId,bondFileId,taxExemptFileId,certificateFileId}) => {

    return(
        <div>
            <div className='Project-Booking-File-Insert'>
                <div className='Project-Booking-File-Insert-Description'>
                    Upload the Final Signed Contract.
                </div>
                <input type='file' accept=".pdf" onChange={(e) => setContractFile(e.target.files)}className='Project-Booking-File-Insert-File'/>
            </div>
            {   contractFileId && !contractFile && // Contract - Updating Text
                <div className='Project-Booking-File-Update'>
                    A contract file has already been uploaded. Choosing a new file will overwrite the stored version.
                </div>
            }

            {   prevailingWage === 'true' &&    // Certified / Prevailing Wage
            <div className='Project-Booking-File-Insert'>
                <div className='Project-Booking-File-Insert-Description'>
                    Upload only the pages of the Certified Payroll / Prevailing Wage Rate Sheet that apply with the rates clearly marked.
                </div>
                <input type='file' accept=".pdf" onChange={(e) => setPrevailingWageFile(e.target.files)}className='Project-Booking-File-Insert-File'/>
            </div>
            }
            {   prevailingWage === 'true' && prevailingWageFileId && !prevailingWageFile && // Updating Text
                <div className='Project-Booking-File-Update'>
                    A Certified Payroll / Prevailing Wage file has already been uploaded. Choosing a new file will overwrite the stored version.
                </div>
            }
            {   bondRequired === 'true' &&  // Bond Required
            <div className='Project-Booking-File-Insert'>
                <div className='Project-Booking-File-Insert-Description'>
                    Upload the Bonding Requirements.
                </div>
                <input type='file' accept=".pdf" onChange={(e) => setBondFile(e.target.files)}className='Project-Booking-File-Insert-File'/>
            </div>
            }
            {   bondRequired === 'true' && bondFileId && !bondFile && // Updating Text
                <div className='Project-Booking-File-Update'>
                    A Bonding file has already been uploaded. Choosing a new file will overwrite the stored version.
                </div>
            }
            {   taxStatus === 'Exempt' &&   // Tax Exemption
            <div className='Project-Booking-File-Insert'>
                <div className='Project-Booking-File-Insert-Description'>
                    Upload the Tax Exemption Certificate.
                </div>
                <input type='file' accept=".pdf" onChange={(e) => setTaxExemptFile(e.target.files)}className='Project-Booking-File-Insert-File'/>
            </div>
            }
            {   taxStatus === 'Exempt' && taxExemptFileId && !taxExemptFile && // Updating Text
                <div className='Project-Booking-File-Update'>
                    A Tax Exemption file has already been uploaded. Choosing a new file will overwrite the stored version.
                </div>
            }
            {   ccipInsurance !== 'None' && // Insurance Program
            <div className='Project-Booking-File-Insert'>
                <div className='Project-Booking-File-Insert-Description'>
                    Upload CCIP or OCIP Insurance Details.
                </div>
                <input type='file' accept=".pdf" onChange={(e) => setCertificateFile(e.target.files)}className='Project-Booking-File-Insert-File'/>
            </div>
            }
            {   ccipInsurance !== 'None' && certificateFileId && !certificateFile && // Updating Text
                <div className='Project-Booking-File-Update'>
                    An Insurance Program file has already been uploaded. Choosing a new file will overwrite the stored version.
                </div>
            }
        </div>
    )
};

const BudgetSkipForm = ({budgetSkipReason,setBudgetSkipReason}) => {
    return(
        <div className='Project-Booking-Skip-Budget'>
            <textarea className='Project-Booking-Skip-Budget-Input' value={budgetSkipReason} onChange={e => setBudgetSkipReason(e.target.value)} />
        </div>
    )
};

const ElementSubmitComponent = ({title,loading,seperator=false,error}) => {
    return(
        <div className='Project-Booking-Submission-Section'>
            <div className='Project-Booking-Submission-File-Group'>
                <div className='Project-Booking-Submission-Status'>
                    {   loading && !error &&
                        <LoadingSpinner size={60}/>
                    }     
                    {   !loading && !error &&
                        <HiBadgeCheck className='Project-Booking-Accepted' />
                    }
                    {   error &&
                        <MdError className='Project-Booking-Error' />
                    }
                </div>
                <div className='Project-Booking-Submission-Title'>
                    {title}
                </div>
            </div>
            {   error &&
                <div className='Project-Booking-Error-Line'>{error}</div>
            }
            {   seperator &&
                <FaDotCircle className='Project-Booking-Submission-Seperator'/>
            }
        </div>
    )
};

const RejectionWindow = ({rejectionList,getForm,rejectionSelected,cancelRejection}) => {
    const [expanded, setExpanded] = useState(false);

    const selectRejection = (id) => {
        setExpanded(false);
        getForm(id);
    };

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const RejectionComponent = ({rejection}) => {
        return(
            <div className='Project-Booking-Rejection-Component' onClick={() => selectRejection(rejection.recordId)}>

                <div className='Project-Booking-Rejection-Line'>
                    <div className='Project-Booking-Rejection-Title'>
                        Project Name:  
                    </div>
                    <div className='Project-Booking-Rejection-Text'>
                        {rejection.projectName}
                    </div>
                </div>

                <div className='Project-Booking-Rejection-Line'>
                    <div className='Project-Booking-Rejection-Title'>
                        Date Rejected:  
                    </div>
                    <div className='Project-Booking-Rejection-Text'>
                        {organizeDate(rejection.date)}
                    </div>
                </div>


            </div>
        )
    };

    return(
    <div className='Project-Booking-Rejection-Window'>
        {   !rejectionSelected &&
            <>
            <div className='Project-Booking-Rejection-Header'>
                { !expanded &&
                <div className='Project-Booking-Rejection-Header-Text' onClick={() => toggleExpanded()}>
                    View Rejections
                </div>
                }
                { expanded &&
                <div className='Project-Booking-Rejection-Header-Text' onClick={() => toggleExpanded()}>
                    Hide Rejections
                </div>
                }

                <IoIosAlert className='Project-Booking-Rejection-Header-Icon'onClick={() => toggleExpanded()}/>
            </div>

            {   expanded &&
                <div className='Project-Booking-Rejection-List'>
                    {
                        rejectionList.map((rejection, key) => (
                            <RejectionComponent rejection={rejection}/>
                        ))
                    }
                </div>
            }
            </>
        }
        {   rejectionSelected &&
            <>
                <div className='Project-Booking-Rejection-Header'>
                    <div className='Project-Booking-Rejection-Header-Text' onClick={() => cancelRejection()}>
                        Close Rejected Request
                    </div>
                </div>
                <div  className='Project-Booking-Rejection-Notes-Header'>
                    Reasons for Rejection:
                </div>
                <div className='Project-Booking-Rejection-Notes'>
                        {rejectionSelected.rejectionText}
                </div>
            </>
        }
    </div>
    )
};

const ServiceBudgetComponent = ({generateServiceContractTemplate, serviceCostBudgetTemplate, setServiceCostFile, budgetError}) => {
    return (
        <>
                <div className='Project-Booking-Service-Budget-Instruction'>
                    A budget will be created using the contract details entered above.
                </div>
                <div className='Project-Booking-Service-Budget-Instruction'>
                    If these change, redownload the template to get an updated version.
                </div>
                <div className='Project-Booking-Service-Budget-Instruction'>
                    Values left with a total of $0 will be ignored when the import is completed.
                </div>
                <div className='Project-Booking-Service-Budget-Template'>
                    <button onClick={() => generateServiceContractTemplate()}>Download Cost Budget Template</button>
                </div>
            
            {   serviceCostBudgetTemplate &&
            <>
                <div className='Project-Booking-File-Insert-Description'>
                    Upload Completed Service Cost Budget
                </div>
                <input type='file' accept=".xls,.xlsx" onChange={(e) => setServiceCostFile(e.target.files)}className='Project-Booking-Budget-Import-Upload'/>
            </>
            }

            {   budgetError &&
                <div className='Project-Booking-Error-Line'>{budgetError}</div>
            }


        </>
    );
};

//  - - - - - - - - - - - PRIMARY COMPONENT - - - - - - - - - - - 
const ProjectBooking = () => {
    const contextObject = useContext(Context);

    const [projectRequired, setProjectRequired] = useState(false);

    // CUSTOMER - - - - - - - 
    const [customerSelected, setCustomerSelected] = useState(null);
    const [locationSelected, setLocationSelected] = useState(null);
    const [customerPoNumber, setCustomerPoNumber] = useState('');
    const [entitySelection, setEntitySelection] = useState(true);
    const [enitityShown, setEntityShown] = useState(false);
    const [entityName, setEntityName] = useState('');

    useEffect(() => {
        if(entitySelection === 'true' || entitySelection === true){
            setEntityShown(false);
        }
        else{
            setEntityShown(true);
        }
        if(entitySelection) setEntityName('');
    },[entitySelection]);


    // BRANCH & MANAGER - - - - - - - 
    const [branch,setBranch] = useState('');
    const [projectManager,setProjectManager] = useState('');
    const [projectManagerList,setProjectManagerList] = useState([]);
    const [projectManagerError,setProjectManagerError] = useState('');
    const [branchAndManagerComplete, setBranchAndManagerComplete] = useState(false);

    useEffect(() => {
        if(branch && projectManager) setBranchAndManagerComplete(true);
        else setBranchAndManagerComplete(false);
    },[branch,projectManager]);

    useEffect(() => {
        if(branch) getFilteredProjectManagers(contextObject.apiToken,branch,setProjectManagerList,setProjectManagerError);
    },[branch]);

    const branchManagementProps = {
        projectManager:projectManager,
        setProjectManager:setProjectManager,
        projectManagerList:projectManagerList,
        branch:branch,
        setBranch:setBranch,
    };

    // PROJECT SELECTION - - - - - - - 
    const [projectType, setProjectType] = useState('Project');

    // PROJECT DETAILS  - - - - - - - 
    const [projectName, setProjectName] = useState('');
    const [projectStreet,setProjectStreet] = useState('');
    const [projectStreet2,setProjectStreet2] = useState('');
    const [projectCity,setProjectCity] = useState('');
    const [projectState,setProjectState] = useState('');
    const [projectZip,setProjectZip] = useState('');
    const [ownerDirectPurchase, setOwnerDirectPurchase] = useState(false);
    const [prevailingWage, setPrevailingWage] = useState(false);
    const [bondRequired, setBondRequired] = useState(false);
    const [businessLicenseRequired, setBusinessLicenseRequired] = useState(false);
    const [permitsRequired, setPermitsRequired] = useState(false);
    const [costAccountGroup, setCostAccountGroup] = useState('');
    const [ccipInsurance, setCcipInsurance] = useState('None');
    const [billingType, setBillingType] = useState('Progressive');
    const [taxStatus, setTaxStatus] = useState('Contractor');
    const [projectNameError, setProjectNameError] = useState('');
    const [projectAddressError, setProjectAddressError] = useState('');
    const [detailsComplete, setDetailsComplete] = useState(false);

    const projectDetailProps = {
        projectName: projectName,
        setProjectName:setProjectName,
        projectStreet:projectStreet,
        setProjectStreet:setProjectStreet,
        projectCity:projectCity,
        setProjectCity:setProjectCity,
        projectState:projectState,
        setProjectState:setProjectState,
        projectZip:projectZip,
        setProjectZip:setProjectZip,
        ownerDirectPurchase:ownerDirectPurchase,
        setOwnerDirectPurchase:setOwnerDirectPurchase,
        prevailingWage:prevailingWage,
        setPrevailingWage:setPrevailingWage,
        bondRequired:bondRequired,
        setBondRequired:setBondRequired,
        businessLicenseRequired:businessLicenseRequired,
        setBusinessLicenseRequired:setBusinessLicenseRequired,
        permitsRequired:permitsRequired,
        setPermitsRequired:setPermitsRequired,
        costAccountGroup:costAccountGroup,
        setCostAccountGroup:setCostAccountGroup,
        ccipInsurance:ccipInsurance,
        setCcipInsurance:setCcipInsurance,
        taxStatus:taxStatus,
        setTaxStatus:setTaxStatus,
    };

    // Checks an address for completeness
    const checkProjectAddress = (street,city,state,zip) => {
        let complete = true;

        if(street.length < 5) complete = false;
        if(city.length < 3) complete = false;
        if(state.length < 2) complete = false;
        if(zip.length < 5) complete = false;

        return complete;
    };

    const checkProjectDetails = () => {
        let complete = true;

        if(projectName.length < 5) {
            complete = false;
            setProjectNameError('Please enter a project name.');
        }
        else setProjectNameError('');

        if(!checkProjectAddress(projectStreet,projectCity,projectState,projectZip)){
            complete = false;
            setProjectAddressError('Please enter a valid project address.');
        }
        else setProjectAddressError('');

        return complete;
    };

    useEffect(() => {
        setDetailsComplete(checkProjectDetails());
    },[projectName,projectStreet,projectCity,projectState,projectZip]);


    // SERVICE DETAILS - - - - - - - 
    const serviceDetailProps = {
        projectName: projectName,
        setProjectName:setProjectName,
        projectStreet:projectStreet,
        setProjectStreet:setProjectStreet,
        projectCity:projectCity,
        setProjectCity:setProjectCity,
        projectState:projectState,
        setProjectState:setProjectState,
        projectZip:projectZip,
        setProjectZip:setProjectZip,
        ownerDirectPurchase:ownerDirectPurchase,
        setOwnerDirectPurchase:setOwnerDirectPurchase,
        prevailingWage:prevailingWage,
        setPrevailingWage:setPrevailingWage,
        bondRequired:bondRequired,
        setBondRequired:setBondRequired,
        businessLicenseRequired:businessLicenseRequired,
        setBusinessLicenseRequired:setBusinessLicenseRequired,
        permitsRequired:permitsRequired,
        setPermitsRequired:setPermitsRequired,
        costAccountGroup:costAccountGroup,
        setCostAccountGroup:setCostAccountGroup,
        ccipInsurance:ccipInsurance,
        setCcipInsurance:setCcipInsurance,
        taxStatus:taxStatus,
        setTaxStatus:setTaxStatus,
        customerPoNumber:customerPoNumber,
        setCustomerPoNumber:setCustomerPoNumber,
        billingType:billingType,
        setBillingType:setBillingType,
    };

    // CONTRACT - - - - - - - 
    const [totalContractAmount, setTotalContractAmount] = useState(0);
    const [totalCostAmount, setTotalCostAmount] = useState(0);
    const [defaultRetainage, setDefaultRetainage] = useState('');
    const [margin, setMargin] = useState(0);
    const [serviceContractList,setServiceContractList] = useState([]);
    const [contractComplete, setContractComplete] = useState(false);
    const [contractValueError, setContractValueError] = useState('');
    const [retainageError, setRetainageError] = useState('');
    const [estiamtedCostError, setEstimatedCostError] = useState('');

    const checkServiceContract = () => {
        let complete = true;

        serviceContractList.forEach(element => {
            if(!element.startDate) complete = false;
            if(!element.endDate) complete = false; 
            if(!element.billingRule) complete = false; 
            if(element.contractValue < 1) complete = false;  
        });

        setContractComplete(complete);
    };

    const handleContractChange = (list) => {
        setServiceContractList(list)
        checkServiceContract();
    };

    const contractProps = {
        defaultRetainage:defaultRetainage,
        setDefaultRetainage:setDefaultRetainage,
        totalContractAmount:totalContractAmount,
        setTotalContractAmount:setTotalContractAmount,
        totalCostAmount:totalCostAmount,
        setTotalCostAmount:setTotalCostAmount,
        margin:margin,
        setMargin:setMargin,
        serviceContractList:serviceContractList,
        setServiceContractList:setServiceContractList,
        handleContractChange:handleContractChange,
    };

    useEffect(()  => {
        checkServiceContract();
    },[serviceContractList]);

    useEffect(() => {
        if(totalContractAmount > 0 && totalCostAmount > 0){
            let result = (((totalContractAmount/totalCostAmount)-1)*100);
            setMargin(result.toFixed(4));
        }
        else setMargin(0);

        let complete = true;

        if(totalContractAmount < 1){
            complete = false;
            setContractValueError('Enter a total contract amount.');
        }
        else setContractValueError('');

        if(totalCostAmount < 1){
            complete = false;
            setEstimatedCostError('Enter an estimated cost.');
        }
        else setEstimatedCostError('');

        if(defaultRetainage === ''){
            complete = false;
            setRetainageError('Enter a default retainage.');
        }
        else setRetainageError('');

        setContractComplete(complete);

    },[totalContractAmount,totalCostAmount,defaultRetainage]);


    // Links - - - - - - - - - - - 
    const [salesLink, setSalesLink] = useState('');
    const [salesLinkError, setSalesLinkError] = useState('Please copy and paste the link to the sales folder');
    const [validSalesLink, setValidSalesLink] = useState(false);
    
    const validateSalesLink = () => {
        const validStart = 'https://cornerstonedetentionproducts';

        if(salesLink.startsWith(validStart)) return true;
        else return false
    };

    useEffect(() => {
        if(!salesLink){
            setSalesLinkError('Please copy and paste the link to the sales folder');
            setValidSalesLink(false);
        }
        else{
            if(!validateSalesLink()){
                setSalesLinkError('Please copy a valid Box Link to Continue. To log in, navigate to www.Box.com');
                setValidSalesLink(false);
            }
            else{
                setSalesLinkError('');
                setValidSalesLink(true);
            }
        };
    },[salesLink]);


    // FILES - - - - - - - 
    const [filesRequired, setFilesRequired] = useState(false);
    const [filesComplete, setFilesComplete] = useState(false);
    const [contractFile, setContractFile] = useState();
    const [contractFileError, setContractFileError] = useState('');
    const [prevailingWageFile, setPrevailingWageFile] = useState();
    const [bondFile, setBondFile] = useState();
    const [taxExemptFile, setTaxExemptFile] = useState();
    const [certificateFile, setCertificateFile] = useState();
    const [prevailingWageFileError, setPrevailingWageFileError] = useState('');
    const [bondFileError, setBondFileError] = useState('');
    const [certificateFileError, setCertificateFileError] = useState('');
    const [contractFileId, setContractFileId] = useState('');
    const [contractError, setContractError] = useState('');
    const [prevailingWageFileId, setPrevailingWageFileId] = useState('');
    const [prevailingWageError, setPrevailingWageError] = useState('');
    const [bondFileId, setBondFileId] = useState('');
    const [bondError, setBondError] = useState('');
    const [taxExemptFileId, setTaxExemptFileId] = useState('');
    const [taxExemptError, setTaxExemptError] = useState('');
    const [certificateFileId, setCertificateFileId] = useState('');
    const [certificateError, setCertificateError] = useState('');

    useEffect(() => {
        if(contractFile){
            setContractFileId('');
            console.log('Resetting Contract File')
        }
        if(prevailingWageFile) setPrevailingWageFileId('');
        if(bondFile) setBondFileId('');
        if(taxExemptFile) setTaxExemptFileId('');
        if(certificateFile) setCertificateFileId('');

    },[contractFile,prevailingWageFile,bondFile,taxExemptFile,certificateFile]);

    const fileProps = {
        contractFile:contractFile,
        setContractFile:setContractFile,
        prevailingWage: prevailingWage,
        prevailingWageFile:prevailingWageFile,
        setPrevailingWageFile:setPrevailingWageFile,
        bondRequired:bondRequired,
        bondFile:bondFile,
        setBondFile:setBondFile,
        taxStatus:taxStatus,
        taxExemptFile:taxExemptFile,
        setTaxExemptFile:setTaxExemptFile,
        ccipInsurance:ccipInsurance,
        certificateFile:certificateFile,
        setCertificateFile:setCertificateFile,

        contractFileId:contractFileId,
        prevailingWageFileId:prevailingWageFileId,
        bondFileId:bondFileId,
        taxExemptFileId:taxExemptFileId,
        certificateFileId:certificateFileId,
    };
    
    const checkFilesRequired = () => {
        let complete = true;
        let filesShown = true;

        if(!contractFile && !contractFileId){
            setContractFileError('Upload a Final Signed Contract.');
            complete = false;
        }
        else setContractFileError('');

        // PREVAILING WAGE
        if(prevailingWage === 'true'){
            filesShown = true;

            if(!prevailingWageFile && !prevailingWageFileId){
                setPrevailingWageFileError('Upload a Prevailing Wage Rate Sheet.');
                complete = false;
            }
            else setPrevailingWageFileError('');
        } else setPrevailingWageFileError('');

        // BOND
        if(bondRequired === 'true'){
            filesShown = true;
            if(!bondFile && !bondFileId){
                setBondFileError('Upload Bonding Requirements.');
                complete = false;
            }
            else setBondFileError('');
        }else setBondFileError('');

        if(taxStatus === 'Exempt'){
            filesShown = true;
        }

        if(ccipInsurance === 'true'){
            filesShown = true;
            if(!certificateFile && !certificateFileId){
                setCertificateFileError('Upload CCIP or OCIP Insurance Details.');
                complete = false;
            }
            else setCertificateFileError('');
        }else setCertificateFileError('');

        setFilesRequired(filesShown);

        return complete;
    };

    useEffect(() => {
        setFilesComplete(checkFilesRequired());
    },[prevailingWage,bondRequired,taxStatus,prevailingWageFile,bondFile,certificateFile,taxExemptFile,certificateFileError,ccipInsurance,contractFile]);

    
    // BUDGET - - - - - - - 
    const [budgetAvailable, setBudgetAvailable] = useState('');
    const [budgetImport, setBudgetImport] = useState();
    const [budgetError, setBudgetError] = useState('');
    const [budgetComplete, setBudgetComplete] = useState(false);
    const [budgetSkipReason, setBudgetSkipReason] = useState('');
    const [proestBudget, setProestBudget] = useState('');
    const [budgetResponse, setBudgetResponse] = useState('');
    const [budgetFiles, setBudgetFiles] = useState();
    const [taskFile, setTaskFile] = useState();
    const [revenueFile, setRevenueFile] = useState();
    const [costFile, setCostFile] = useState();
    const [taskFileId, setTaskFileId] = useState();
    const [revenueFileId, setRevenueFileId] = useState();
    const [costFileId, setCostFileId] = useState();
    const [taskFileError, setTaskFileError] = useState();
    const [revenueFileError, setRevenueFileError] = useState();
    const [costFileError, setCostFileError] = useState();

    const [budgetResponseErrors, setBudgetResponseErrors] = useState();

    const [serviceCostBudgetTemplate, setServiceCostBudgetTemplate] = useState('');
    const [serviceCostFile, setServiceCostFile] = useState();
    const [serviceFilesComplete, setServiceFilesComplete] = useState(false);
    const [serviceCostFileError, setServiceCostFileError] = useState();
    const [serviceTaskFilename, setServiceTaskFilename] = useState();
    const [serviceRevenueFilename, setServiceRevenueFilename] = useState();
    const [serviceCostFilename, setServiceCostFilename] = useState();

    useEffect(() => {
        if(budgetResponse){
            if(budgetResponse.totalCost != totalCostAmount) setBudgetResponseErrors('Total Estimated Cost provided in contract details does not match the total of the list provided.');
            else setBudgetResponseErrors(null);
        }
        else setBudgetResponseErrors(null);

    },[budgetResponse, totalCostAmount]);

    const checkBudget = () => {
        let complete = true;

        // BUDGET
        if(!budgetAvailable && !budgetFiles){
            complete = false;
            if(projectType === 'Service') setBudgetError('Please download, format, and upload a service budget.');
            else setBudgetError('Please select a budget type.');
        };

        if(budgetAvailable === 'Standard'){
            if(!budgetResponse && !budgetFiles) {
                setBudgetError('Upload a standard budget.');
                complete = false;
            }
            else{
                if(budgetResponse.errorList > 0) complete = false;
                else setBudgetError('');
            }
        };

        if(budgetAvailable === 'ProEst'){
            if(!proestBudget) {
                setBudgetError('Provide enter a valid ProEst estimate number.');
                complete = false;
            }
            else setBudgetError('');
        };

        if(budgetAvailable === 'None'){
            if(budgetSkipReason.length < 5){
                setBudgetError('Provide a valid reason for skipping the budget.');
                complete = false;
            }
            else setBudgetError('');
        };

        if(budgetAvailable === 'Service'){
            if(!serviceCostFile) {
                setBudgetError('Upload a service budget.');
                complete = false;
            }
            else setBudgetError('');
        };

        return complete;
    };

    useEffect(() => {
        setBudgetComplete(checkBudget());
    },[budgetSkipReason,budgetAvailable,proestBudget,budgetResponse,serviceCostFile]);

    // Formats budget when uploaded
    const importProjectBudget = () => {
        setBudgetFiles();
        const extension = budgetImport[0].type
        const filename = `${projectName}-Original Budget-${getDate()}.xlsx`;
        sendProjectBudget(contextObject.apiToken, budgetImport, filename, setBudgetResponse, setBudgetError);
    };

    // Do this when a budget import is selected
    useEffect(() => {
        if(!budgetResponse && budgetImport) importProjectBudget();
    },[budgetImport]);

    const openProjectBookingTask = async (token, fileName) => {
        newProjectBookingDataService.getBudgetFile(token,fileName);
    };

    const openProjectBookingRevenue = async (token, fileName) => {
        newProjectBookingDataService.getBudgetFile(token,fileName);
    };

    const openProjectBookingCost = async (token, fileName) => {
        newProjectBookingDataService.getBudgetFile(token,fileName);
    };

    // SUBMITTING - - - - - - - 
    const [submitting, setSubmitting] = useState(false);
    const [completeSubmission, setCompleteSubmission] = useState('');
    const [completionError, setCompletionError] = useState('');
    const [notes, setNotes] = useState('');
    let submittingCheck = false;

    const createProjectBookingObject = () => {
        let tempStreet2 = '';
        if(projectStreet2) tempStreet2 = projectStreet2.replace(/[^a-zA-Z0-9\s]/g, '')

        const projectAddress = {
            street: projectStreet.replace(/[^a-zA-Z0-9\s]/g, ''),
            street2: tempStreet2,
            city: projectCity.replace(/[^a-zA-Z0-9\s]/g, ''),
            state: projectState.replace(/[^a-zA-Z0-9\s]/g, ''),
            zip: projectZip.replace(/[^a-zA-Z0-9\s]/g, '')
        };

        let tempEntityName = '';
        if(entityName) tempEntityName = entityName.replace(/[^a-zA-Z0-9\s]/g, '');

        let tempPoNumber = '';
        if(customerPoNumber) tempPoNumber = customerPoNumber.replace(/[^a-zA-Z0-9\s]/g, '');

        let tempBudgetSkipReason = '';
        if(budgetSkipReason) tempBudgetSkipReason = budgetSkipReason.replace(/[^a-zA-Z0-9\s]/g, '');

        const budget = {
            type: budgetAvailable,
            text: budgetSkipReason,
            estimateNumber: proestBudget
        };

        const projectBookingObject = {
            customer:customerSelected,
            location:locationSelected,
            entityName: tempEntityName,
            customerPoNumber:tempPoNumber,

            branch:branch,
            projectManager:projectManager,

            projectType:projectType,

            projectName:projectName.replace(/[^a-zA-Z0-9\s]/g, ''),
            projectAddress: projectAddress,

            taxStatus,taxStatus,

            ownerDirectPurchase:`${ownerDirectPurchase === 'true' ? true : false}`,
            prevailingWage:`${prevailingWage === 'true' ? true : false}`,
            bondRequired:`${bondRequired === 'true' ? true : false}`,
            businessLicenseRequired:`${businessLicenseRequired === 'true' ? true : false}`,
            permitsRequired:`${permitsRequired === 'true' ? true : false}`,
            permitsRequired:`${permitsRequired === 'true' ? true : false}`,
            ccipInsurance: ccipInsurance,

            salesLink:salesLink,

            totalContractAmount:totalContractAmount,
            totalCostAmount:totalCostAmount,
            margin:margin,
            defaultRetainage:defaultRetainage,

            contractFileId:contractFileId,
            prevailingWageFileId:prevailingWageFileId,
            bondFileId:bondFileId,
            taxExemptFileId:taxExemptFileId,
            certificateFileId:certificateFileId,
            
            budget:budget,
            budgetResponse:budgetResponse,
            budgetSkipReason:tempBudgetSkipReason,

            submittingUser: contextObject.activeUser.id,

            notes:notes
        };

        if(projectType === 'Service'){
            projectBookingObject.totalContractAmount = 0;
            projectBookingObject.totalCostAmount = 0;
            projectBookingObject.margin = 0;
            projectBookingObject.defaultRetainage = 0;
            projectBookingObject.serviceContractList = serviceContractList;
            projectBookingObject.budget.type = "Standard";
            projectBookingObject.budget.taskFileName = serviceTaskFilename;
            projectBookingObject.budget.revenueFileName = serviceRevenueFilename;
            projectBookingObject.budget.costFileName = serviceCostFilename;
        };

        return projectBookingObject
    };

    const createUpdateProjectBookingObject = () => {
        const updatedRecord = rejectionRecord;

        updatedRecord.resubmit = true;
        updatedRecord.rejectionId = rejectionSelected.rejectionId;
        // Customer
        updatedRecord.customer.class = customerSelected.class;
        updatedRecord.customer.customerId = customerSelected.customerId;
        updatedRecord.customer.name = customerSelected.name;
        // Location
        updatedRecord.location = updatedRecord.customer.location;
        updatedRecord.location.locationId = locationSelected.locationId;
        updatedRecord.location.locationName = locationSelected.locationName;
        updatedRecord.location.status = locationSelected.status;
        // Location Address
        updatedRecord.location.address.street = locationSelected.address.street;
        updatedRecord.location.address.street2 = locationSelected.address.street2;
        updatedRecord.location.address.city = locationSelected.address.city;
        updatedRecord.location.address.state = locationSelected.address.state;
        updatedRecord.location.address.zip = locationSelected.address.zip;
        updatedRecord.location.address.country = locationSelected.address.country;
        // Name / Type
        updatedRecord.projectName = projectName.replace(/[^a-zA-Z0-9\s]/g, '');
        updatedRecord.projectType = projectType;
        // Branch / PM
        updatedRecord.branch = branch;
        updatedRecord.projectManager = projectManager;
        // Entity / Po Number
        if(entityName) updatedRecord.entityName = entityName.replace(/[^a-zA-Z0-9\s]/g, '');
        else updatedRecord.entityName = null
        if(customerPoNumber) updatedRecord.customerPoNumber = customerPoNumber.replace(/[^a-zA-Z0-9\s]/g, '');
        else updatedRecord.customerPoNumber = null;
        // Project Address
        updatedRecord.address.street = projectStreet.replace(/[^a-zA-Z0-9\s]/g, '');
        if(projectStreet2) updatedRecord.address.street2 = projectStreet2.replace(/[^a-zA-Z0-9\s]/g, '');
        else updatedRecord.address.street2 = null;
        updatedRecord.address.city = projectCity.replace(/[^a-zA-Z0-9\s]/g, '');
        updatedRecord.address.state = projectState.replace(/[^a-zA-Z0-9\s]/g, '');
        updatedRecord.address.zip = projectZip.replace(/[^a-zA-Z0-9\s]/g, '');
        // Required
        updatedRecord.bondRequired = bondRequired === 'true' ? true : false;
        updatedRecord.businessLicenseRequired = businessLicenseRequired === 'true' ? true : false;
        updatedRecord.ownerDirectPurchase = ownerDirectPurchase === 'true' ? true : false;
        updatedRecord.prevailingWage = prevailingWage === 'true' ? true : false;
        updatedRecord.permitsRequired = permitsRequired === 'true' ? true : false;
        updatedRecord.ccipInsurance = ccipInsurance;
        // Contract
        updatedRecord.totalContractAmount = totalContractAmount;
        updatedRecord.totalCostAmount = totalCostAmount;
        updatedRecord.margin = margin;
        updatedRecord.defaultRetainage = defaultRetainage;
        updatedRecord.taxStatus = taxStatus;
        updatedRecord.serviceContractList = serviceContractList;
        // Notes
        if(notes) updatedRecord.notes = notes.replace(/[^a-zA-Z0-9\s]/g, '');
        else updatedRecord.notes = null;
        // Links
        updatedRecord.salesLink = salesLink;
        // Files
        updatedRecord.contract = contractFileId;
        updatedRecord.rateSheet = prevailingWageFileId;
        updatedRecord.ocip = certificateFileId;
        updatedRecord.bonding = bondFileId;
        updatedRecord.taxExemption = taxExemptFileId;
        // Budget
        const budget = {
            id: updatedRecord.budget.id,
            type: budgetAvailable,
            text: budgetSkipReason,
            estimateNumber: proestBudget,
            taskFileName: budgetFiles ? budgetFiles.task : null,
            revenueFileName: budgetFiles ? budgetFiles.revenue : null,
            costFileName: budgetFiles ? budgetFiles.cost : null,
        };

        updatedRecord.budgetResponse = budgetResponse;
        updatedRecord.budget = budget;

        if(projectType === 'Service'){
            updatedRecord.totalContractAmount = 0;
            updatedRecord.totalCostAmount = 0;
            updatedRecord.margin = 0;
            updatedRecord.defaultRetainage = 0;
            updatedRecord.serviceContractList = serviceContractList;
        };

        return updatedRecord;
    };

    const submitFiles = () => {
        setSubmitting(true);

        if(contractFile) sendContractFile(contextObject.apiToken,contractFile[0],`${projectName.replace(/[^a-zA-Z0-9\s]/g, '')}-Contract-${getDate()}.${contractFile[0].type.replace(/(.*)\//g, '')}`,setContractFileId,setContractError);
        if(prevailingWageFile) sendPrevailingWageFile(contextObject.apiToken,prevailingWageFile[0],`${projectName.replace(/[^a-zA-Z0-9\s]/g, '')}-Rate Sheet-${getDate()}.${prevailingWageFile[0].type.replace(/(.*)\//g, '')}`,setPrevailingWageFileId,setPrevailingWageError);
        if(bondFile) sendBondFile(contextObject.apiToken,bondFile[0],`${projectName.replace(/[^a-zA-Z0-9\s]/g, '')}-Bonding Requirements-${getDate()}.${bondFile[0].type.replace(/(.*)\//g, '')}`,setBondFileId,setBondError);
        if(taxExemptFile) sendTaxExemptFile(contextObject.apiToken,taxExemptFile[0],`${projectName.replace(/[^a-zA-Z0-9\s]/g, '')}-Tax Exemption-${getDate()}.${taxExemptFile[0].type.replace(/(.*)\//g, '')}`,setTaxExemptFileId,setTaxExemptError);
        if(certificateFile) sendCertificateFile(contextObject.apiToken,certificateFile[0],`${projectName.replace(/[^a-zA-Z0-9\s]/g, '')}-OCIP CCIP-${getDate()}.${certificateFile[0].type.replace(/(.*)\//g, '')}`,setCertificateFileId,setCertificateError);

        if(taskFile) sendTaskFile(contextObject.apiToken,taskFile[0],`${projectName.replace(/[^a-zA-Z0-9\s]/g, '')}`,setTaskFileId,setTaskFileError);
        if(revenueFile) sendRevenueFile(contextObject.apiToken,revenueFile[0],`${projectName.replace(/[^a-zA-Z0-9\s]/g, '')}`,setRevenueFileId,setRevenueFileError);
        if(costFile) sendCostFile(contextObject.apiToken,costFile[0],`${projectName.replace(/[^a-zA-Z0-9\s]/g, '')}`,setCostFileId,setCostFileError);

        if(serviceCostFile) sendServiceCostFile(contextObject.apiToken,serviceCostFile[0],`${projectName.replace(/[^a-zA-Z0-9\s]/g, '')}`,setServiceTaskFilename,setServiceRevenueFilename,setServiceCostFilename,setServiceFilesComplete,serviceContractList,setServiceCostFileError)

        if(!contractFile && !prevailingWageFile && !bondFile && !taxExemptFile && !certificateFile && !taskFile && !revenueFile && !costFile && !serviceCostFile){
            submittingCheck = true;
            if(!rejectionSelected) sendProjectBookingRequest(contextObject.apiToken,createProjectBookingObject(),setCompleteSubmission,setCompletionError);
            else updateProjectBookingRequest(contextObject.apiToken,createUpdateProjectBookingObject(),setCompleteSubmission,setCompletionError);
        } 
    };

    const checkFormComplete = () => {
        let readyToSubmit = true;

        if(!customerSelected) readyToSubmit = false;
        if(!locationSelected) readyToSubmit = false;
        if(!branchAndManagerComplete) readyToSubmit = false;
        if(!contractComplete) readyToSubmit = false;
        if(!validSalesLink) readyToSubmit = false;
        if(!filesComplete) readyToSubmit = false;
        if(!budgetComplete) readyToSubmit = false;
        if(budgetResponseErrors) readyToSubmit = false;

        return(readyToSubmit);
    };

    const checkFilesComplete = () => {
        let readyToSubmit = true;

        if(!checkFormComplete()) readyToSubmit = false;
        if(contractFile && !contractFileId) readyToSubmit = false;
        if(prevailingWageFile && !prevailingWageFileId) readyToSubmit = false;
        if(bondFile && !bondFileId) readyToSubmit = false;
        if(taxExemptFile && !taxExemptFileId) readyToSubmit = false;
        if(certificateFile && !certificateFileId) readyToSubmit = false;

        if(taskFile && !taskFileId) readyToSubmit = false;
        if(revenueFile && !revenueFileId) readyToSubmit = false;
        if(costFile && !costFileId) readyToSubmit = false;

        if(serviceCostFile && !serviceTaskFilename) readyToSubmit = false;
        if(serviceCostFile && !serviceRevenueFilename) readyToSubmit = false;
        if(serviceCostFile && !serviceCostFilename) readyToSubmit = false;
        
        return(readyToSubmit);
    };

    useEffect(() => {
        if(checkFilesComplete() && !submittingCheck && !rejectionSelected){
            submittingCheck = true;
            sendProjectBookingRequest(contextObject.apiToken,createProjectBookingObject(),setCompleteSubmission,setCompletionError);
        }
        else if(checkFilesComplete() && !submittingCheck && rejectionSelected){
            submittingCheck = true;
            updateProjectBookingRequest(contextObject.apiToken,createUpdateProjectBookingObject(),setCompleteSubmission,setCompletionError);
        }

    },[contractFileId,prevailingWageFileId,bondFileId,taxExemptFileId,certificateFileId,taskFileId,revenueFileId,costFileId,serviceFilesComplete]);

    // REJECTION - - - - - - - 
    const [rejectionList, setRejectionList] = useState();
    const [rejectionSelected, setRejectionSelected] = useState();
    const [rejectionRecord, setRejectionRecord] = useState();

    useEffect(() => {
        newProjectBookingDataService.getRejectedList(contextObject.apiToken,contextObject.activeUser.id).then(res => setRejectionList(res.data.rejection))
    },[]);

    const clearForm = () => {
        setRejectionRecord(null);
        setCustomerSelected(null);
        setLocationSelected(null);
        setEntityName('');
        setCustomerPoNumber('');
        setBranch('');
        setProjectManager('');
        setBranchAndManagerComplete(false);
        setProjectName('');
        setProjectStreet('');
        setProjectStreet2('');
        setProjectCity('');
        setProjectState('');
        setProjectZip('');
        setOwnerDirectPurchase(false);
        setPrevailingWage(false);
        setBondRequired(false);
        setBusinessLicenseRequired(false);
        setPermitsRequired(false);
        setCcipInsurance('None');
        setBillingType('Progressive');
        setTaxStatus('Contractor');
        setDetailsComplete(false);
        setTotalContractAmount(0);
        setTotalCostAmount(0);
        setMargin(0);
        setDefaultRetainage(0);
        setServiceContractList([]);
        setContractComplete(false);
        setSalesLink('');
        setFilesRequired(false);
        setFilesComplete(false);
        setContractFileId('');
        setPrevailingWageFileId('');
        setBondFileId('');
        setTaxExemptFileId('');
        setCertificateFileId('');
        setBudgetAvailable('');
        setBudgetSkipReason('');
    };

    const populateForm = (record) => {
        console.log(record)
        if(record.projectType === 'Service'){
            getServiceContracts(contextObject.apiToken,record.id,setServiceContractList,setContractValueError);
        };
        setRejectionRecord(record);
        setProjectRequired(true);
        setCustomerSelected(record.customer);
        setLocationSelected(record.customer.location);
        if(record.entityName) setEntitySelection(false);
        setEntityName(record.entityName);
        setCustomerPoNumber(record.poNumber);
        setBranch(record.branch);
        setProjectManager(record.projectManager);
        setBranchAndManagerComplete(true);
        setProjectName(record.name);
        setProjectStreet(record.address.street);
        setProjectStreet2(record.address.street2);
        setProjectCity(record.address.city);
        setProjectState(record.address.state);
        setProjectZip(record.address.zip);
        setProjectType(record.projectType);
        setOwnerDirectPurchase(record.ownerDirect ? 'true' : 'false');
        setPrevailingWage(record.prevailingWage ? 'true' : 'false');
        setBondRequired(record.bondRequired ? 'true' : 'false');
        setBusinessLicenseRequired(record.businessLicense ? 'true' : 'false');
        setPermitsRequired(record.permitsRequired ? 'true' : 'false');
        setCcipInsurance(record.ccipInsturance);
        setBillingType('Progressive');
        setTaxStatus(record.taxStatus);
        setDetailsComplete(true);
        setTotalContractAmount(record.contractAmount);
        setTotalCostAmount(record.costAmount);
        setMargin(record.margin);
        setDefaultRetainage(record.defaultRetainage);
        setServiceContractList([]);
        setContractComplete(true);
        setSalesLink(record.salesLink);
        setFilesRequired(true);
        setFilesComplete(true);
        setContractFileId(record.contract);
        if(record.rateSheet) setPrevailingWageFileId(record.rateSheet);
        if(record.bonding) setBondFileId(record.bonding);
        if(record.taxExemption) setTaxExemptFileId(record.taxExemption);
        if(record.ocip) setCertificateFileId(record.ocip);

        if(record.budget){
            if(record.budget.type === 'None'){
                setBudgetAvailable('None');
                setBudgetSkipReason(record.budget.text);
            };
            if(record.budget.type === 'ProEst'){
                setBudgetAvailable('ProEst');
                setProestBudget(record.budget.estimateNumber);
            };
            if(record.budget.type === 'Standard'){
                setBudgetAvailable('Standard');
                const budgetFiles = {
                    task: record.budget.taskFileName,
                    revenue: record.budget.revenueFileName,
                    cost: record.budget.costFileName,
                }
                setBudgetFiles(budgetFiles);
            };
        };

        setNotes(record.notes);
    };

    const getForm = (id) => {
        rejectionList.forEach(element => {
            if(element.recordId === id) setRejectionSelected(element);
        });
        newProjectBookingDataService.getRecord(contextObject.apiToken,id).then(res => populateForm(res.data.record));
    };

    const cancelRejection = () => {
        setRejectionSelected(null);
        clearForm();
    };

    const generateServiceContractTemplate = () => {
        createServiceContractTemplate(contextObject.apiToken,serviceContractList,setServiceCostBudgetTemplate);
        setBudgetAvailable('Service');
    };

    const clearBudgetFiles = () => {
        setBudgetFiles(null);
        setBudgetError(null);
        setBudgetResponse(null);
        setBudgetImport(null);
    };

    // MAIN DISPLAY - - - - - - - 
    return(
        <div className='Project-Booking'>
            { !submitting &&
                <div className='project-Booking-Form'>
                    {   rejectionList && rejectionList.length > 0 &&
                        <RejectionWindow rejectionList={rejectionList} getForm={getForm} rejectionSelected={rejectionSelected} cancelRejection={cancelRejection}/>
                    }

                    {   !projectRequired &&
                        <ProjectRequiredForm projectRequired={projectRequired} setProjectRequired={setProjectRequired}/>
                    }

                    {   projectRequired &&    // CUSTOMER
                        <>
                            <CustomerSelector apiToken={contextObject.apiToken} customerSelected={customerSelected} setCustomerSelected={setCustomerSelected} locationSelected={locationSelected} setLocationSelected={setLocationSelected}/>

                            {   customerSelected &&
                                <>
                                <SelectInputField title={'Entity Name on Contract or PO same as Customer'} value={entitySelection} setTo={setEntitySelection} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
                                { enitityShown &&
                                <StandardInputField title={'Entity Name on Contract or PO'} value={entityName} setTo={setEntityName}/>
                                }
                                <StandardInputField title={'Customer PO Number'} value={customerPoNumber} setTo={setCustomerPoNumber}/>
                                </>
                            }
                        </>
                    }

                    {   customerSelected &&  locationSelected && // BRANCH & MANAGER
                    <>
                    <div className='Project-Booking-Section-Title'>Branch & Manager Details</div>
                    <BranchManagementForm {...branchManagementProps}/>
                    </>
                    }
                    
                    {   customerSelected &&  locationSelected && branchAndManagerComplete &&  // PROJECT TYPE
                    <>
                        <div className='Project-Booking-Section-Title'>Type</div>
                        <ProjectTypeForm projectType={projectType} setProjectType={setProjectType}/>
                    </>
                    }

                    {   customerSelected &&  locationSelected && branchAndManagerComplete && projectType === 'Project' &&  // PROJECT DETAILS
                    <>
                    <div className='Project-Booking-Section-Title'>Project Details</div>
                    <ProjectDetailsForm {...projectDetailProps}/>

                        { projectNameError &&
                            <div className='Project-Booking-Error-Line'>{projectNameError}</div>
                        }
                        { projectAddressError &&
                            <div className='Project-Booking-Error-Line'>{projectAddressError}</div>
                        }
                    </>
                    }

                    {   customerSelected &&  locationSelected && branchAndManagerComplete && projectType === 'Project' && detailsComplete && // PROJECT CONTRACT
                    <>
                    <div className='Project-Booking-Section-Title'>Contract Details</div>
                    <ProjectContractForm {...contractProps}/>
                    { contractValueError &&
                        <div className='Project-Booking-Error-Line'>{contractValueError}</div>
                    }
                    { estiamtedCostError &&
                        <div className='Project-Booking-Error-Line'>{estiamtedCostError}</div>
                    }
                    { retainageError &&
                        <div className='Project-Booking-Error-Line'>{retainageError}</div>
                    }
                    </>
                    }

                    {   customerSelected &&  locationSelected && branchAndManagerComplete && projectType === 'Service' && // SERVICE DETAILS
                    <>
                    <div className='Project-Booking-Section-Title'>Service Details</div>
                    <ServiceDetailsForm {...serviceDetailProps}/>

                    { projectNameError &&
                        <div className='Project-Booking-Error-Line'>{projectNameError}</div>
                    }
                    { projectAddressError &&
                        <div className='Project-Booking-Error-Line'>{projectAddressError}</div>
                    }
                    </>
                    }

                    {   customerSelected &&  locationSelected && branchAndManagerComplete && projectType === 'Service' && detailsComplete && // SERVICE CONTRACT
                    <>
                    <div className='Project-Booking-Section-Title'>Contract Details</div>
                    <ServiceContractForm {...contractProps}/>
                    {   !contractComplete &&
                        <div className='Project-Booking-Error-Line'>Please fill out the service contract information to continue.</div>
                    }
                    </>
                    }

                    {   customerSelected &&  locationSelected && branchAndManagerComplete && contractComplete && // LINKS
                        <> 
                        <div className='Project-Booking-Section-Title'>Links</div>
                        <StandardInputField title={'Sales Folder Link'} value={salesLink} setTo={setSalesLink} width={500}/>
                        { salesLinkError &&
                            <div className='Project-Booking-Error-Line'>{salesLinkError}</div>
                        }
                        </>
                    }

                    {   customerSelected &&  locationSelected && branchAndManagerComplete && validSalesLink && filesRequired && // FILES
                        <> 
                        <div className='Project-Booking-Section-Title'>Files</div>
                        <FilesForm {...fileProps}/>
                        { contractFileError &&
                            <div className='Project-Booking-Error-Line'>{contractFileError}</div>
                        }
                        { prevailingWageFileError &&
                            <div className='Project-Booking-Error-Line'>{prevailingWageFileError}</div>
                        }
                        { bondFileError &&
                            <div className='Project-Booking-Error-Line'>{bondFileError}</div>
                        }
                        { certificateFileError &&
                            <div className='Project-Booking-Error-Line'>{certificateFileError}</div>
                        }
                        </>
                    }
                    
                    {   customerSelected &&  locationSelected && branchAndManagerComplete && validSalesLink && filesComplete && projectType === 'Project' && // PROJECT BUDGET
                        <>
                            <div className='Project-Booking-Section-Title'>Budget</div>
                            <SelectInputField title={'What type of Budget would you like to upload?'} value={budgetAvailable} setTo={setBudgetAvailable} options={[{text:'Standard', value:'Standard'},{text:'ProEst',value:'ProEst'},{text:"I don't have a budget",value:'None'}]}/>
                        
                        {   budgetAvailable === 'Standard' && // STANDARD BUDGET
                            <div className='Project-Booking-Budget'>
                            {   !budgetImport &&
                            <>
                                <div className='Project-Booking-Budget-Import'>
                                    <div className='Project-Booking-Budget-Import-Instructions'>
                                        Please upload a Standard Budget. 
                                    </div>

                                    <div className='Project-Booking-Budget-Import-Controls'>
                                        <button className='Project-Booking-Budget-Import-Template' onClick={ () => getBudgetTemplate() }>Download Template</button>
                                        <input type='file' accept=".xls,.xlsx" onChange={(e) => setBudgetImport(e.target.files)}className='Project-Booking-Budget-Import-Upload'/>
                                    </div>
                                </div>
                            </>
                            }

                            {   budgetFiles && 
                            <div className='Project-Booking-Budget-Files'>
                                <div className='Project-Booking-File-Update'>
                                    A budget has already been uploaded. Choosing a new file above will overwrite all stored versions. For simple changes, download the existing cost budget below, make edits, and re-upload.
                                </div>

                                <div className='Project-Booking-Budget-Files-Tray'>
                                    {
                                        /*
                                    <button className='Record-Sales-Link' onClick={() => openProjectBookingTask(contextObject.apiToken,budgetFiles.task)}>Download Tasks</button>
                                    <button className='Record-Sales-Link' onClick={() => openProjectBookingRevenue(contextObject.apiToken,budgetFiles.revenue)}>Download Revenue Budget</button>

                                        */
                                    }
                                    <button className='Record-Sales-Link' onClick={() => openProjectBookingCost(contextObject.apiToken,budgetFiles.cost)}>Download Cost Budget</button>
                                </div>
{
    /*
                                <div className='Project-Booking-Budget-File-Upload'>
                                    <div className='Project-Booking-Budget-File-Title'>Update Task File</div>
                                    <input type='file' accept=".xls,.xlsx" onChange={(e) => setTaskFile(e.target.files)}className='Project-Booking-Budget-File-Input'/>
                                </div>

                                <div className='Project-Booking-Budget-File-Upload'>
                                    <div className='Project-Booking-Budget-File-Title'>Update Revenue File</div>
                                    <input type='file' accept=".xls,.xlsx" onChange={(e) => setRevenueFile(e.target.files)}className='Project-Booking-Budget-File-Input'/>
                                </div>
    */
}

                                <div className='Project-Booking-Budget-File-Upload'>
                                    <div className='Project-Booking-Budget-File-Title'>Update Cost File</div>
                                    <input type='file' accept=".xls,.xlsx" onChange={(e) => setCostFile(e.target.files)}className='Project-Booking-Budget-File-Input'/>
                                </div>
                            </div>
                            }

                            {   budgetImport && !budgetResponse &&
                                <LoadingSpinner size={60}/>
                            }

                            {   budgetResponse && !budgetError &&
                            <>
                                <button onClick={() => clearBudgetFiles()}>Clear Budget</button>
                                <BudgetComponent budgetResponse={budgetResponse} budgetResponseErrors={budgetResponseErrors}/>
                            </>
                            }
                            </div>
                        }

                        {   budgetAvailable === 'ProEst' && // PROEST BUDGET
                            <div className='Project-Booking-Budget'>
                                <StandardInputField title={'ProEst Estimate Number'} value={proestBudget} setTo={setProestBudget} width={250}/>
                            </div>
                        }

                        {   budgetAvailable === 'None' && // NO BUDGET
                            <>
                            <div className='Project-Booking-Section-Title'>Reason for Skipping Budget</div>
                            <BudgetSkipForm budgetSkipReason={budgetSkipReason} setBudgetSkipReason={setBudgetSkipReason}/>
                            </>
                        }

                        { budgetError &&
                            <div className='Project-Booking-Error-Line'>{budgetError}</div>
                        }

                        </>
                    }

                    {   customerSelected &&  locationSelected && branchAndManagerComplete && validSalesLink && filesComplete && projectType === 'Service' && // SERVICE BUDGET
                        <div className='Project-Booking-Budget'>
                            <div className='Project-Booking-Section-Title'>Budget</div>
                            {   !budgetFiles &&
                                <ServiceBudgetComponent generateServiceContractTemplate={generateServiceContractTemplate} serviceCostBudgetTemplate={serviceCostBudgetTemplate} setServiceCostFile={setServiceCostFile} budgetError={budgetError}/>
                            }

                            {   budgetFiles && 
                            <div className='Project-Booking-Budget-Files'>
                                <div className='Project-Booking-File-Update'>
                                    A budget has already been uploaded. Choosing a new file will overwrite the stored version.
                                </div>

                                <div className='Project-Booking-Budget-Files-Tray'>
{
    /*
                                    <button className='Record-Sales-Link' onClick={() => openProjectBookingTask(contextObject.apiToken,budgetFiles.task)}>Download Tasks</button>
                                    <button className='Record-Sales-Link' onClick={() => openProjectBookingRevenue(contextObject.apiToken,budgetFiles.revenue)}>Download Revenue Budget</button>

    */
}
                                    <button className='Record-Sales-Link' onClick={() => openProjectBookingCost(contextObject.apiToken,budgetFiles.cost)}>Download Cost Budget</button>
                                </div>
{
    /*
                                <div className='Project-Booking-Budget-File-Upload'>
                                    <div className='Project-Booking-Budget-File-Title'>Update Task File</div>
                                    <input type='file' accept=".xls,.xlsx" onChange={(e) => setTaskFile(e.target.files)}className='Project-Booking-Budget-File-Input'/>
                                </div>

                                <div className='Project-Booking-Budget-File-Upload'>
                                    <div className='Project-Booking-Budget-File-Title'>Update Revenue File</div>
                                    <input type='file' accept=".xls,.xlsx" onChange={(e) => setRevenueFile(e.target.files)}className='Project-Booking-Budget-File-Input'/>
                                </div>
    */
}


                                <div className='Project-Booking-Budget-File-Upload'>
                                    <div className='Project-Booking-Budget-File-Title'>Update Cost File</div>
                                    <input type='file' accept=".xls,.xlsx" onChange={(e) => setCostFile(e.target.files)}className='Project-Booking-Budget-File-Input'/>
                                </div>
                            </div>
                            }
                        </div>
                    }

                    {   checkFormComplete() && // NOTES
                        <>
                            <div className='Project-Booking-Section-Title'>Notes:</div>
                            <div className='Project-Booking-Skip-Budget'>
                                <textarea className='Project-Booking-Skip-Budget-Input' value={notes} onChange={e => setNotes(e.target.value)} />
                            </div>                        
                        </>
                    }

                    {   checkFormComplete() && // SUBMIT BUTTON
                        <div className='Project-Booking-Submit'> 
                            { !rejectionSelected &&
                                <button className='Project-Booking-Submit-Button' onClick={() => submitFiles()}>Submit Project Booking Request</button>
                            }
                            { rejectionSelected &&
                                <button className='Project-Booking-Submit-Button' onClick={() => submitFiles()}>Update Project Booking Request</button>
                            }
                        </div>
                    }
                </div>
            }

            {   submitting &&
                <div className='Project-Booking-Submission-Window'>
                    <div className='project-Booking-Submitting-Header'>
                        Submitting Project Booking Request
                    </div>
                    {   contractFile &&
                        <ElementSubmitComponent title={'Final Contract'} loading={!contractFileId} error={contractError} seperator={true}/>
                    }

                    {   prevailingWageFile &&
                        <ElementSubmitComponent title={'Certified Payroll / Prevailing Wage Sheet'} loading={!prevailingWageFileId} error={prevailingWageError} seperator={true}/>
                    }

                    {   bondFile &&
                        <ElementSubmitComponent title={'Bonding Requirements'} loading={!bondFileId} error={bondError} seperator={true}/>
                    }

                    {   taxExemptFile &&
                        <ElementSubmitComponent title={'Tax Exempt Document'} loading={!taxExemptFileId} error={taxExemptError} seperator={true}/>
                    }

                    {    certificateFile &&
                        <ElementSubmitComponent title={'OCIP / CCIP Insurance Document'} loading={!certificateFileId} error={certificateError} seperator={true}/>
                    }

                    {    taskFile &&
                        <ElementSubmitComponent title={'Updated Task Budget File'} loading={!taskFileId} error={taskFileError} seperator={true}/>
                    }

                    {    revenueFile &&
                        <ElementSubmitComponent title={'Updated Revenue Budget File'} loading={!revenueFileId} error={revenueFileError} seperator={true}/>
                    }

                    {    costFile &&
                        <ElementSubmitComponent title={'Updated Cost Budget File'} loading={!costFileId} error={costFileError} seperator={true}/>
                    }

                    {    serviceCostFile &&
                        <ElementSubmitComponent title={'Service Cost Budget'} loading={!serviceFilesComplete} error={serviceCostFileError} seperator={true}/>
                    }

                    <ElementSubmitComponent title={'Project Booking Form'} loading={!completeSubmission} error={completionError}/>

                    {   completeSubmission &&
                        <div className='Project-Booking-Submission-Success'>
                            Your Project Booking Request has been Submitted.
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default ProjectBooking;