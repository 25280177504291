
const StandardInputField = ({title, value, setTo, locked, width=300, searchCustomer, globalUpdate=null}) => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if(searchCustomer) searchCustomer();
        }
    };

    const globalUpdateCall = () => {
        if(globalUpdate) globalUpdate();
    };

    return(
        <div className='Project-Booking-Input-Field'>
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            {   !locked &&
                <input className='Project-Booking-Input-Field-Input' style={{width:`${width}px`}} onBlur={() => globalUpdateCall()}  value={value} onChange={e => setTo(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
            }
            {   locked &&
                <input className='Project-Booking-Input-Field-Input' disabled style={{width:`${width}px`}} value={value} onChange={e => setTo(e.target.value)}/>
            }
        </div>
    )
};

export default StandardInputField;