import axios from "axios";
import { API_URL,SERVER_URL } from '../Constants';

const convertToBase64 = (arrayBuffer) => {
    var base64    = ''
    var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
    
    var bytes         = new Uint8Array(arrayBuffer)
    var byteLength    = bytes.byteLength
    var byteRemainder = byteLength % 3
    var mainLength    = byteLength - byteRemainder
    
    var a, b, c, d
    var chunk
    
    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]
    
        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
        d = chunk & 63               // 63       = 2^6 - 1
    
        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
    }
    
    // Deal with the remaining bytes and padding
    if (byteRemainder == 1) {
        chunk = bytes[mainLength]
    
        a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2
    
        // Set the 4 least significant bits to zero
        b = (chunk & 3)   << 4 // 3   = 2^2 - 1
    
        base64 += encodings[a] + encodings[b] + '=='
    } else if (byteRemainder == 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]
    
        a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4
    
        // Set the 2 least significant bits to zero
        c = (chunk & 15)    <<  2 // 15    = 2^4 - 1
    
        base64 += encodings[a] + encodings[b] + encodings[c] + '='
    }
    
    return base64
};

class newProjectBookingDataService{
    uploadBudget(accessToken, budget, filename) {
        const formData = new FormData();
        formData.append('file', budget);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}importBudget/`, formData, {headers:{'content-type': 'multipart/form-data','Authorization':accessToken}});
    };

    getBlankTemplate(){
        window.open(`${API_URL}getBudgetTemplate/`)
    };

    getFilteredCostCodeList(accessToken, costCode) {
        return axios.get(`${API_URL}getCostCodeList/${costCode}`, {headers:{'Authorization':accessToken}});
    };

    getFilteredCustomerList(config) {
        return axios.get(`${API_URL}getFilteredCustomerList/`, config);
    };

    getFilteredProjectManagerList(accessToken, branch) {
        return axios.get(`${API_URL}getProjectManagerList/${branch}`, {headers:{'Authorization':accessToken}});
    };

    postContract(accessToken, file, filename){
        const formData = new FormData();
        formData.append("file", file);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postProjectBookingContract/`, formData, {headers:{'Authorization':accessToken,'content-type': 'multipart/form-data'}});
    };

    postPrevailingWage(accessToken, file, filename){
        const formData = new FormData();
        formData.append("file", file);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postProjectBookingPrevailing/`, formData, {headers:{'Authorization':accessToken,'content-type': 'multipart/form-data'}});
    };

    postBond(accessToken, file, filename){
        const formData = new FormData();
        formData.append("file", file);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postProjectBookingBonding/`, formData, {headers:{'Authorization':accessToken,'content-type': 'multipart/form-data'}});
    };

    postTaxExempt(accessToken, file, filename){
        const formData = new FormData();
        formData.append("file", file);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postProjectBookingTaxExempt/`, formData, {headers:{'Authorization':accessToken,'content-type': 'multipart/form-data'}});
    };

    postCertificate(accessToken, file, filename){
        const formData = new FormData();
        formData.append("file", file);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postProjectBookingCertificate/`, formData, {headers:{'Authorization':accessToken,'content-type': 'multipart/form-data'}});
    };

    postServiceCostFile(accessToken, file,serviceContractList,projectName){
        const formData = new FormData();
        formData.append('file', file);
        formData.append('projectName', projectName);
        formData.append('serviceContractList', JSON.stringify(serviceContractList));

        return axios.post(`${API_URL}postServiceCostBudget/`, formData, {headers:{'content-type': 'multipart/form-data','Authorization':accessToken}});
    };

    submitProjectBookingRequest(accessToken, projectBookingRequest) {
        return axios.post(`${API_URL}postProjectBookingRequest/`, projectBookingRequest, {headers:{'Authorization':accessToken}});
    };

    updateProjectBookingRequest(accessToken, projectBookingRequest) {
        return axios.post(`${API_URL}updateProjectBooking/`, projectBookingRequest, {headers:{'Authorization':accessToken}});
    };

    approveRecord(accessToken, approvalObject){
        return axios.post(`${API_URL}approveProjectBooking/`, approvalObject, {headers:{'Authorization':accessToken}});
    };

    updateRecord(accessToken, requestRecord){
        return axios.post(`${API_URL}updateProjectBooking/`, requestRecord, {headers:{'Authorization':accessToken}});
    };

    rejectRecord(accessToken, rejection) {
        return axios.post(`${API_URL}rejectProjectBookingRequest/`, rejection, {headers:{'Authorization':accessToken}});
    };

    getRejectedRecord(accessToken, id) {
        return axios.get(`${API_URL}rejectedProjectBookingOnRequest/${id}`, {headers:{'Authorization':accessToken}});
    };

    getRejectedList(accessToken, id) {
        return axios.get(`${API_URL}rejectedProjectBookingOnUser/${id}`, {headers:{'Authorization':accessToken}});
    };

    inactivateRecord(accessToken, id) {
        return axios.put(`${API_URL}inactivateProjectBooking/${id}`, {}, {headers:{'Authorization':accessToken}});
    };

    activateRecord(accessToken, id) {
        return axios.put(`${API_URL}activateProjectBooking/${id}`, {}, {headers:{'Authorization':accessToken}});
    };

    getList(accessToken, filterObject) {
        return axios.get(`${API_URL}getProjectBookingList/`,  {headers:{'Authorization':accessToken}, params:filterObject},);
    };

    getSearchList(accessToken, searchObject) {
        return axios.get(`${API_URL}getProjectBookingSearchList/`,  {headers:{'Authorization':accessToken}, params:searchObject},);
    };

    getRecord(accessToken, id) {
        return axios.get(`${API_URL}getProjectBookingRequest/${id}`, {headers:{'Authorization':accessToken}});
    };

    toggleFlag(accessToken, id) {

        return axios.put(`${API_URL}toggleProjectBookingFlag/${id}`,{}, {headers:{'Authorization':accessToken}});
    };

    async getContract(accessToken, id){
        return axios.get(`${API_URL}getProjectBookingContract/${id}`, {responseType: "arraybuffer", headers:{'Authorization':accessToken}}).then((res) => {
            return(convertToBase64(res.data));
        });
    };

    async getOcip(accessToken, id){
        return axios.get(`${API_URL}getProjectBookingOcip/${id}`, {responseType: "arraybuffer", headers:{'Authorization':accessToken}}).then((res) => {
            return(convertToBase64(res.data));
        });
    };

    async getPrevailing(accessToken, id){
        return axios.get(`${API_URL}getProjectBookingPrevailing/${id}`, {responseType: "arraybuffer", headers:{'Authorization':accessToken}}).then((res) => {
            return(convertToBase64(res.data));
        });
    };

    async getTaxExemption(accessToken, id){
        return axios.get(`${API_URL}getProjectBookingTaxExemption/${id}`, {responseType: "arraybuffer", headers:{'Authorization':accessToken}}).then((res) => {
            return(convertToBase64(res.data));
        });
    };

    async getBonding(accessToken, id){
        return axios.get(`${API_URL}getProjectBookingBonding/${id}`, {responseType: "arraybuffer", headers:{'Authorization':accessToken}}).then((res) => {
            return(convertToBase64(res.data));
        });
    };
    
    async getBudgetFile(accessToken, fileName){
        window.open(`${SERVER_URL}${fileName}`, {headers:{'Authorization':accessToken}})
    };

    async updateProjectBookingTask(accessToken, file, projectName){
        const formData = new FormData();
        formData.append("file", file);
        formData.append('projectName', projectName);
        return axios.post(`${API_URL}postProjectBookingTask/`, formData, {headers:{'Authorization':accessToken,'content-type': 'multipart/form-data'}});
    };

    async updateProjectBookingRevenue(accessToken, file, projectName){
        const formData = new FormData();
        formData.append("file", file);
        formData.append('projectName', projectName);
        return axios.post(`${API_URL}postProjectBookingRevenue/`, formData, {headers:{'Authorization':accessToken,'content-type': 'multipart/form-data'}});
    };

    async updateProjectBookingCost(accessToken, file, projectName){
        const formData = new FormData();
        formData.append("file", file);
        formData.append('projectName', projectName);
        return axios.post(`${API_URL}postProjectBookingCost/`, formData, {headers:{'Authorization':accessToken,'content-type': 'multipart/form-data'}});
    };

    async getServiceContracts(accessToken, id){
        return axios.get(`${API_URL}getProjectBookingServiceContracts/${id}`, {headers:{'Authorization':accessToken}});
    };

    async markRequestComplete(accessToken, id) {
        return axios.put(`${API_URL}markRequestComplete/${id}`,{}, {headers:{'Authorization':accessToken}});
    };

    async postServiceContracts(accessToken,serviceContractObject) {
        return axios.post(`${API_URL}postServiceContracts`, serviceContractObject, {headers:{'Authorization':accessToken}});
    };

    async postServiceContractTemplate(accessToken,serviceContractList) {
        return axios.post(`${API_URL}postServiceCostTemplate`, serviceContractList, {headers:{'Authorization':accessToken}});
    };
};

newProjectBookingDataService = new newProjectBookingDataService();
export default newProjectBookingDataService;