import './CustomerSelector.css';
import { useState,useEffect } from "react";
import LoadingSpinner from '../Components/LoadingSpinner';
import { FaCheck,FaRegCopy } from "react-icons/fa";
import newProjectBookingDataService from '../Services/ProjectBookingService';

import AddressInputField from '../Inputs/AddressInputField';
import StandardInputField from '../Inputs/StandardInputField';

const buildCustomerAddress = (location) => {
    let addressString = ''

    if(location.address.street){
        addressString = addressString + location.address.street;
    };

    if(location.address.street2){
        addressString = addressString + ' ' + location.address.street2;
    };

    if(location.address.city){
        addressString = addressString + ' ' + location.address.city;
    };

    if(location.address.state){
        addressString = addressString + ', ' + location.address.state;
    };

    if(location.address.zip){
        addressString = addressString + ' ' + location.address.zip;
    };        

    return addressString;
};

const CustomerLocationHeader = () => {
    const LocationHeaderColumn = ({width, text}) => {
        return(
            <div className='Project-Booking-Customer-Selection-Header-Column' style={{width:`${width}px`}}>
                {text}
            </div>
        )
    };
    return (
        <div className='Project-Booking-Customer-Selection-Header'>
            <LocationHeaderColumn width={180} text={'Location Title'}/>
            <LocationHeaderColumn width={420} text={'Location Address'}/>
        </div>
    )
};

const CustomerSelectionHeader = ({sortOnName,sortOnId,sortOnClass}) => {
    const CustomerHeaderColumn = ({width, text, clickFunction}) => {
        return(
            <div className='Project-Booking-Customer-Selection-Header-Column' style={{width:`${width}px`}} onClick={() => clickFunction()}>
                {text}
            </div>
        )
    };

    return (
        <div className='Project-Booking-Customer-Selection-Header'>
            <CustomerHeaderColumn width={120} text={'Customer ID'} clickFunction={() => sortOnId}/>
            <CustomerHeaderColumn width={300} text={'Customer Name'} clickFunction={() => sortOnName}/>
            <CustomerHeaderColumn width={150} text={'Customer Class'} clickFunction={() => sortOnClass}/>
            <CustomerHeaderColumn width={180} text={'Location Title'}/>
            <CustomerHeaderColumn width={420} text={'Location Address'}/>
        </div>
    )
};

const CustomerLocationRow = ({location,selectLocation}) => {
    const LocationSelectColumn = ({width, text}) => {
        return(
            <div className='Project-Booking-Customer-Selection-Column' style={{width:`${width}px`}}>
                {text}
            </div>
        )
    };

    return(
        <div className='Project-Booking-Customer-Selection-Row' onClick={() => selectLocation(location)}>
            <LocationSelectColumn width={180} text={location.locationName}/>
            <LocationSelectColumn width={420} text={buildCustomerAddress(location)}/>
        </div>
    )
};

const CustomerSelectionRow = ({customer,selectCustomer}) => {
    const CustomerSelectColumn = ({width, text}) => {
        return(
            <div className='Project-Booking-Customer-Selection-Column' style={{width:`${width}px`}}>
                {text}
            </div>
        )
    };

    return(
        <div className='Project-Booking-Customer-Selection-Row' onClick={() => selectCustomer(customer)}>
            <CustomerSelectColumn width={120} text={customer.customerId}/>
            <CustomerSelectColumn width={300} text={customer.name}/>
            <CustomerSelectColumn width={150} text={customer.class}/>
            <CustomerSelectColumn width={180} text={customer.locations[0].locationName}/>
            <CustomerSelectColumn width={420} text={buildCustomerAddress(customer.locations[0])}/>
        </div>
    )
};

const CustomerSelectionWindow = ({customerList,selectCustomer,customerSelected,sortOnName,sortOnId,sortOnClass}) => {
    return(
        <div className='Project-Booking-Customer-Selection'>
            <CustomerSelectionHeader sortOnName={sortOnName} sortOnId={sortOnId} sortOnClass={sortOnClass}/>

            {  customerList &&
                customerList.map((customer,key) => (
                    <CustomerSelectionRow key={key} customer={customer} selectCustomer={selectCustomer} customerSelected={customerSelected}/>
                ))
            }
        </div>
    )
};

const SelectedCustomerWindow = ({customer, locationSelected, setLocationSelected}) => {

    const selectLocation = (location) => {
        setLocationSelected(location);
    };

    const CustomerLine = ({title,value}) => {
        return (
            <div className='Project-Booking-Selected-Customer-Line'>
                <div className='Project-Booking-Selected-Customer-Line-Title'>
                    {title}
                </div>
                <div className='Project-Booking-Selected-Customer-Line-Value'>
                    {value}
                </div>
            </div>
        )
    };

    return(
        <div className='Project-Booking-Selected-Customer-Window'>
            <CustomerLine title={'ID:'} value={customer.customerId}/>
            <CustomerLine title={'Name:'} value={customer.name}/>
            <CustomerLine title={'Class:'} value={customer.class}/>
            
            {   !locationSelected &&
                <div className='Project-Booking-Selected-Customer-Locations'>
                    <div className='Project-Booking-Selected-Customer-Locations-Title'>
                        Please select a customer location below.
                    </div>
                    <div className='Project-Booking-Selected-Customer-Location-Table'>
                        < CustomerLocationHeader />
                        {
                            customer.locations.map((element,key) => (
                                <CustomerLocationRow location={element} selectLocation={selectLocation}/>
                            ))
                        }
                    </div>

                </div>
            }
            {   locationSelected &&
                <CustomerLine title={'Location:'} value={buildCustomerAddress(locationSelected)}/>
            }
        </div>
    )
};

const getFilteredCustomers = async (apiToken,customerNumber,customerName,customerStreet,customerCity,customerState,customerZip,setCustomerList,setEmpty,setError) => {
    const customerSearchObject = {
        number: `${customerNumber}`,
        name: `${customerName}`,
        street: `${customerStreet}`,
        city: `${customerCity}`,
        state: `${customerState}`,
        zip: `${customerZip}`,
    };

    const config = {
        headers: {
            'Authorization': apiToken
        },
        params: customerSearchObject
    };

    newProjectBookingDataService.getFilteredCustomerList(config)
    .then(response =>{
        if(response.status > 199 && response.status < 300){
            if(response.status === 204) setEmpty(true)
            else{
                setCustomerList(response.data);
            }
        }
        else{
            setError('Failed to search customers. Please try again and contact IT if this issue continues.');
        }

    }).catch( e=> {
        setError('Failed to search customers. Please try again and contact IT if this issue continues.');
        console.log(e.toString());
    });
};

const CustomerDetailsForm = ({customerNumber,setCutomerNumber,customerName,setCustomerName,
    customerStreet,setCustomerStreet,customerCity,setCustomerCity,customerState,setCustomerState,customerZip,setCustomerZip,
    customerList,customerSearch,clearCustomerList,customerSelected,setCustomerSelected,locationSelected,setLocationSelected,
    sortOnName,sortOnId,sortOnClass}) => {

    const [searchingCustomer, setSearchingCustomer] = useState(false);
    const [emptyResults, setEmptyResults] = useState(false);
    const [error,setError] = useState('');
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if(emptyResults){
            setEmptyResults(false);
        };
        if(customerList){
            clearCustomerList();
        }
    },[customerNumber,customerName,customerStreet,customerCity,customerState,customerZip]);

    useEffect(() => {
        if(customerList && customerList.length > 0){
            setSearchingCustomer(false);
        }
    },[customerList]);

    useEffect(() => {
        setSearchingCustomer(false);
    },[emptyResults]);

    const selectCustomer = (customer) => {
        setCustomerSelected(customer);
        if(customer.locations.length < 2){
            setLocationSelected(customer.locations[0]);
        }
        clearCustomerList();
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText('https://www.cornerstoneinc.io/newCustomer');
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        },1000);
    };

    const clearCustomer = () => {
        setError('');
        setSearchingCustomer(false);
        setCutomerNumber('');
        setCustomerName('')
        setCustomerStreet('');
        setCustomerCity('');
        setCustomerState('');
        setCustomerZip('');
        setCustomerSelected(false);
        clearCustomerList();
        setEmptyResults(false);
    };

    const searchCustomer = () => {
        setSearchingCustomer(true);
        customerSearch(setEmptyResults,setError);
    };

    const checkValidInput = () => {
        if(customerNumber.length > 1 || customerName.length > 2 || customerStreet.length > 2 || customerCity.length > 2 || customerState.length >= 2 || customerZip.length > 4) return true;
        else return false;
    };

    return(
        <div className='Project-Booking-Input-Form'>
            {   (!searchingCustomer && !customerSelected && !customerList) && (customerNumber.length <= 2 && customerName.length <= 2) &&
                <div className='Project-Booking-Customer-Search-Message'>
                    Start by searching for a customer using either their number, name, or address.
                </div>
            }

            {   !customerSelected &&
            <>
            <StandardInputField title={'Customer Number'} value={customerNumber} setTo={setCutomerNumber} searchCustomer={searchCustomer}/>
            <StandardInputField title={'Customer Name'} value={customerName} setTo={setCustomerName} searchCustomer={searchCustomer}/>
            <AddressInputField title={'Customer Address'} street={customerStreet} setStreet={setCustomerStreet} city={customerCity} setCity={setCustomerCity} state={customerState} setState={setCustomerState} zip={customerZip} setZip={setCustomerZip} locked={false} searchCustomer={searchCustomer}/>
            </>
            }

            {   customerSelected &&
            <>
                <SelectedCustomerWindow customer={customerSelected} locationSelected={locationSelected} setLocationSelected={setLocationSelected}/>
                <button className='Project-Booking-Customer-Clear-Button' onClick={() => clearCustomer()}>Clear Customer</button>
            </>
            }

            {   !emptyResults && (!searchingCustomer && !customerSelected && !customerList) && !checkValidInput() &&
                <button className='Project-Booking-Customer-Search-Button'>Search for Customer</button>
            }

            {   !emptyResults && (!searchingCustomer && !customerSelected && !customerList) && checkValidInput() &&
                <button className='Project-Booking-Customer-Search-Button Search-Button-Active' onClick={() => searchCustomer()}>Search for Customer</button>
            }

            {   searchingCustomer && !error &&
                <LoadingSpinner size={60}/>
            }
            {   customerList && customerList.length > 0 && !searchingCustomer &&
            <>
                <CustomerSelectionWindow customerList={customerList} selectCustomer={selectCustomer} customerSelected={customerSelected} sortOnName={sortOnName} sortOnId={sortOnId} sortOnClass={sortOnClass}/>
                <button className='Project-Booking-Customer-Clear-Button' onClick={() => clearCustomer()}>Clear Customer</button>
            </>
            }

            {   emptyResults &&
            <div className='Project-Booking-Customer-Empty'>
                <div className='Project-Booking-Customer-Empty-Message'>
                    No Customer Found.
                </div>
                <div  className='Project-Booking-Customer-Empty-Message'>
                    To add a new customer to the system, please send them the link below.
                </div>
                <div className='Project-Booking-Customer-Link-Container'>
                    <div className='Project-Booking-Customer-Link-Text'>
                        https://www.cornerstoneinc.io/newCustomer
                    </div>
                    <div className='Project-Booking-Customer-Link-Copy' onClick={() => copyToClipboard()}>
                        {   !copied &&
                            <FaRegCopy  className='Project-Booking-Customer-Link-Copy-Image'/>
                        }
                        {   copied &&
                            <FaCheck  className='Project-Booking-Customer-Link-Copy-Image'/>
                        }
                    </div>

                    {   copied &&
                    <div  className='Project-Booking-Customer-Empty-Message'>
                        Copied Link
                    </div>
                    }
                </div>


                <button className='Project-Booking-Customer-Clear-Button' onClick={() => clearCustomer()}>Try Again</button>
            </div>
            }

            {   error &&
            <>
                <div className='Project-Booking-Customer-Error'>
                    {error}
                </div>
                <button className='Project-Booking-Customer-Clear-Button' onClick={() => clearCustomer()}>Try Again</button>
            </>
            }

        </div>
    )
};

const CustomerSelector = ({apiToken,customerSelected,setCustomerSelected,locationSelected,setLocationSelected,title=true}) => {
    const [customerList, setCustomerList] = useState();
    const [customerNumber, setCutomerNumber] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerStreet, setCustomerStreet] = useState('');
    const [customerCity, setCustomerCity] = useState('');
    const [customerState, setCustomerState] = useState('');
    const [customerZip, setCustomerZip] = useState('');

    const sortOnName = () => {

    };

    const sortOnId = () => {

    };

    const sortOnClass = () => {

    };

    const clearCustomerList = () => {
        setCustomerList(null);
    };

    const clearForm = () => {
        setCutomerNumber('');
        setCustomerName('');
        setCustomerStreet('');
        setCustomerCity('');
        setCustomerState('');
        setCustomerZip('');
    };

    const customerSearch = (setEmpty,setError) => {
        setLocationSelected(null);
        getFilteredCustomers(apiToken,customerNumber,customerName,customerStreet,customerCity,customerState,customerZip,setCustomerList,setEmpty,setError)
    };

    const customerDetailProps = {
        customerSelected:customerSelected,
        setCustomerSelected:setCustomerSelected,
        locationSelected:locationSelected,
        setLocationSelected:setLocationSelected,
        customerNumber:customerNumber,
        setCutomerNumber:setCutomerNumber,
        customerName:customerName,
        setCustomerName:setCustomerName,
        customerStreet:customerStreet,
        setCustomerStreet:setCustomerStreet,
        customerCity:customerCity,
        setCustomerCity:setCustomerCity,
        customerState:customerState,
        setCustomerState:setCustomerState,
        customerZip:customerZip,
        setCustomerZip:setCustomerZip,
        customerList:customerList,
        customerSearch:customerSearch,
        clearCustomerList:clearCustomerList,
        sortOnName:sortOnName,
        sortOnId:sortOnId,
        sortOnClass:sortOnClass,
    };

    useEffect(() => {
        if(customerSelected && locationSelected){
            clearCustomerList();
            clearForm();
        };
    },[customerSelected,locationSelected]);

    return(
        <>
        {   !customerSelected && title &&
            <div className='Project-Booking-Section-Title'>Select a Customer</div>
        }
        {   customerSelected && title &&
            <div className='Project-Booking-Section-Title'>Customer</div>
        }
        <CustomerDetailsForm {...customerDetailProps}/> 
        </>
    )
};

export default CustomerSelector;