import './NewVendor.css'
import Logo from '../refs/CornerstoneColor.jpg';
import { useEffect, useState } from 'react';
import { useSearchParams,useParams } from "react-router-dom";
import { ImCheckboxUnchecked, ImCheckboxChecked  } from "react-icons/im";
import { FaStar, FaCheck, FaDotCircle  } from "react-icons/fa";
import { HiBadgeCheck } from "react-icons/hi";
import newVendorRequestService from '../Services/VendorRequestService';

import LoadingSpinner from '../Components/LoadingSpinner';

import newVendorDataService from "../Services/NewVendorService"


/* DRAWN SIGNATURE INPUT
    import fileDownload from 'js-file-download';
    import SignatureCanvas from 'react-signature-canvas';
    const sigCanvas = useRef({});
    const sigClear = () => sigCanvas.current.clear();
    const saveSignatureImage = () => {
        setSignatureImage(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
        checkConditionsComplete();
    };
    const submitSignature = () => {
        const filename = `${businessName}-Signature-${getDate()}.png`;
        sendSignature(signatureImage, filename, setSignatureId, setSignatureError);
    };
 <SignatureCanvas ref={sigCanvas} canvasProps={{width: 600, height: 200, className: 'External-Form-Signature'}} />

 const sendSignature = async (signature, filename, setSignatureId, setSignatureError) => {
    newVendorDataService.submitSignature(signature,filename)
    .then(response =>{
        setSignatureId(response.data.id);
    }).catch( e=> {
        setSignatureError(e.toString());
    });
};
*/



// API SUPPORT CALLS - - - - - - - - - - -
const sendVoidedCheck = async (voidCheck, filename, setVoidedCheckId, setVoidedCheckError) => {
    newVendorDataService.submitVoidedCheck(voidCheck,filename)
    .then(response =>{
        setVoidedCheckId(response.data.id);

    }).catch( e=> {
        setVoidedCheckError(e.toString());

    });
};

const sendCoi = async (coi, filename, setCoiId, setCoiError) => {
    newVendorDataService.submitCoi(coi,filename)
    .then(response =>{
        setCoiId(response.data.id);

    }).catch( e=> {
        setCoiError(e.toString());
    });
};

const sendVendorRecord = async (vendorObject,setFormComplete,setVendorRecordError) => {
    console.log(vendorObject)
    newVendorDataService.submitVendorRecord(vendorObject)
    .then(response =>{
        setFormComplete();
    }).catch( e=> {
        setVendorRecordError(e.toString());
    });
};

const updateVendorRecord = async (vendorObject,setFormComplete,setVendorRecordError) => {
    newVendorDataService.updateVendorRecord(vendorObject)
    .then(response =>{
        setFormComplete();
    }).catch( e=> {
        setVendorRecordError(e.toString());
    });
};

const getBlankW9 = () => {
    newVendorDataService.getW9()
};

const sendW9 = async (w9, filename, setW9Id, setW9Error) => {
    newVendorDataService.submitW9(w9,filename)
    .then(response =>{
        setW9Id(response.data.id);
    }).catch( e=> {
        setW9Error(e.toString());
    });
};

const sendInternationalWire = async (intWire, filename, setIntWireId, setIntWireError) => {
    newVendorDataService.submitIntWire(intWire,filename)
    .then(response =>{
        setIntWireId(response.data.id);
    }).catch( e=> {
        setIntWireError(e.toString());
    });
};

// FETCHES REJECTION RECORD
const grabRejectionRecord = async (sessionId,rejectionId,setRejectionRecord) => {
        newVendorRequestService.getRejectionRecord(sessionId, rejectionId).then(res => setRejectionRecord(res.data));
};


// HELPER FUNCTIONS - - - - - - - - - - -
const getDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    today = mm + '.' + dd + '.' + yyyy;
    return today;
};

const SignatureInput = ({title,setTo,complete=false ,width=0}) => {
    const [displayWidth, setDisplayWidth ] = useState('auto');

    const inputCssProps = {
        '--inputWidth': `${displayWidth}`
    };

    useEffect(() => {
        if(width > 0) setDisplayWidth(`${width}px`)
        else setDisplayWidth('auto')
    },[])

    return(
        <>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='true'/>
        <link href="https://fonts.googleapis.com/css2?family=Whisper&display=swap" rel="stylesheet"/>

        <div className='External-Form-Input' style={inputCssProps}>
            {   !complete &&
                <FaStar className='External-Form-Input-Required' />
            }
            {   complete &&
                <FaCheck className='External-Form-Input-Complete' />
            }
            
            <div className='External-Form-Input-Title'>
                {title}
            </div>
            <input type="text" className='External-Form-Signature-Box' onChange={(e) => setTo(e.target.value)} />
        </div>
        </>
    )
}

const TextInput = ({title,setTo, complete=false ,width=0,charCount=0,required=false,active=true, under=false, initializedValue=null}) => {
    const [displayWidth, setDisplayWidth ] = useState('auto');

    const inputCssProps = {
        '--inputWidth': `${displayWidth}`
    };

    useEffect(() => {
        if(width>0){
            setDisplayWidth(`${width}px`)
        }
        else{
            if(charCount > 0){
                setDisplayWidth(`${charCount*18}px`);
            }
            else{
                setDisplayWidth('auto')
            }
        }
    },[])

    if(!under){
        return(
            <div className='External-Form-Input' style={inputCssProps}>
                {   active && required && !complete &&
                <FaStar className='External-Form-Input-Required' />
                }
                {   active && !required && !complete &&
                <FaStar className='External-Form-Input-Not-Required' />
                }
                {   active && complete &&
                <FaCheck className='External-Form-Input-Complete' />
                }
                <div className='External-Form-Input-Title'>
                    {title}
                </div>
                {   charCount > 0 &&
                    <input type="text" placeholder={initializedValue} inputMode='numeric' maxLength={charCount} className='External-Form-Input-Contrained' onChange={(e) => setTo(e.target.value)}/>
                }
                {   charCount < 1 &&
                    <input type="text" placeholder={initializedValue} className='External-Form-Input-Box' onChange={(e) => setTo(e.target.value)} />
                }
                
            </div>
        )
    }
    else{
        return(
            <div className='External-Form-Input-Under' style={inputCssProps}>
                {   active && required && !complete &&
                <FaStar className='External-Form-Input-Required' />
                }
                {   active && !required && !complete &&
                <FaStar className='External-Form-Input-Not-Required' />
                }
                {   active && complete &&
                <FaCheck className='External-Form-Input-Complete' />
                }

                {   charCount > 0 &&
                    <input type="text" inputMode='numeric' placeholder={initializedValue} maxLength={charCount} className='External-Form-Input-Contrained' onChange={(e) => setTo(e.target.value)}/>
                }
                {   charCount < 1 &&
                    <input type="text" className='External-Form-Input-Box' placeholder={initializedValue} onChange={(e) => setTo(e.target.value)} />
                }

                <div className='External-Form-Input-Title-Under'>
                    {title}
                </div>
                
            </div>
        )
    }

};

const DropDownSelection = ({title, options, setTo, complete=false, width, active, required, initializedValue}) => {
    const inputCssProps = {
        '--inputWidth': `${width}`
    };

    const selectOption = (value) => {
         setTo(value);
    };

    return(
        <div className='External-Form-Input' style={inputCssProps}>
        {   active && required && !complete &&
        <FaStar className='External-Form-Input-Required' />
        }
        {   active && required && complete &&
        <FaCheck className='External-Form-Input-Complete' />
        }
        <div className='External-Form-Input-Title'>
            {title}
        </div>
        {   !initializedValue &&
        <select className='External-Form-Dropdown'onChange={(e) => selectOption(e.target.value)}>
            <option value={false}> </option>
            {   options.map((option, key) => (
                <option className='External-Form-Dropdown-Selection' key={key} value={option}>{option}</option>
            ))
            }
        </select>
        }
        {   initializedValue &&
        <select className='External-Form-Dropdown' onChange={(e) => selectOption(e.target.value)}>
            <option value={true}>{initializedValue}</option>
            {   options.map((option, key) => (
                <option className='External-Form-Dropdown-Selection' key={key} value={option}>{option}</option>
            ))
            }
        </select>
        }

        
    </div>
    )
};

const AccountSelector = ({title, selected, setTo}) => {
    return(
        <div className='External-Vendor-Type-Selection-Group'>
            {   !selected &&
                <ImCheckboxUnchecked className='External-Vendor-Type-Selection' onClick={() => setTo(true)}/>
            }
            {   selected &&
                <ImCheckboxChecked className='External-Vendor-Type-Selection' onClick={() => setTo(false)}/>
            }
            <div className='External-Vendor-Type-Selection-Text'>
                {title}
            </div>
        </div>
    )
};

const ClassificationSelector = ({value,classificationList,addClassification,removeClassification}) => {
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if(classificationList){
            if(classificationList.includes(value)){
                setSelected(true);
            }
            else setSelected(false);
        }
        else setSelected(false);

    },[classificationList]);

    return(
        <div className='External-Vendor-Type-Selection-Group'>
            {   !selected &&
                <ImCheckboxUnchecked className='External-Vendor-Type-Selection' onClick={() => addClassification(value)}/>
            }
            {   selected &&
                <ImCheckboxChecked className='External-Vendor-Type-Selection' onClick={() => removeClassification(value)}/>
            }
            <div className='External-Vendor-Type-Selection-Text'>
                {value}
            </div>
        </div>
    )
};

const checkEmail = (email) => {
    let valid = true;
    try{
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if(re.test(email)){
            // Valid Email
        }
        else{
            valid = false;
        }
    }
    catch{
        valid = false;
    }

    return valid;
};


// DISPLAY SECTIONS - - - - - - - - - -

// HEADER FOR FORM
const FormHeader = ({formEmail}) => {
    return(
        <div className='External-Form-Header'>
            <img src={Logo} className='External-Form-Logo' alt='Cornerstone Logo'/>

            <div className='External-Form-Contact'>
                <div className='External-Form-Contact-Content'>
                    2511 Midpark Road, Montgomery, AL 36109
                </div>
                <div className='External-Form-Contact-Content'>
                    (334) 286-4333
                </div>
            </div>
            <div className='External-Form-Email-Support'>
                {formEmail}
            </div>
            <div className='External-Vendor-Title'>
                    New Vendor Form
            </div>
        </div>
    )
};

// VENDOR TYPE SECTION
const VendorType = ({typeActive,formType,handleFormTypeSelection}) => {
    return(
        <div className='External-Vendor-Type'>

        {   typeActive &&
            <div className='External-Vendor-Type-Title External-Vendor-Bold'>
                Are you a new vendor or updating information for an existing vendor?
            </div>
        }


        <div className='External-Vendor-Type-Selections'>
            <div className='External-Vendor-Type-Selection-Group'>
                {   formType === 'New' &&
                    <ImCheckboxChecked className='External-Vendor-Type-Selection'/>
                }
                {   formType !== 'New' &&
                    <ImCheckboxUnchecked className='External-Vendor-Type-Selection' onClick={() => handleFormTypeSelection('New')}/>
                }
                <div className='External-Vendor-Type-Selection-Text'>
                    New Vendor
                </div>
            </div>

            <div className='External-Vendor-Type-Selection-Group'>
                {   formType === 'Update' &&
                    <ImCheckboxChecked className='External-Vendor-Type-Selection'/>
                }
                {   formType !== 'Update' &&
                    <ImCheckboxUnchecked className='External-Vendor-Type-Selection' onClick={() => handleFormTypeSelection('Update')}/>
                }
                <div className='External-Vendor-Type-Selection-Text'>
                    Update Vendor
                </div>
            </div>
            
        </div>

    </div>
    )
};

// VENDOR INFORMATION SECTION
const VendorInformation = ({formType,vendorInformationActive,setFedEinSSn,fedEinSSn,fedEinSSnComplete,setLegalName,legalName,
    legalNameComplete,setBusinessName,businessName,businessNameComplete,setStreet,street,setStreet2,street2,streetComplete,setCity,city,cityComplete,
    setState,state,stateComplete,setZip,zip,zipComplete,setCountry,country}) => {

    const [addressComplete, setAddressComplete] = useState(false);

    useEffect(() => {
        if(stateComplete && cityComplete && stateComplete && zipComplete) setAddressComplete(true);
        else setAddressComplete(false);
    },[streetComplete,cityComplete,stateComplete,zipComplete]);

    return(
        <>
        {   formType &&
            <div className='External-Vendor-Information'>
                {   vendorInformationActive &&
                    <>
                    <div className='External-Vendor-Type-Title External-Vendor-Bold'>
                        Please Fill in the Vendor/Payee Information Below.
                    </div>
                    </>

                }
                <TextInput title={'Federal EIN/SSN:'} setTo={setFedEinSSn} complete={fedEinSSnComplete} charCount={9} required={true} active={vendorInformationActive} initializedValue={fedEinSSn}/>
                <TextInput title={'Legal Name:'} setTo={setLegalName} complete={legalNameComplete} width={340} required={true} active={vendorInformationActive} initializedValue={legalName}/>
                <TextInput title={'Payee/Business Name:'} setTo={setBusinessName} complete={businessNameComplete} width={600} required={true} active={vendorInformationActive} initializedValue={businessName}/>

                <div className='External-Form-Spacer'>

                </div>
                <div className='External-Form-Input-Title'>
                Business Address:
                </div>
                <TextInput title={'Street:'} setTo={setStreet} complete={streetComplete} width={220} required={true} active={vendorInformationActive} under={true} initializedValue={street}/>
                <TextInput title={'Street2:'} setTo={setStreet2} width={120} required={false} active={vendorInformationActive} under={true} initializedValue={street2}/>
                <TextInput title={'City:'} setTo={setCity} complete={cityComplete} width={140} required={true} active={vendorInformationActive} under={true} initializedValue={city}/>
                <TextInput title={'State:'} setTo={setState} complete={stateComplete} charCount={2} width={40} required={true} active={vendorInformationActive} under={true} initializedValue={state}/>
                <TextInput title={'Zip:'} setTo={setZip} complete={zipComplete} charCount={5} required={true} active={vendorInformationActive} under={true} initializedValue={zip}/>
                <TextInput title={'Country:'} setTo={setCountry} charCount={2} required={false} active={vendorInformationActive} under={true} initializedValue={country}/>

                {   !fedEinSSnComplete &&
                <div  className='External-Vendor-Type-Error'>
                    Enter a Valid Federal EIN or SSN to continue.
                </div>
                }
                {   !legalNameComplete &&
                <div  className='External-Vendor-Type-Error'>
                    Enter a Valid Legal Name to continue.
                </div>
                }
                {   !businessNameComplete &&
                <div  className='External-Vendor-Type-Error'>
                    Enter a Valid Business Name to continue.
                </div>
                }
                {   !addressComplete &&
                <div  className='External-Vendor-Type-Error'>
                    Enter a Valid Address to continue.
                </div>
                }
            </div>
        }
        </>
    )
};

// CONTACTS SECTIONS
const ContactInformation = ({contactInformationShown,contactInformationActive,primaryContactFirstName,primaryContactLastName,setPrimaryContactFirstName,setPrimaryContactLastName,primaryContactTitle,setPrimaryContactTitle,primaryContactNameComplete,
    setPrimaryContactNumber,primaryContactNumber,primaryContactNumberComplete,setPrimaryContactNumberExtension,primaryContactNumberExtension,
    setPrimaryContactEmail,primaryContactEmail,primaryContactEmailComplete,
    secondaryContact,setSecondaryContact,
    secondaryContactFirstName,secondaryContactLastName,setSecondaryContactFirstName,setSecondaryContactLastName,secondaryContactNameComplete,secondaryContactTitle,setSecondaryContactTitle,setSecondaryContactNumber,secondaryContactNumber,
    secondaryContactNumberComplete,setSecondaryContactNumberExtension,secondaryContactNumberExtension,setSecondaryContactEmail,
    secondaryContactEmail,secondaryContactEmailComplete}) => {

    return(
        <>
        {   contactInformationShown &&
            <div className='External-Vendor-Information'>
                {   contactInformationActive &&
                <>
                    <div className='External-Vendor-Type-Title External-Vendor-Bold'>
                        Please Fill in the Contact Information Below.
                    </div>
                    <div className='External-Vendor-Type-Subtitle External-Vendor-Italic'>
                        Remittance will be sent to both contacts if secondary is added.
                    </div>
                </>
                }
                <div className='External-Vendor-Contact-Group'>
                    <TextInput title={'First Name:'} setTo={setPrimaryContactFirstName} complete={primaryContactNameComplete} width={150} required={true} active={contactInformationActive} initializedValue={primaryContactFirstName}/>
                    <TextInput title={'Last Name:'} setTo={setPrimaryContactLastName} complete={primaryContactNameComplete} width={150} required={true} active={contactInformationActive} initializedValue={primaryContactLastName}/>
                    <TextInput title={'Job Title:'} setTo={setPrimaryContactTitle} width={160} required={false} active={contactInformationActive} initializedValue={primaryContactTitle}/>
                    <TextInput title={'Telephone:'} setTo={setPrimaryContactNumber} complete={primaryContactNumberComplete} charCount={10} required={true} active={contactInformationActive} initializedValue={primaryContactNumber}/>
                    <TextInput title={'Extension'} setTo={setPrimaryContactNumberExtension} charCount={6} required={false} active={contactInformationActive}  initializedValue={primaryContactNumberExtension}/>
                    <TextInput title={'Email Address:'} setTo={setPrimaryContactEmail} complete={primaryContactEmailComplete} width={280} required={true} active={contactInformationActive}  initializedValue={primaryContactEmail}/>
                </div>

                {   !secondaryContact && 
                <button onClick={() => setSecondaryContact(true)} className='External-Vendor-Contact-Button Button-Add'>Add Secondary Contact</button>
                }
                {   secondaryContact && contactInformationActive &&
                   <button onClick={() => setSecondaryContact(false)} className='External-Vendor-Contact-Button Button-Remove'>Remove Secondary Contact</button>
                }

                {   secondaryContact &&
                <div className='External-Vendor-Contact-Group'>
                    <TextInput title={'First Name:'} setTo={setSecondaryContactFirstName} complete={secondaryContactNameComplete} width={150} required={true} active={contactInformationActive} initializedValue={secondaryContactFirstName}/>
                    <TextInput title={'Last Name:'} setTo={setSecondaryContactLastName} complete={secondaryContactNameComplete} width={150} required={true} active={contactInformationActive} initializedValue={secondaryContactLastName}/>
                    <TextInput title={'Job Title:'} setTo={setSecondaryContactTitle} width={160} required={false} active={contactInformationActive} initializedValue={secondaryContactTitle}/>
                    <TextInput title={'Telephone:'} setTo={setSecondaryContactNumber} complete={secondaryContactNumberComplete} charCount={10} required={true} active={contactInformationActive} initializedValue={secondaryContactNumber}/>
                    <TextInput title={'Extension'} setTo={setSecondaryContactNumberExtension} charCount={6} required={false} active={contactInformationActive} initializedValue={secondaryContactNumberExtension}/>
                    <TextInput title={'Email Address:'} setTo={setSecondaryContactEmail} complete={secondaryContactEmailComplete} width={280} required={true} active={contactInformationActive} initializedValue={secondaryContactEmail}/>
                </div>
                }

                {   !primaryContactNameComplete &&
                <div  className='External-Vendor-Type-Error'>
                    Enter a Valid Primary Contact Name to continue.
                </div>
                }
                {   !primaryContactNumberComplete &&
                <div  className='External-Vendor-Type-Error'>
                    Enter a Valid Primary Contact Number to continue.
                </div>
                }
                {   !primaryContactEmailComplete &&
                <div  className='External-Vendor-Type-Error'>
                    Enter a Valid Primary Contact Email Address to continue.
                </div>
                }

                {   !secondaryContactNameComplete && secondaryContact &&
                <div  className='External-Vendor-Type-Error'>
                    Enter a Valid Secondary Contact Name to continue.
                </div>
                }
                {   !secondaryContactNumberComplete && secondaryContact &&
                <div  className='External-Vendor-Type-Error'>
                    Enter a Valid Secondary Contact Number to continue.
                </div>
                }
                {   !secondaryContactEmailComplete && secondaryContact &&
                <div  className='External-Vendor-Type-Error'>
                    Enter a Valid Secondary Contact Email Address to continue.
                </div>
                }

            </div>

        }
        </>
    )
};

// PAYMENT SECTION
const PaymentInformation = ({paymentInformationShown,paymentInformationActive,vendorTypeOptions, setVendorTypeSelection, vendorTypeSelection, vendorTypeSelectionComplete,
    paymentTypes,setPaymentMethod,paymentMethod, paymentMethodComplete, paymentTermOptions, setPaymentTerms, paymentTerms, paymentTermsComplete, 
    customTermsShown, setCustomTerms,businessClassifications, classificationList, setClassificationList, editClassificationList=null}) => {

    const addClassification = (value) => {
        let newList = classificationList.filter(item => item !== value);
        newList.push(value);
        setClassificationList(newList);
    };

    const removeClassification = (value) => {
        let newList = classificationList.filter(item => item !== value);
        setClassificationList(newList);
    };

    return(
        <>
        {   paymentInformationShown &&
            <div className='External-Vendor-Information'>
                {   paymentInformationActive &&
                <>
                    <div className='External-Vendor-Type-Title External-Vendor-Bold'>
                        Please Fill out the Vendor Type and Payment Method.
                    </div>
                </>
                }
                <DropDownSelection title={'Vendor Type:'} setTo={setVendorTypeSelection} complete={vendorTypeSelectionComplete} options={vendorTypeOptions} required={true} active={paymentInformationActive} initializedValue={vendorTypeSelection}/>
                <DropDownSelection title={'Payment Method:'} setTo={setPaymentMethod} complete={paymentMethodComplete} options={paymentTypes} required={true} active={paymentInformationActive} initializedValue={paymentMethod}/>
                <DropDownSelection title={'Payment Terms:'} setTo={setPaymentTerms} complete={paymentTermsComplete} options={paymentTermOptions} required={true} active={paymentInformationActive} initializedValue={paymentTerms}/>
                {   customTermsShown &&  
                    <TextInput title={'Custom Terms'} setTo={setCustomTerms} width={800} required={true} active={paymentInformationActive}/>
                }

                <div className='External-Vendor-Selection-Section'>
                <div className='External-Vendor-Selection-Title'>
                    Business Classifications:
                </div>
                    {   
                        businessClassifications.map((value, key) => (
                            <ClassificationSelector key={key} value={value} classificationList={classificationList} addClassification={addClassification} removeClassification={removeClassification}/>
                        ))
                    }
                </div>

                {   !vendorTypeSelectionComplete &&
                <div  className='External-Vendor-Type-Error'>
                    Select a valid Vendor Type to continue.
                </div>
                }
                {   !paymentMethodComplete &&
                <div  className='External-Vendor-Type-Error'>
                    Select a valid Payment Method to continue.
                </div>
                }
                {   !paymentTermsComplete &&
                <div  className='External-Vendor-Type-Error'>
                    Enter valid Payment Terms to continue.
                </div>
                }
            </div>
        }
        </>
    )
};

// COI SECTION
const CoiInformation = ({coiActive, setCoiFile}) => {
    return(
        <div className='External-Vendor-Information'>
            {   coiActive &&
                <>
                    <div className='External-Vendor-Type-Title External-Vendor-Bold'>
                        Please provide a Certificate of Insurance (COI) below.
                    </div>
                </>
            }
            {
                !coiActive &&
                <div className='External-Vendor-Thick-Spacer'/>
            }
            <div className='External-Form-Spacer'>
            </div>
            <div className='External-Form-Input-Title'>
            PDF version of COI :
            </div>
            <input type='file' accept="application/pdf" onChange={(e) => setCoiFile(e.target.files)} className='External-Form-File-Upload'/>
        </div>
    )
};

// ACH SECTION
const AchInformation = ({achActive,setBankName,bankName,bankNameComplete,setBankContactFirstName,bankContactFirstName,setBankContactLastName,bankContactLastName,bankContactNameComplete,
    setBankStreet,bankStreet,setBankStreet2,bankStreet2,bankStreetComplete,setBankCity,bankCity,bankCityComplete,setBankState,bankState,bankStateComplete,
    setBankZip,bankZip,bankZipComplete,setBankCountry,bankCountry,bankCountryComplete,setBankTelephone,bankTelephone,bankTelephoneComplete,
    setBankContactEmail,bankContactEmail,bankContactEmailComplete,setBankAccountName,bankAccountName,bankAccountNameComplete,
    setBankAccountNumber,bankAccountNumber,bankAccountNumberComplete,setBankRoutingNumber,bankRoutingNumber,bankRoutingNumberComplete,
    setBankSwiftCode,bankSwiftCode,bankSwiftCodeComplete,setBankAccountSelection,bankAccountSelection,bankAccountSelectionComplete}) => {
    
    const [ checkingSelected, setCheckingSelected ] = useState(true);
    const [ savingsSelected, setSavingsSelected ] = useState(false);
    const [ addressComplete, setAddressComplete] = useState(false);

    useEffect(() => {
        if(bankStateComplete && bankCityComplete && bankStateComplete && bankZipComplete) setAddressComplete(true);
        else setAddressComplete(false);
    },[bankStateComplete,bankCityComplete,bankStateComplete,bankZipComplete]);


    const toggleSelection = (value) => {
        if(value === 'Checking'){
            setSavingsSelected(false);
            setCheckingSelected(true);
            setBankAccountSelection('Checking');
        }
        else{
            setSavingsSelected(true);
            setCheckingSelected(false);
            setBankAccountSelection('Savings');
        }
    };

    useEffect(() => {
        if(bankAccountSelection === 'Checking'){
            setSavingsSelected(false);
            setCheckingSelected(true);
        }
        else{
            setSavingsSelected(true);
            setCheckingSelected(false);
        }
    },[bankAccountSelection]);

    return(
        <div className='External-Vendor-Information'>
            { achActive &&
            <div className='External-Vendor-Type-Title External-Vendor-Bold'>
                Please Fill in the ACH Payment Information Below.
            </div>
            }
                
            <TextInput title={'Bank Name:'} setTo={setBankName} complete={bankNameComplete} width={280} required={true} active={achActive} initializedValue={bankName}/>
            <TextInput title={'Name on Account:'} setTo={setBankAccountName} complete={bankAccountNameComplete} width={300} required={true} active={achActive} initializedValue={bankAccountName}/>

            <div className='External-Form-Spacer'>
            </div>
            <div className='External-Form-Input-Title'>
            Bank Address:
            </div>
            <TextInput title={'Street:'} setTo={setBankStreet}complete={bankStreetComplete} width={220} required={true} active={achActive} under={true} initializedValue={bankStreet}/>
            <TextInput title={'Street2:'} setTo={setBankStreet2} width={120} required={false} active={achActive} under={true} initializedValue={bankStreet2}/>
            <TextInput title={'City:'} setTo={setBankCity} complete={bankCityComplete} width={140} required={true} active={achActive} under={true} initializedValue={bankCity}/>
            <TextInput title={'State:'} setTo={setBankState} complete={bankStateComplete} charCount={2} width={40} required={true} active={achActive} under={true} initializedValue={bankState}/>
            <TextInput title={'Zip:'} setTo={setBankZip} complete={bankZipComplete} charCount={5} required={true} active={achActive} under={true} initializedValue={bankZip}/>
            <TextInput title={'Country:'} setTo={setBankCountry} complete={bankCountryComplete} charCount={2} required={false} active={achActive} under={true} initializedValue={bankCountry}/>
            <div className='External-Form-Spacer'>
            </div>

            <TextInput title={'Bank Telephone:'} setTo={setBankTelephone} complete={bankTelephoneComplete} charCount={10} required={true} active={achActive} initializedValue={bankTelephone}/>
            <TextInput title={'Bank Contact First Name:'} setTo={setBankContactFirstName} complete={bankContactNameComplete} width={150} required={true} active={achActive} initializedValue={bankContactFirstName}/>
            <TextInput title={'Bank Contact Last Name:'} setTo={setBankContactLastName} complete={bankContactNameComplete} width={150} required={true} active={achActive} initializedValue={bankContactLastName}/>
            <TextInput title={'Bank Contact Email:'} setTo={setBankContactEmail} complete={bankContactEmailComplete} width={300} required={true} active={achActive} initializedValue={bankContactEmail}/>
            <TextInput title={'Account Number:'} setTo={setBankAccountNumber} complete={bankAccountNumberComplete} charCount={17} required={true} active={achActive} initializedValue={bankAccountNumber}/>
            <TextInput title={'Routing Number:'} setTo={setBankRoutingNumber} complete={bankRoutingNumberComplete} charCount={9} required={true} active={achActive} initializedValue={bankRoutingNumber}/>
            <TextInput title={'Swift Code:'} setTo={setBankSwiftCode} complete={bankSwiftCodeComplete} charCount={11}  active={achActive} initializedValue={bankSwiftCode}/>

            <AccountSelector title={'Checking'} selected={checkingSelected} setTo={() => toggleSelection('Checking')}/>
            <AccountSelector title={'Savings'} selected={savingsSelected} setTo={() => toggleSelection('Savings')}/>

            {   !bankNameComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a Valid Bank Name to continue.
            </div>
            }
            {   !bankAccountNameComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a Valid Name for the Bank Account to continue.
            </div>
            }
            {   !addressComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a Valid Bank Address to continue.
            </div>
            }
            {   !bankTelephoneComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a Valid Bank Telephone Number to continue.
            </div>
            }
            {   !bankContactNameComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a Valid Bank Contact Name to continue.
            </div>
            }
            {   !bankContactEmailComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a Valid Bank Contact Email to continue.
            </div>
            }
            {   !bankAccountNumberComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a Valid Bank Account Number to continue.
            </div>
            }
            {   !bankRoutingNumberComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a Valid Bank Routing Number to continue.
            </div>
            }
            {   !bankAccountSelectionComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a Valid Bank Account Type Selection to continue.
            </div>
            }
        </div>
    )
};

// INTERNATIONAL WIRE SECTION
const InternationWireInformation = ({intWireActive, setIntWire}) => {
    return(
        <div className='External-Vendor-Information'>
            {   intWireActive &&
                <>
                    <div className='External-Vendor-Type-Title External-Vendor-Bold'>
                        Please provide International Wire Instructions below.
                    </div>
                </>
            }
            {
                !intWireActive &&
                <div className='External-Vendor-Thick-Spacer'/>
            }
            <div className='External-Form-Spacer'>
            </div>
            <div className='External-Form-Input-Title'>
            PDF version of International Wire Instructions :
            </div>
            <input type='file' accept="application/pdf" onChange={(e) => setIntWire(e.target.files)} className='External-Form-File-Upload'/>
        </div>
    )
};

// VOIDED CHECK SECTION
const VoidedCheckInformation = ({voidedCheckActive, setVoidedCheckFile}) => {

    return(
        <div className='External-Vendor-Information'>
            {   voidedCheckActive &&
                <>
                    <div className='External-Vendor-Type-Title External-Vendor-Bold'>
                        Please attach a Voided Check or a Bank Letter confirming the banking information above.
                    </div>
                </>
            }
            {
                !voidedCheckActive &&
                <div className='External-Vendor-Thick-Spacer'>
                </div>
            }
            <div className='External-Form-Spacer'>
            </div>
            <div className='External-Form-Input-Title'>
            PDF version of Voided Check or Bank Letter :
            </div>
            <input type='file' accept="application/pdf" onChange={(e) => setVoidedCheckFile(e.target.files)} className='External-Form-File-Upload'/>
        </div>
    )
};

// W9 SECTION
const W9Section = ({w9Active, downloadBlankW9, setFinishedW9}) => {
    return(
        <div className='External-Vendor-Information'>
            {   w9Active &&
                <>
                    <div className='External-Vendor-Type-Title External-Vendor-Bold'>
                        Please download the blank W9, fill it out, and attach the finished copy below.
                    </div>
                    <button onClick={() => downloadBlankW9()}>Click Here to Download Blank W9</button>
                </>
            }
            {
                !w9Active &&
                <div className='External-Vendor-Thick-Spacer'>
                </div>
            }
            <div className='External-Form-Spacer'>
            </div>
            <div className='External-Form-Input-Title'>
            PDF version of completed W9:
            </div>
            <input type='file' accept="application/pdf" onChange={(e) => setFinishedW9(e.target.files)} className='External-Form-File-Upload'/>
        </div>
    )
};

// CONDITIONS SECTION
const ConditionsSection = ({termsAccepted,setTermsAccepted,setOfficerName,officerName,officerNameComplete,setOfficerTitle,officerTitle,
    officerTitleComplete,setSignature,signatureComplete}) => {
    const conditionString = 'I certify that I am authorized to provide this information, and that the information on this form is correct to the best of my knowledge. I understand that if payment is erroneously made to the account identified above, Cornerstone reserves the right to reverse the transfer. If Vendor provides inaccurate payment information, Vendor agrees to indemnify and hold harmless Cornerstone from any damages or costs associated with providing incorrect information in this Authorization. I acknowledge that it is Vendor’s responsibility to notify Cornerstone immediately if it believes there is a discrepancy between the amount deposited to its bank account and the amount of the invoice(s) paid. I understand that it is Vendor’s responsibility to notify Cornerstone immediately of any changes in the financial institution(s) or account(s) identified above. I understand that this Authorization will remain in full force and effect until Cornerstone has received written notification requesting a change or cancellation and has had a reasonable opportunity to act on the change or cancellation. I hereby authorize Cornerstone to electronically deposit payments to the bank account designated above as payment under Vendor’s agreement(s) with Cornerstone.'
    const confirmationString = 'Click here to indicate that you have read and agree to the terms above.'

    return(
        <div className='External-Form-Conditions'>
            <div className='External-Form-Conditions-Text'>
                {conditionString}
            </div>
            <div className='External-Form-Conditions-Accept'>
                <input type='checkbox' className='External-Form-Conditions-Accept-Input' onClick={(e) => setTermsAccepted(e.target.checked)}/>
                <div className='External-Form-Conditions-Accept-Text'>
                    {confirmationString}
                </div>
            </div>
            <div className='External-Form-Conditions-Information'>
                <TextInput title={`Officer's Name`} setTo={setOfficerName} complete={officerNameComplete} width={300} required={true} initializedValue={officerName}/>
                <TextInput title={`Officer's Title`} setTo={setOfficerTitle} complete={officerTitleComplete} width={300} required={true} initializedValue={officerTitle}/>
                <div className='External-Vendor-Signature-Section'>
                    <SignatureInput title={`Officer's Signature`} setTo={setSignature} complete={signatureComplete} width={400}/>
                    <div className='External-Vendor-Signature-Text'>
                        Typed name is considered a valid/legal signature
                    </div>
            </div>
            </div>
            
            {   !termsAccepted &&
            <div  className='External-Vendor-Type-Error'>
                Accept Terms to continue.
            </div>
            }

            {   !officerNameComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a valid Office Name to continue.
            </div>
            }
            {   !officerTitleComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a valid Office Title to continue.
            </div>
            }
            {   !signatureComplete &&
            <div  className='External-Vendor-Type-Error'>
                Enter a valid Signature to continue.
            </div>
            }

        </div>
    )
};


// FORM SUBMISSION - - - - - - - -
const FormSubmissionWindow = ({voidedCheckId,voidedCheckShown,coiId,coiShown,w9Id,intWireShown,intWireId,finalizedSubmitting}) => {
    // FILES SECTION HELPER FUNCTION
    const FileSection = ({loading, title, seperator=false}) => {
        return(
            <div className='External-Form-Submission-Section'>
                <div className='External-Form-Submission-File-Group'>
                    <div className='External-Form-Submission-Status'>
                        {   loading &&
                            <LoadingSpinner size={60}/>
                        }     
                        {   !loading &&
                            <HiBadgeCheck className='External-Form-Accepted' />
                        }
                    </div>
                    <div className='External-Form-Submission-Title'>
                        {title}
                    </div>
                </div>
                {   seperator &&
                    <FaDotCircle className='External-Form-Submission-Seperator'/>
                }
            </div>
        )
    };
    
    return(
        <div className='External-Form-Submission-Window'>
            {   voidedCheckShown &&
                <FileSection loading={!voidedCheckId} title={'Submitting Voided Check'} seperator={true}/>
            }
            {   coiShown &&
                <FileSection loading={!coiId} title={'Submitting COI'} seperator={true}/>
            }
            {   intWireShown &&
                <FileSection loading={!intWireId} title={'Submitting International Wire Instructions'} seperator={true}/>
            }

            <FileSection loading={!w9Id} title={'Submitting W9'} seperator={true}/>

            <FileSection loading={!finalizedSubmitting} title={'Submitting Vendor Record'}/>

            {   finalizedSubmitting &&
                <div className='External-Form-Submission-Success'>
                    We have received your information. Thank you!
                </div>
            }

        </div>
    )
};


// REJECTION - - - - - - - - - - - 
const RejectionHeader = ({rejectionRecord}) => {
    
    return(
        <div className='Rejection-Header'>
            <div className='Rejection-Header-Title'>
                Your request has been rejected for the following reasons:
            </div>
            <div className='Rejection-Header-Text'>
                {rejectionRecord.text}
            </div>
            <div className='Rejection-Header-Instructions'>
                Please update below and resubmit.
            </div>
        </div>
    )
};


// PRIMARY FUNCTION - - - - - - - - -
const NewVendor = () => {
    const { email } = useParams();
    const [ notificationEmail, setNotificationEmail ] = useState(email);
    useEffect(() => {
        setNotificationEmail(email);
    },[email]);

    // DEFAULTS
    const formEmail = 'For questions or support, please email: ACH@cornerstoneinc.com';
    const vendorTypeOptions = ['Supplier', 'Subcontractor', 'Government', 'Attorney', 'Rent'];
    const paymentTypes = ['ACH', 'Purchasing Card', 'International Wire'];
    const paymentTermOptions = ['Net 60', 'Net 45', 'Net 30', 'Net 15', 'Net 10', '1st of Month', 'Upon Receipt'];
    const businessClassifications = ['MBE(State)','MBE(Fed)','WBE','MWBE','VBE','DVBE','SDVBE','LGBTBE','WOSB','EDWOSB','VOSB','SDVOSB','HUBZone','8(a)','DBE','CAN'];
    const params = useSearchParams(); 

    // FORM TYPE STATE
    const [ formType, setFormType ] = useState('New');
    const [ typeActive, setTypeActive ] = useState(true);
    const [ typeComplete, setTypeComplete ] = useState(true);

    // VENDOR INFORMATION STATE
    const [ vendorInformationActive, setVendorInformationActive ] = useState(false);
    const [ vendorInformationComplete, setVendorInformationComplete ] = useState(false);
    const [ fedEinSSn, setFedEinSSn ] = useState('');
    const [ fedEinSSnComplete, setFedEinSSnComplete ] = useState(false);
    const [ legalName, setLegalName ] = useState('');
    const [ legalNameComplete, setLegalNameComplete ] = useState(false);
    const [ businessName, setBusinessName ] = useState('');
    const [ businessNameComplete, setBusinessNameComplete ] = useState(false);
    const [ street, setStreet ] = useState('');
    const [ street2, setStreet2 ] = useState('');
    const [ streetComplete, setStreetComplete ] = useState(false);
    const [ city, setCity ] = useState('');
    const [ cityComplete, setCityComplete ] = useState(false);
    const [ state, setState ] = useState('');
    const [ stateComplete, setStateComplete ] = useState(false);
    const [ zip, setZip ] = useState('');
    const [ zipComplete, setZipComplete ] = useState(false);
    const [ country, setCountry ] = useState('');

    // CONTACT STATE
    const [ contactInformationShown, setContactInformationShown ] = useState(false);
    const [ contactInformationActive, setContactInformationActive ] = useState(false);
    const [ primaryContactFirstName, setPrimaryContactFirstName ] = useState('');
    const [ primaryContactLastName, setPrimaryContactLastName ] = useState('');
    const [ primaryContactNameComplete, setPrimaryContactNameComplete ] = useState(false);
    const [ primaryContactTitle, setPrimaryContactTitle ] = useState('');
    const [ primaryContactNumber, setPrimaryContactNumber ] = useState('');
    const [ primaryContactNumberComplete, setPrimaryContactNumberComplete ] = useState(false);
    const [ primaryContactNumberExtension, setPrimaryContactNumberExtension] = useState('');
    const [ primaryContactEmail, setPrimaryContactEmail ] = useState('');
    const [ primaryContactEmailComplete, setPrimaryContactEmailComplete ] = useState(false);
    const [ secondaryContact, setSecondaryContact ] = useState(false);
    const [ secondaryContactFirstName, setSecondaryContactFirstName ] = useState('');
    const [ secondaryContactLastName, setSecondaryContactLastName ] = useState('');
    const [ secondaryContactNameComplete, setSecondaryContactNameComplete ] = useState(false);
    const [ secondaryContactTitle, setSecondaryContactTitle ] = useState('');
    const [ secondaryContactNumber, setSecondaryContactNumber ] = useState('');
    const [ secondaryContactNumberComplete, setSecondaryContactNumberComplete ] = useState(false);
    const [ secondaryContactNumberExtension, setSecondaryContactNumberExtension] = useState('');
    const [ secondaryContactEmail, setSecondaryContactEmail ] = useState('');
    const [ secondaryContactEmailComplete, setSecondaryContactEmailComplete ] = useState(false);

    // PAYMENT STATE
    const [ paymentInformationShown, setPaymentInformationShown ] = useState(false);
    const [ paymentInformationActive, setPaymentInformationActive ] = useState(false);
    const [ vendorTypeSelection, setVendorTypeSelection ] = useState('');
    const [ vendorTypeSelectionComplete, setVendorTypeSelectionComplete ] = useState(false);
    const [ paymentMethod, setPaymentMethod ] = useState('');
    const [ paymentMethodComplete, setPaymentMethodComplete ] = useState(false);
    const [ classificationList, setClassificationList ] = useState([]);
    const [ paymentTerms, setPaymentTerms] = useState('');
    const [ paymentTermsComplete, setPaymentTermsComplete] = useState('');
    const [ customTermsShown, setCustomTermsShown ] = useState(false);
    const [ customTerms, setCustomTerms ] = useState('');

    // ACH STATE
    const [ achShown, setAchShown ] = useState(false);
    const [ achActive, setAchActive ] = useState(false);
    const [ achComplete, setAchComplete ] = useState(false);
    const [ bankName, setBankName ] = useState('');
    const [ bankNameComplete, setBankNameComplete ] = useState(false);
    const [ bankContactFirstName, setBankContactFirstName ] = useState('');
    const [ bankContactLastName, setBankContactLastName ] = useState('');
    const [ bankContactNameComplete, setBankContactNameComplete ] = useState(false);
    const [ bankStreet, setBankStreet ] = useState('');
    const [ bankStreet2, setBankStreet2 ] = useState('');
    const [ bankStreetComplete, setBankStreetComplete ] = useState(false);
    const [ bankCity, setBankCity ] = useState('');
    const [ bankCityComplete, setBankCityComplete ] = useState('');
    const [ bankState, setBankState ] = useState('');
    const [ bankStateComplete, setBankStateComplete ] = useState(false);
    const [ bankZip, setBankZip ] = useState('');
    const [ bankZipComplete, setBankZipComplete ] = useState(false);
    const [ bankCountry, setBankCountry ] = useState('');
    const [ bankCountryComplete, setBankCountryComplete ] = useState(false);
    const [ bankTelephone, setBankTelephone ] = useState('');
    const [ bankTelephoneComplete, setBankTelephoneComplete ] = useState(false);
    const [ bankContactEmail, setBankContactEmail ] = useState('');
    const [ bankContactEmailComplete, setBankContactEmailComplete ] = useState(false);
    const [ bankAccountName, setBankAccountName ] = useState('');
    const [ bankAccountNameComplete, setBankAccountNameComplete ] = useState(false);
    const [ bankAccountNumber, setBankAccountNumber ] = useState('');
    const [ bankAccountNumberComplete, setBankAccountNumberComplete ] = useState(false);
    const [ bankRoutingNumber, setBankRoutingNumber ] = useState('');
    const [ bankRoutingNumberComplete, setBankRoutingNumberComplete ] = useState(false);
    const [ bankSwiftCode, setBankSwiftCode ] = useState('');
    const [ bankSwiftCodeComplete, setBankSwiftCodeComplete ] = useState(false);
    const [ bankAccountSelection, setBankAccountSelection ] = useState('Checking');
    const [ bankAccountSelectionComplete, setBankAccountSelectionComplete ] = useState(false);

    // VOIDED CHECK STATE
    const [ voidedCheckShown, setVoidedCheckShown ] = useState(false);
    const [ voidedCheckActive, setVoidedCheckActive ] = useState(false);
    const [ voidedCheckFile, setVoidedCheckFile ] = useState();
    const [ voidedCheckId, setVoidedCheckId ] = useState();
    const [ voidedCheckError, setVoidedCheckError ] = useState('');

    // COI STATE
    const [ coiFile, setCoiFile ] = useState();
    const [ coiShown, setCoiShown ] = useState(false);
    const [ coiActive, setCoiActive] = useState(false);
    const [ coiId, setCoiId] = useState();
    const [ coiError, setCoiError ] = useState();

    // W9 STATE
    const [ w9Shown, setW9Shown ] = useState(false);
    const [ w9Active, setW9Active ] = useState(false);
    const [ finishedW9, setFinishedW9 ] = useState();
    const [ w9Id, setW9Id ] = useState();
    const [ w9Error, setW9Error ] = useState('');

    // INTERNATIONAL WIRE STATE
    const [ intWireShown, setIntWireShown ] = useState(false);
    const [ intWireActive, setIntWireActive ] = useState(false);
    const [ intWire, setIntWire ] = useState();
    const [ intWireId, setIntWireId ] = useState();
    const [ intWireError, setIntWireError ] = useState('');

    // CONDITIONS STATE
    const [ conditionsShown, setConditionsShown ] = useState(false);
    const [ conditionsComplete, setConditionsComplete ] = useState(false);
    const [ termsAccepted, setTermsAccepted ] = useState(false);
    const [ officerName, setOfficerName ] = useState('');
    const [ officerNameComplete, setOfficerNameComplete ] = useState(false);
    const [ officerTitle, setOfficerTitle ] = useState('');
    const [ officerTitleComplete, setOfficerTitleComplete ] = useState(false);
    const [ signature, setSignature ] = useState('');
    const [ signatureComplete, setSignatureComplete ] = useState(false);

    // SUBMITTING STATE
    const [ submitting, setSubmitting ] = useState(false);
    const [ finalizedSubmitting, setFinalizedSubmitted ] = useState(false);
    const [ vendorRecordError, setVendorRecordError ] = useState('');

    // REJECTION STATES
    const [ formTypeShown, setFormTypeShown ] = useState(false);
    const [ vendorInformationShown, setVendorInformationShown ] = useState(false);

    const [ rejectionRecord, setRejectionRecord ] = useState();


    // REJECTIONS - - - - - - - - - - -
    const markFieldsComplete = () => {
        setTypeComplete(true);
        setFedEinSSnComplete(true);
        setLegalNameComplete(true);
        setBusinessNameComplete(true);
        setStreetComplete(true);
        setCityComplete(true);
        setStateComplete(true);
        setZipComplete(true);
        setPrimaryContactNameComplete(true);
        setPrimaryContactNumberComplete(true);
        setPrimaryContactEmailComplete(true);
        setSecondaryContactNameComplete(true);
        setSecondaryContactNumberComplete(true);
        setSecondaryContactEmailComplete(true);
        setVendorTypeSelectionComplete(true);
        setPaymentMethodComplete(true);
        setPaymentTermsComplete(true);
        setBankNameComplete(true);
        setBankContactNameComplete(true);
        setBankStreetComplete(true);
        setBankCityComplete(true);
        setBankStateComplete(true);
        setBankZipComplete(true);
        setBankCountryComplete(true);
        setBankTelephoneComplete(true);
        setBankContactEmailComplete(true);
        setBankAccountNameComplete(true);
        setBankAccountNumberComplete(true);
        setBankRoutingNumberComplete(true);
        setBankSwiftCodeComplete(true);
        setBankAccountSelectionComplete(true);
        setOfficerNameComplete(true);
        setOfficerTitleComplete(true);
    };
    // INITIALIZE REJECTED VENDOR RECORD
    const initializeVendorRecord = (vendorProfile) => {
        console.log('Initializing Vendor Record')
        console.log(vendorProfile)
        // Form Type
        setFormType(vendorProfile.formType);
        // Vendor Information
        setFedEinSSn(vendorProfile.einSsn);
        setLegalName(vendorProfile.legalName);
        setBusinessName(vendorProfile.businessName);
        setStreet(vendorProfile.businessAddress.street);
        setStreet2(vendorProfile.businessAddress.street2);
        setCity(vendorProfile.businessAddress.city);
        setState(vendorProfile.businessAddress.state);
        setZip(vendorProfile.businessAddress.zip);
        setCountry(vendorProfile.businessAddress.country);
        // Contact Information
        setPrimaryContactFirstName(vendorProfile.primaryContact.firstName);
        setPrimaryContactLastName(vendorProfile.primaryContact.lastName);
        setPrimaryContactTitle(vendorProfile.primaryContact.title);
        setPrimaryContactNumber(vendorProfile.primaryContact.number);
        setPrimaryContactNumberExtension(vendorProfile.primaryContact.extension);
        setPrimaryContactEmail(vendorProfile.primaryContact.email);
        if(vendorProfile.secondaryContact){
            setSecondaryContactFirstName(vendorProfile.secondaryContact.firstName);
            setSecondaryContactLastName(vendorProfile.secondaryContact.lastName);
            setSecondaryContactTitle(vendorProfile.secondaryContact.title);
            setSecondaryContactNumber(vendorProfile.secondaryContact.number);
            setSecondaryContactNumberExtension(vendorProfile.secondaryContact.extension);
            setSecondaryContactEmail(vendorProfile.secondaryContact.email);
        };
        // Payment Information
        setVendorTypeSelection(vendorProfile.vendorType);
        setPaymentMethod(vendorProfile.paymentMethod);
        setPaymentTerms(vendorProfile.paymentTerms);
        setClassificationList(classificationList);
        // ACH
        if(vendorProfile.bankAccount){
            setBankName(vendorProfile.bankAccount.name);
            setBankContactFirstName(vendorProfile.bankAccount.contact.firstName);
            setBankContactLastName(vendorProfile.bankAccount.contact.lastName);
            setBankStreet(vendorProfile.bankAccount.address.street);
            setBankStreet2(vendorProfile.bankAccount.address.street2);
            setBankCity(vendorProfile.bankAccount.address.city);
            setBankState(vendorProfile.bankAccount.address.state);
            setBankZip(vendorProfile.bankAccount.address.zip);
            setBankCountry(vendorProfile.bankAccount.address.country);
            setBankTelephone(vendorProfile.bankAccount.contact.number);
            setBankContactEmail(vendorProfile.bankAccount.contact.email);
            setBankAccountName(vendorProfile.bankAccount.accountName);
            setBankAccountNumber(vendorProfile.bankAccount.accountNumber);
            setBankRoutingNumber(vendorProfile.bankAccount.routingNumber);
            setBankSwiftCode(vendorProfile.bankAccount.swiftCode);
            setBankAccountSelection(vendorProfile.bankAccount.accountType);
        };
        setOfficerName(vendorProfile.signerName);
        setOfficerTitle(vendorProfile.signerTitle);
    };
    // INITIALIZE SECTIONS SHOWN
    const initializeForm = () => {
        rejectionRecord.sections.forEach(element => {

            // VENDOR INFORMATION
            if(element === 'vendorInformation'){
                setVendorInformationActive(true);
                setVendorInformationShown(true);
            };

            // CONTACTS
            if(element === 'contacts'){
                setContactInformationActive(true);
                setContactInformationShown(true);
            };

            // PAYMENT INFORMATION
            if(element === 'payment'){
                setPaymentInformationActive(true);
                setPaymentInformationShown(true);
            };

            
            // COI INFORMATION
            if(element === 'ach'){
                setAchActive(true);
                setAchShown(true);
            };

            // COI INFORMATION
            if(element === 'coi'){
                setCoiActive(true);
                setCoiShown(true);
            };

            // W9 INFORMATION
            if(element === 'w9'){
                setW9Active(true);
                setW9Shown(true);
            };

            // VOIDED CHECK INFORMATION
            if(element === 'voidedCheck'){
                setVoidedCheckActive(true);
                setVoidedCheckShown(true);
            };

            // INT WIRE INFORMATION
            if(element === 'intlWire'){
                setIntWireActive(true);
                setIntWireShown(true);
            };
        });
    };

    useEffect(() => {   // CALL TO INITIALIZE IF REJECTION RECORD FOUND
        if(rejectionRecord){
            console.log(rejectionRecord)
            initializeVendorRecord(rejectionRecord.vendorProfile);
            markFieldsComplete();
            initializeForm();
        };
    },[rejectionRecord]);

    useEffect(() => { // DETERMINES IF THIS IS A REJECTION OR NOT - CALLS TO SET RECORD
        const searchParams = params[0];
        if(searchParams.get("sessionId")) grabRejectionRecord(searchParams.get("sessionId"),searchParams.get("rejectionId"),setRejectionRecord);
        else setRejectionRecord(null);
    },[]);
    //PROPS
    const rejectionProps = {
        rejectionRecord: rejectionRecord,
    };

// FORM TYPE
    const handleFormTypeSelection = (selection) => {
        setFormType(selection);
    };
    useEffect(() => {
        if(!rejectionRecord){
            if(formType) {
                setTypeActive(false);
                setTypeComplete(true);
                setVendorInformationActive(true);
            }
            else{
                setTypeActive(true);
                setTypeComplete(false);
                setVendorInformationActive(false);
            }
        }
    },[formType]);

    // *TRANSITION* - - - - VENDOR <-> CONTACTS
    const transitionFromVendor = (valid) => {
        if(valid) {
            setVendorInformationComplete(true);
            setVendorInformationActive(false);
            setContactInformationShown(true);
            setContactInformationActive(true);
        }
        else{
            setVendorInformationComplete(false);
            setVendorInformationActive(true);
        }
    };

    // VENDOR
    const checkVendorInformation = () => {
        let complete = true
        if(zip.length === 5){
            setZipComplete(true);
        }
        else{
            setZipComplete(false)
            complete = false;
        };

        if(fedEinSSn.length === 9){
            setFedEinSSnComplete(true);
        }
        else{
            setFedEinSSnComplete(false)
            complete = false;
        };

        if(legalName.length > 2) {
            setLegalNameComplete(true);
        }
        else{
            setLegalNameComplete(false)
            complete = false;
        };

        if(businessName.length > 2) {
            setBusinessNameComplete(true);
        }
        else{
            setBusinessNameComplete(false)
            complete = false;
        };

        if(street.length > 5) {
            setStreetComplete(true);
        }
        else{
            setStateComplete(false)
            complete = false;
        };

        if(city.length > 2) {
            setCityComplete(true);
        }
        else{
            setCityComplete(false)
            complete = false;
        };

        if(state.length > 1) {
            setStateComplete(true);
        }
        else{
            setStateComplete(false)
            complete = false;
        };

        return complete;
    };
    const vendorInformationProps = {
        formType:formType,
        vendorInformationActive:vendorInformationActive,
        setFedEinSSn:setFedEinSSn,
        fedEinSSn:fedEinSSn,
        fedEinSSnComplete:fedEinSSnComplete,
        setLegalName:setLegalName,
        legalName:legalName,
        legalNameComplete:legalNameComplete,
        setBusinessName:setBusinessName,
        businessName:businessName,
        businessNameComplete:businessNameComplete,
        setStreet:setStreet,
        street:street,
        street2:street2,
        setStreet2:setStreet2,
        streetComplete:streetComplete,
        setCity:setCity,
        city:city,
        cityComplete:cityComplete,
        setState:setState,
        state:state,
        stateComplete:stateComplete,
        setZip:setZip,
        zip:zip,
        zipComplete:zipComplete,
        setCountry:setCountry,
        country:country,
    };
    useEffect(() => {
        if(!rejectionRecord){
            transitionFromVendor(checkVendorInformation());
        };
    },[fedEinSSn,legalName,businessName,street,city,state,zip])

    // *TRANSITION* - - - - CONTACTS <-> PAYMENT
    const transitionFromContact = (valid) => {
        if(!rejectionRecord){
            if(valid) {
                setContactInformationActive(false);
                setPaymentInformationShown(true);
                setPaymentInformationActive(true);
            }
            else{
                setContactInformationActive(true);
            }
        };
    };

// CONTACT
    const checkContactInformation = () => {
        let complete = true;

        if(primaryContactFirstName.length > 3 && primaryContactLastName.length > 3) setPrimaryContactNameComplete(true);
        else{
            setPrimaryContactNameComplete(false);
            complete = false;
        };

        if(primaryContactNumber.length === 10) setPrimaryContactNumberComplete(true);
        else{
            setPrimaryContactNumberComplete(false);
            complete = false;
        };

        if(checkEmail(primaryContactEmail)) setPrimaryContactEmailComplete(true);
        else{
            setPrimaryContactEmailComplete(false);
            complete = false;
        };

        if(secondaryContact){
            if(secondaryContactFirstName.length > 3 && secondaryContactLastName.length > 3) setSecondaryContactNameComplete(true);
            else{
                setSecondaryContactNameComplete(false);
                complete = false;
            };
    
            if(secondaryContactNumber.length === 10) setSecondaryContactNumberComplete(true);
            else{
                setSecondaryContactNumberComplete(false);
                complete = false;
            };
    
            if(checkEmail(secondaryContactEmail)) setSecondaryContactEmailComplete(true);
            else{
                setSecondaryContactEmailComplete(false);
                complete = false;
            };
        }

        return complete;
    };
    useEffect(() => {
        if(checkContactInformation()) transitionFromContact(true);
        else transitionFromContact(false);
    },[primaryContactFirstName,primaryContactLastName,primaryContactNumber,primaryContactEmail, secondaryContact, secondaryContactFirstName,secondaryContactLastName, secondaryContactNumber, secondaryContactEmail]);
    // PROPS
    const contactInformationProps = {
        contactInformationShown:contactInformationShown,
        contactInformationActive:contactInformationActive,
        setPrimaryContactFirstName:setPrimaryContactFirstName,
        setPrimaryContactLastName:setPrimaryContactLastName,
        primaryContactFirstName: primaryContactFirstName,
        primaryContactLastName: primaryContactLastName,
        primaryContactNameComplete:primaryContactNameComplete,
        primaryContactTitle,primaryContactTitle,
        setPrimaryContactTitle:setPrimaryContactTitle,
        setPrimaryContactNumber:setPrimaryContactNumber,
        primaryContactNumber:primaryContactNumber,
        primaryContactNumberComplete:primaryContactNumberComplete,
        setPrimaryContactNumberExtension:setPrimaryContactNumberExtension,
        primaryContactNumberExtension:primaryContactNumberExtension,
        setPrimaryContactEmail:setPrimaryContactEmail,
        primaryContactEmail:primaryContactEmail,
        primaryContactEmailComplete:primaryContactEmailComplete,
        secondaryContact: secondaryContact,
        setSecondaryContact: setSecondaryContact,
        setSecondaryContactFirstName:setSecondaryContactFirstName,
        setSecondaryContactLastName:setSecondaryContactLastName,
        secondaryContactFirstName:secondaryContactFirstName,
        secondaryContactLastName:secondaryContactLastName,
        secondaryContactNameComplete:secondaryContactNameComplete,
        secondaryContactTitle,secondaryContactTitle,
        setSecondaryContactTitle:setSecondaryContactTitle,
        setSecondaryContactNumber:setSecondaryContactNumber,
        secondaryContactNumber:secondaryContactNumber,
        secondaryContactNumberComplete:secondaryContactNumberComplete,
        setSecondaryContactNumberExtension:setSecondaryContactNumberExtension,
        secondaryContactNumberExtension:secondaryContactNumberExtension,
        setSecondaryContactEmail:setSecondaryContactEmail,
        secondaryContactEmail:secondaryContactEmail,
        secondaryContactEmailComplete:secondaryContactEmailComplete,
    };
    
    // *TRANSITION* - - - - PAYMENT <-> W9
    const transferFromPayment = (valid) => {
        if(valid){
            setPaymentInformationActive(false);
            setW9Shown(true);
            setW9Active(true);
        }
        else{
            setPaymentInformationActive(true);
            setW9Shown(false);
            setW9Active(false);  
        }
    };
    // PAYMENT
    useEffect(() => {    // TRIGGER CUSTOM TERMS
        if(paymentTerms === 'Other'){
            setCustomTermsShown(true);
        }
        else{
            setCustomTermsShown(false);
        }
    },[paymentTerms]);

    const checkPaymentInformation = () => {
        let complete = true;

        if(vendorTypeSelection && vendorTypeSelection !== 'false') setVendorTypeSelectionComplete(true);
        else{
            setVendorTypeSelectionComplete(false);
            complete = false;
        };

        if(paymentMethod && paymentMethod !== 'false') setPaymentMethodComplete(true);
        else{
            setPaymentMethodComplete(false);
            complete = false;
        };

        if(paymentTerms && paymentTerms !== 'false') setPaymentTermsComplete(true);
        else{
            setPaymentTermsComplete(false);
            complete = false;
        };

        return complete;
    };

    useEffect(() => {
        if(!rejectionRecord){
            if(checkPaymentInformation()) transferFromPayment(true);
            else transferFromPayment(false);
        };
    },[vendorTypeSelection, paymentMethod, paymentTerms, customTerms]);
    //PROPS
    const paymentInformationProps = {
        paymentInformationShown:paymentInformationShown,
        paymentInformationActive:paymentInformationActive,
        vendorTypeOptions:vendorTypeOptions,
        setVendorTypeSelection:setVendorTypeSelection,
        vendorTypeSelection:vendorTypeSelection,
        vendorTypeSelectionComplete:vendorTypeSelectionComplete,
        paymentTypes:paymentTypes,
        setPaymentMethod:setPaymentMethod,
        paymentMethod:paymentMethod,
        paymentMethodComplete:paymentMethodComplete,
        paymentTermOptions:paymentTermOptions,
        setPaymentTerms:setPaymentTerms,
        paymentTerms:paymentTerms,
        paymentTermsComplete:paymentTermsComplete,
        customTermsShown:customTermsShown,
        setCustomTerms:setCustomTerms,
        businessClassifications:businessClassifications,
        classificationList:classificationList,
        setClassificationList:setClassificationList,
    };

    // *TRANSITION* - - - - ACH
    const transitionFromAch = (valid) => {
        if(valid){
            setAchActive(false);
            setAchComplete(true);
        }
        else{
            setAchActive(true);
            setAchComplete(false);
        }
    };
    // ACH
    const checkAchInformation = () => {
        let complete = true;

        if(bankName.length > 2) setBankNameComplete(true);
        else{
            setBankNameComplete(false);
            complete = false;
        };

        if(bankContactFirstName.length > 2 && bankContactLastName.length > 2) setBankContactNameComplete(true);
        else{
            setBankContactNameComplete(false);
            complete = false;
        };

        if(bankStreet.length > 2) setBankStreetComplete(true);
        else{
            setBankStreetComplete(false);
            complete = false;
        };

        if(bankCity.length > 2) setBankCityComplete(true);
        else{
            setBankCityComplete(false);
            complete = false;
        };

        if(bankState.length > 1) setBankStateComplete(true);
        else{
            setBankStateComplete(false);
            complete = false;
        };

        if(bankZip.length === 5) setBankZipComplete(true);
        else{
            setBankZipComplete(false);
            complete = false;
        };

        if(bankZip.length === 5) setBankZipComplete(true);
        else{
            setBankZipComplete(false);
            complete = false;
        };

        if(bankTelephone.length === 10) setBankTelephoneComplete(true);
        else{
            setBankTelephoneComplete(false);
            complete = false;
        };
        
        if(checkEmail(bankContactEmail)) setBankContactEmailComplete(true);
        else{
            setBankContactEmailComplete(false);
            complete = false;
        };

        if(bankAccountName.length > 2) setBankAccountNameComplete(true);
        else{
            setBankAccountNameComplete(false);
            complete = false;
        };

        if(bankAccountNumber.length > 7) setBankAccountNumberComplete(true);
        else{
            setBankAccountNumberComplete(false);
            complete = false;
        };

        if(bankRoutingNumber.length === 9) setBankRoutingNumberComplete(true);
        else{
            setBankRoutingNumberComplete(false);
            complete = false;
        };

        if(bankAccountSelection && bankAccountSelection !== '') setBankAccountSelectionComplete(true);
        else{
            setBankAccountSelectionComplete(false);
            complete = false;
        };

        return complete;
    };
    useEffect(() => { // CHECKS FOR TRANSITION READY
        if(!rejectionRecord){
            if(checkAchInformation())transitionFromAch(true);
            else transitionFromAch(false);
        }
    },[bankName,bankContactFirstName,bankContactLastName,bankStreet,bankCity,bankState,bankZip,bankTelephone,bankContactEmail,bankAccountName,
    bankAccountNumber,bankRoutingNumber,bankSwiftCode,bankAccountSelection]);
    const achProps = {
        achActive:achActive,
        setBankName:setBankName,
        bankName:bankName,
        bankNameComplete:bankNameComplete,
        setBankContactFirstName:setBankContactFirstName,
        bankContactFirstName:bankContactFirstName,
        setBankContactLastName:setBankContactLastName,
        bankContactLastName:bankContactLastName,
        bankContactNameComplete:bankContactNameComplete,
        setBankStreet:setBankStreet,
        bankStreet:bankStreet,
        setBankStreet2:setBankStreet2,
        bankStreet2:bankStreet2,
        bankStreetComplete:bankStreetComplete,
        setBankCity:setBankCity,
        bankCity:bankCity,
        bankCityComplete:bankCityComplete,
        setBankState:setBankState,
        bankState:bankState,
        bankStateComplete:bankStateComplete,
        setBankZip:setBankZip,
        bankZip:bankZip,
        bankZipComplete:bankZipComplete,
        setBankCountry:setBankCountry,
        bankCountry:bankCountry,
        bankCountryComplete:bankCountryComplete,
        setBankTelephone:setBankTelephone,
        bankTelephone:bankTelephone,
        bankTelephoneComplete:bankTelephoneComplete,
        setBankContactEmail:setBankContactEmail,
        bankContactEmail:bankContactEmail,
        bankContactEmailComplete:bankContactEmailComplete,
        setBankAccountName:setBankAccountName,
        bankAccountName:bankAccountName,
        bankAccountNameComplete:bankAccountNameComplete,
        setBankAccountNumber:setBankAccountNumber,
        bankAccountNumber:bankAccountNumber,
        bankAccountNumberComplete:bankAccountNumberComplete,
        setBankRoutingNumber:setBankRoutingNumber,
        bankRoutingNumber:bankRoutingNumber,
        bankRoutingNumberComplete:bankRoutingNumberComplete,
        setBankSwiftCode:setBankSwiftCode,
        bankSwiftCode:bankSwiftCode,
        bankSwiftCodeComplete:bankSwiftCodeComplete,
        setBankAccountSelection:setBankAccountSelection,
        bankAccountSelection:bankAccountSelection,
        bankAccountSelectionComplete:bankAccountSelectionComplete,
    };
    useEffect(() => {    // TRIGGER ACH & INT WIRE WINDOW SHOW
        if(!rejectionRecord){
            if(paymentMethod === 'ACH'){
                setAchActive(true);
                setAchShown(true);
                setVoidedCheckShown(true);
                setVoidedCheckActive(true);
                setIntWireActive(false);
                setIntWireShown(false);
            }
            else if(paymentMethod === 'International Wire'){
                setIntWireActive(true);
                setIntWireShown(true);
                setAchActive(false);
                setAchShown(false);
                setVoidedCheckShown(false);
                setVoidedCheckActive(false);
            }
            else{
                setAchActive(false);
                setAchShown(false);
                setVoidedCheckShown(false);
                setVoidedCheckActive(false);
                setIntWireActive(false);
                setIntWireShown(false);
            };
        };
    },[paymentMethod]);

    // VOIDED CHECK
    useEffect(() => {
        if(voidedCheckFile) {
            setVoidedCheckActive(false);
        }
        else{
            setVoidedCheckActive(true);
        }
    },[voidedCheckFile]);
    const submitVoidedCheck = () => {
        const extension = voidedCheckFile[0].type
        const filename = `${businessName}-Voided Check-${getDate()}.${extension.replace(/(.*)\//g, '')}`;
        sendVoidedCheck(voidedCheckFile[0], filename, setVoidedCheckId, setVoidedCheckError);
    };

    // COI
    useEffect(() => {
        if(coiFile) {
            setCoiActive(false);
        }
        else{
            setCoiActive(true);
        }
    },[coiFile]);
    const submitCOI = () => {
        const extension = coiFile[0].type
        const filename = `${businessName}-COI-${getDate()}.${extension.replace(/(.*)\//g, '')}`;
        sendCoi(coiFile[0], filename, setCoiId, setCoiError);
    };
    useEffect(() => {    // TRIGGER COI WINDOW SHOW
        if(!rejectionRecord){
            if(vendorTypeSelection === 'Subcontractor'){
                setCoiActive(true);
                setCoiShown(true);
            }
            else{
                setCoiActive(false);
                setCoiShown(false);
            };
        };
    },[vendorTypeSelection]);

    // W9
    const downloadBlankW9 = () => {
        getBlankW9();
    };
    const submitW9 = () => {
        const extension = finishedW9[0].type
        const filename = `${businessName}-W9-${getDate()}.${extension.replace(/(.*)\//g, '')}`;
        sendW9(finishedW9[0], filename, setW9Id, setW9Error);
    };
    useEffect(() => {
        if(finishedW9) setW9Active(false);
        else setW9Active(true);
    },[finishedW9]);

    // Internation Wire
    const submitIntWire = () => {
        const extension = intWire[0].type
        const filename = `${businessName}-Int Wire-${getDate()}.${extension.replace(/(.*)\//g, '')}`;
        sendInternationalWire(intWire[0], filename, setIntWireId, setIntWireError);
    };
    useEffect(() => {
        if(!rejectionRecord){
            if(intWire) setIntWireActive(false);
            else setIntWireActive(true);
        }
        else if(intWire) setIntWireActive(false);
    },[intWire]);

    // READY FOR CONDITIONS
    const readyToConfirm = () => {
        let ready = true;
        if(typeActive) ready = false;
        if(!vendorInformationComplete) ready = false;
        if(contactInformationShown) if(contactInformationActive) ready = false;
        if(paymentInformationShown) if(paymentInformationActive) ready = false;
        if(achShown) if(!achComplete) ready = false;
        if(voidedCheckShown) if(voidedCheckActive) ready = false;
        if(coiShown) if(coiActive) ready = false;
        if(intWireShown) if(intWireActive) ready = false;
        if(!finishedW9) ready = false;
        
        return ready;
    };
    useEffect(() => {
        if(!rejectionRecord){
            if(readyToConfirm()) setConditionsShown(true);
            else setConditionsShown(false);
        };
    },[typeActive,vendorInformationComplete,contactInformationActive,paymentInformationActive,achComplete,voidedCheckActive,coiActive,finishedW9,intWireActive]);


    // CONDITIONS & SIGNATURE

    const checkConditionsComplete = () => {
        let complete = true;

        if(signature.length > 3) setSignatureComplete(true);
        else{
            setSignatureComplete(false);
            complete = false;
        };

        if(officerName.length > 3) setOfficerNameComplete(true);
        else{
            setOfficerNameComplete(false);
            complete = false;
        };

        if(officerTitle.length > 2) setOfficerTitleComplete(true);
        else{
            setOfficerTitleComplete(false);
            complete = false;
        };

        if(!termsAccepted) complete = false;

        setConditionsComplete(complete);
    };
    useEffect(() => {
        checkConditionsComplete();
    },[signature,officerName,officerTitle,termsAccepted]);

    const conditionsProps = {
        termsAccepted:termsAccepted,
        setTermsAccepted:setTermsAccepted,
        setOfficerName:setOfficerName,
        officerName:officerName,
        officerNameComplete:officerNameComplete,
        setOfficerTitle:setOfficerTitle,
        officerTitle:officerTitle,
        officerTitleComplete:officerTitleComplete,
        setSignature:setSignature,
        signatureComplete:signatureComplete,
    };

    // SUBMITTING FORM
    const setFormComplete = () => {
        setFinalizedSubmitted(true);
    };

    const createVendorRecordObject = () => {
        // CREATE OBJECT
        const vendorObject = {
            formType: formType,
            fedEinSSn: fedEinSSn,
            legalName:legalName,
            businessName:businessName,
            street:street,
            street2:street2,
            city:city,
            state:state,
            zip:zip,
            country:country,
            primaryContactFirstName:primaryContactFirstName,
            primaryContactLastName:primaryContactLastName,
            primaryContactTitle:primaryContactTitle,
            primaryContactNumber:primaryContactNumber,
            primaryContactNumberExtension:primaryContactNumberExtension,
            primaryContactEmail:primaryContactEmail,
            secondaryContactFirstName:secondaryContactFirstName,
            secondaryContactLastName:secondaryContactLastName,
            secondaryContactTitle:secondaryContactTitle,
            secondaryContactNumber:secondaryContactNumber,
            secondaryContactNumberExtension:secondaryContactNumberExtension,
            secondaryContactEmail:secondaryContactEmail,
            vendorType:vendorTypeSelection,
            paymentMethod:paymentMethod,
            classificationList:classificationList,
            paymentTerms:paymentTerms,
            customTerms:customTerms,
            bankName:bankName,
            bankContactFirstName:bankContactFirstName,
            bankContactLastName:bankContactLastName,
            bankStreet:bankStreet,
            bankStreet2:bankStreet2,
            bankCity:bankCity,
            bankState:bankState,
            bankZip:bankZip,
            bankCountry:bankCountry,
            bankTelephone:bankTelephone,
            bankContactEmail:bankContactEmail,
            bankAccountName:bankAccountName,
            bankAccountNumber:bankAccountNumber,
            bankRoutingNumber:bankRoutingNumber,
            bankSwiftCode:bankSwiftCode,
            bankAccountSelection:bankAccountSelection,
            voidedCheckId:voidedCheckId,
            coiId:coiId,
            officerName:officerName,
            officerTitle:officerTitle,
            signature: signature,
            intWireId:intWireId,
            w9Id:w9Id,
            notificationEmail:notificationEmail,
        };

        if(rejectionRecord){
            vendorObject.id = rejectionRecord.vendorProfile.id;
            vendorObject.businessAddressId = rejectionRecord.vendorProfile.businessAddress.id;
            if(rejectionRecord.vendorProfile.bankAccount) vendorObject.bankAccountId = rejectionRecord.vendorProfile.bankAccount.id;
            vendorObject.primaryContactId = rejectionRecord.vendorProfile.primaryContact.id;
            if(rejectionRecord.vendorProfile.secondaryContact) vendorObject.secondaryContactId = rejectionRecord.vendorProfile.secondaryContact.id;
            vendorObject.rejectionId = rejectionRecord.id;
        };

        return vendorObject;
    };

    const submitVendorRecord = () => {
        const vendorObject = createVendorRecordObject();
        // SEND OBJECT
        sendVendorRecord(vendorObject,setFormComplete,setVendorRecordError);
    };

    const putVendorRecord = () => {
        const vendorObject = createVendorRecordObject();
        // SEND OBJECT
        updateVendorRecord(vendorObject,setFormComplete,setVendorRecordError);
    };

    const submitForm = () => {
        setSubmitting(true);
        if(voidedCheckShown) submitVoidedCheck();
        if(coiShown) submitCOI();
        if(intWireShown) submitIntWire();
        submitW9();
    };

    const checkUpdatedFiles = () => {
        let complete = true;

        if(intWireShown) if(!intWire) complete = false;
        if(voidedCheckShown) if(!voidedCheckFile) complete = false;
        if(coiShown) if(!coiFile) complete = false;
        if(w9Shown) if(!finishedW9) complete = false;

        return complete;
    };

    useEffect(() => {
        let finalReady = true;
        if(voidedCheckShown) if(!voidedCheckId) finalReady = false;
        if(coiShown) if(!coiId) finalReady = false;
        if(!w9Id) finalReady = false;
        if(intWireShown) if(!intWireId) finalReady = false;

        if(finalReady) {
            if(!rejectionRecord) submitVendorRecord();
            if(rejectionRecord) putVendorRecord();
        };
    },[voidedCheckId,coiId,w9Id,intWireId], );

    // RENDER NEW VENDOR PRIMARY
    return(
        <div className='External-Form-Window'>

            <div className='External-Form-Page'>

                <FormHeader formEmail={formEmail}/>

                {   !submitting && !rejectionRecord &&
                    <>

                    <VendorInformation {...vendorInformationProps}/>

                    <ContactInformation {...contactInformationProps} />
                    
                    <PaymentInformation {...paymentInformationProps}/>

                    {   achShown &&
                        <AchInformation {...achProps}/>
                    }   

                    {   intWireShown &&
                        <InternationWireInformation intWireActive={intWireActive} setIntWire={setIntWire}/>
                    }

                    {   voidedCheckShown &&
                        <VoidedCheckInformation voidedCheckActive={voidedCheckActive} setVoidedCheckFile={setVoidedCheckFile}/>
                    }

                    {   coiShown &&
                        <CoiInformation coiActive={coiActive} setCoiFile={setCoiFile}/>
                    }

                    {   w9Shown &&
                        <W9Section w9Active={w9Active} downloadBlankW9={downloadBlankW9} setFinishedW9={setFinishedW9}/>
                    }

                    {   conditionsShown &&
                        <ConditionsSection {...conditionsProps}/>
                    }

                    {   conditionsComplete &&
                        <button onClick={() => submitForm()} className='External-Vendor-Submit External-Vendor-Button'>Submit Form</button>
                    }
                    
                    <img src={'https://www.positivessl.com/images/seals/positivessl_trust_seal_md_167x42.png'} className='External-Vendor-SSL'/>
                    </>
                }
                {   submitting &&
                    <FormSubmissionWindow coiId={coiId} coiShown={coiShown} voidedCheckId={voidedCheckId} voidedCheckShown={voidedCheckShown} 
                    w9Id={w9Id} intWireShown={intWireShown} intWireId={intWireId} finalizedSubmitting={finalizedSubmitting}/>
                }
                {   rejectionRecord && !submitting &&
                <>
                    <RejectionHeader {...rejectionProps}/>
                    {   formTypeShown &&
                    <VendorType typeActive={typeActive} formType={formType} handleFormTypeSelection={handleFormTypeSelection}/>
                    }

                    { vendorInformationShown &&
                        <VendorInformation {...vendorInformationProps} />
                    }

                    {   contactInformationShown &&
                        <ContactInformation {...contactInformationProps} />
                    }

                    {   paymentInformationShown &&
                        <PaymentInformation {...paymentInformationProps} editClassificationList={rejectionRecord.vendorProfile.classificationList}/>
                    }

                    {   achShown &&
                        <AchInformation {...achProps}/>
                    }   

                    {   intWireShown &&
                        <InternationWireInformation intWireActive={intWireActive} setIntWire={setIntWire}/>
                    }

                    {   voidedCheckShown &&
                        <VoidedCheckInformation voidedCheckActive={voidedCheckActive} setVoidedCheckFile={setVoidedCheckFile}/>
                    }

                    {   coiShown &&
                        <CoiInformation coiActive={coiActive} setCoiFile={setCoiFile}/>
                    }

                    {   w9Shown &&
                        <W9Section w9Active={w9Active} downloadBlankW9={downloadBlankW9} setFinishedW9={setFinishedW9}/>
                    }

                    <ConditionsSection {...conditionsProps}/>

                    {   intWireShown && !intWire &&
                    <div  className='External-Vendor-Type-Error'>
                        Upload new International Wire Instructions to continue.
                    </div>
                    }

                    {   voidedCheckShown && !voidedCheckFile &&
                    <div  className='External-Vendor-Type-Error'>
                        Upload a new Voided Check or Bank Letter to continue.
                    </div>
                    }

                    {   coiShown && !coiFile &&
                    <div  className='External-Vendor-Type-Error'>
                        Upload a new COI to continue.
                    </div>
                    }

                    {   w9Shown && !finishedW9 &&
                    <div  className='External-Vendor-Type-Error'>
                        Upload a new W9 to continue.
                    </div>
                    }

                    {   conditionsComplete && checkUpdatedFiles() &&
                        <button onClick={() => submitForm()} className='External-Vendor-Submit External-Vendor-Button'>Resubmit Form</button>
                    }

                    <img src={'https://www.positivessl.com/images/seals/positivessl_trust_seal_md_167x42.png'} className='External-Vendor-SSL'/>

                </>
                }
            </div>
        </div>
    )
};


export default NewVendor;